@use '../../../../styles/variables.scss';
@use '../../../../styles/colors.scss';
@use '../../../../styles/breakpoints.scss';

.CompetitionItemContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 48px;
  width: 100%;
  gap: 10px;
  box-shadow: variables.$inner--line--bottom--1;
  .LeftContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    gap: 48px;
    .Title {
      font-family: variables.$font--text--medium;
      font-size: 16px;
      line-height: 16px;
      width: 300px;
      min-width: 300px;
    }
    .TitleIsDisabled {
      color: colors.$black--dark--3;
    }
    .District {
      font-family: variables.$font--text--medium;
      color: colors.$black--dark--3;
      width: 150px;
      font-size: 14px;
      line-height: 14px;
    }
    .TotalGames {
      font-family: variables.$font--text--medium;
      color: colors.$black--dark--3;
      width: 100px;
      font-size: 14px;
      line-height: 14px;
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .CompetitionItemContainer {
    padding: 24px 32px;
    gap: 16px;
    align-items: flex-end;
    .LeftContainer {
      flex-direction: column-reverse;
      align-items: flex-start;
      gap: 8px;
      .Title {
        max-width: 100%;
        width: 100%;
        min-width: unset;
        font-family: variables.$font--text--bold;
        font-size: 14px;
        line-height: 18px;
      }
      .District {
        font-size: 14px;
        line-height: 18px;
        max-width: 100%;
        width: 100%;
        letter-spacing: -0.01em;
      }
      .TotalGames {
        font-size: 14px;
        line-height: 18px;
        max-width: 100%;
        width: 100%;
        letter-spacing: -0.01em;
      }
    }
  }
}

@media only screen and (max-width: breakpoints.$mobile) {
  .CompetitionItemContainer {
    padding: 16px;
  }
}
