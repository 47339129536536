@use '../../../../../styles/variables.scss';
@use '../../../../../styles/breakpoints.scss';
.LoaderContainer {
  min-height: 478px;
}
.FormContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 32px;
  min-height: 478px;
  .ThumbnailContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .Fields {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .ToggleSwitchContainer {
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: center;
    }
    .Buttons {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 12px;
      width: 100%;
    }
  }
}
@media only screen and (max-width: breakpoints.$md) {
  .LoaderContainer {
    min-height: 350px;
  }
  .FormContainer {
    min-height: 350px;
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .FormContainer {
    min-height: unset;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .Fields {
      .ToggleSwitchContainer {
        flex-direction: column;
        align-items: flex-start;
        .Text {
          display: flex;
          flex-direction: column;
          gap: 16px;
          font-family: variables.$font--text--light;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}
