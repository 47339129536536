@use '../../../../styles/breakpoints.scss';

.ButtonContainer {
  display: flex;
  gap: 16px;
  padding: 16px 32px;
}

@media only screen and (max-width: breakpoints.$md) {
  .ButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: unset;
  }
}
