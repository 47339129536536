@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.Themed {
  color: variables.$channel-theme--primary-color;
  background-color: variables.$channel-theme--primary-background-color;
  text-transform: variables.$channel-theme--text-transform;
  a {
    font-family: variables.$channel-theme--submenu-font-family !important;
    font-weight: variables.$channel-theme--submenu-font-weight !important;
  }

  ul {
    padding: 0;
    margin-top: 20px;
    margin-bottom: 28px;
    li {
      text-decoration: none;
      color: variables.$channel-theme--primary-color;
      margin-right: 40px;
      a,
      a:visited {
        text-decoration: none;
        color: variables.$channel-theme--primary-color;
      }
      a:hover,
      a:focus {
        color: variables.$channel-theme--primary-color;
      }
    }

    list-style-type: none;
    display: flex;
    flex-direction: row;
  }
}

.NotThemed {
  text-transform: none;

  ul {
    padding: 0;
    margin-top: 20px;
    margin-bottom: 28px;
    li {
      text-decoration: none;
      color: variables.$menu-item--dark--active-color;
      margin-right: 40px;
      a,
      a:visited {
        text-decoration: none;
        color: variables.$menu-item--dark--active-color;
      }
      a:hover,
      a:focus {
        color: variables.$menu-item--dark--active-color;
      }
    }
    list-style-type: none;
    display: flex;
    flex-direction: row;
  }
}

.SubmenuContainer {
  display: flex;

  font-weight: 700;
  font-size: 20px;
  .HoverTheme {
    a:hover {
      color: variables.$channel-theme--primary-highlight-color !important;
    }
  }
  .SelectedTheme {
    a {
      color: variables.$channel-theme--primary-highlight-color !important;
    }
  }
  .NotSelected {
    opacity: 0.65;
    font-weight: 400;
  }
}
.ShoudlHaveSmallerText {
  font-size: 16px;
  ul {
    margin-top: 12px;
    margin-bottom: 16px;
    line-height: 16px;
    li {
      margin-right: 32px;
    }
  }
}

@media screen and (max-width: breakpoints.$sm) {
  .SubmenuContainer {
    font-size: 14px;
  }
  .Themed,
  .NotThemed,
  .NotSelected {
    ul {
      margin-top: 18px;
      margin-bottom: 18px;
      li {
        margin-right: 20px;
      }
    }
  }
}
