@use '../../styles/variables.scss';
@use '../../styles/breakpoints.scss';

.BreadcrumbContainer {
  display: flex;
  flex-direction: row;
  text-transform: none;
  font-size: 14px;
  line-height: 14px;
  padding: 20px variables.$padding--left-right--desktop;

  .Breadcrumb {
    padding-right: 8px;
    color: variables.$breadcrumb--text;
    white-space: nowrap;

    a,
    a:visited {
      text-decoration: none;
      color: variables.$breadcrumb--text;
    }
    a:hover,
    a:focus {
      color: variables.$breadcrumb--text;
    }
  }
  .CurrentPage {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    color: variables.$breadcrumb--highlight;
    a,
    a:visited {
      text-decoration: none;
      color: variables.$breadcrumb--highlight;
    }
    a:hover,
    a:focus {
      color: variables.$breadcrumb--highlight;
    }
  }
}
@media screen and (max-width: breakpoints.$sm) {
  .BreadcrumbContainer {
    padding: 10px variables.$padding--left-right--tablet;
    font-size: 10px;
    line-height: 10px;
  }
}

@media screen and (max-width: breakpoints.$mobile) {
  .BreadcrumbContainer {
    padding: 10px variables.$padding--left-right--mobile;
    font-size: 10px;
  }
}
