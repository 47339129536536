@use '../../../styles/variables.scss';

.PreLoaderContainer {
  display: flex;
  padding-right: 16px;
  flex-direction: column;
  .Thumbnail {
    position: relative;
    border-radius: variables.$component-radius;
    padding-bottom: 56.25%;
    .InnerContainer {
      position: absolute;
      width: 100%;
      height: 100%;
      background: variables.$preloader--linear-gradient-3;
    }
  }
}

.small {
  width: 318px;
  height: 179px;
}
.medium {
  width: 390px;
  height: 220px;
}
.large {
  width: 494px;
  height: 272px;
}
.xLarge {
  width: 1004px;
  height: 565px;
}
.keepRatio {
  width: 100%;
}

@keyframes animate {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.Loader {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: animate;
  animation-timing-function: linear;

  display: flex;
  vertical-align: center;
}
