@import '../../../../styles/variables.scss';

.TeamContainer {
  display: flex;
  flex-direction: column;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
      font-weight: bolder;
    }
  }

  .HomeTeam {
    margin-bottom: 4px;
  }
}
