@use '../../../styles/variables.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}
.ArticleThumbnailLink {
  text-decoration: none;
  color: variables.$brand--primary--text-dark;
}
.SummaryText {
  display: none;
  position: absolute;
  color: variables.$white;
  background-color: rgba(variables.$default--black-background, 0.7);
  justify-content: center;
  align-items: center;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 32px;
  .TextContent {
    display: inline-block;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: calc(100%);
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.ArticleThumbnailContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px;
  border-radius: variables.$component-radius;
  overflow: hidden;
  background: variables.$white;
  border-radius: 4px;
  &.small {
    width: 318px;
    height: 274px;
  }
  &.medium {
    width: 390px;
    height: 334px;
  }
  &.large {
    width: 494px;
    height: 364px;
  }
  &.xlarge {
    width: 1220px;
    height: 838px;
  }
}

.ArticleTextContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;

  &.small {
    width: 318px;
    height: 95px;
  }
  &.medium {
    width: 390px;
    height: 144px;
  }
  &.large {
    width: 494px;
    height: 86px;
  }
  &.xlarge {
    width: 1220px;
    height: 271px;
  }

  padding: 16px 16px 24px;
}

.ImageContainer {
  position: relative;
  &:hover {
    .SummaryText {
      display: flex;
    }
  }

  &.small {
    width: 318px;
    height: 179px;
  }
  &.medium {
    width: 390px;
    height: 220px;
  }
  &.large {
    width: 494px;
    height: 278px;
  }
  &.xlarge {
    width: 1220px;
    height: 567px;
  }
  .ArticleImage {
    height: 100%;
    width: 100%;
    background-size: cover !important;
    background-position: center center;
    background-repeat: no-repeat !important;
  }
}

.NoImageContainer {
  position: relative;
  box-sizing: border-box;
  &:hover {
    .SummaryText {
      display: flex;
    }
  }
  &.small {
    width: 318px;
    height: 179px;
  }
  &.medium {
    width: 390px;
    height: 220px;
  }
  &.large {
    width: 494px;
    height: 278px;
  }
  &.xlarge {
    width: 1220px;
    height: 567px;
  }

  .ArticleImage {
    background-origin: content-box;
    height: 100%;
    width: 100%;
    padding: 16px;
    background-size: 100% 100%;
    background-repeat: no-repeat !important;
  }
}
