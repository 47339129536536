.VideoAds {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .VideoAdsInner {
    position: relative;
    width: 100%;
    height: 100%;
    .AdContainer {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 99;
      overflow: hidden;
    }
  }
}
