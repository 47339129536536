@use '../../../../styles/breakpoints.scss';
.SponsorItem {
  cursor: pointer;
  display: flex;
  align-items: center;
  max-width: 140px;
  height: 75px;

  img {
    object-fit: contain;
    width: auto;
    height: auto;
    max-height: 100%;
    max-width: 100%;
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .SponsorItem {
    max-height: 50px;
    max-width: 100px;
  }
}
@media only screen and (max-width: breakpoints.$mobile) {
  .SponsorItem {
    max-height: 25px;
    max-width: 75px;
  }
}
