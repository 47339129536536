@use '../../../styles/breakpoints.scss';

.AnimationContainer {
  display: flex;
  justify-content: center;
  height: 314px;
  width: 100%;
}

@media only screen and (max-width: breakpoints.$sm) {
  .AnimationContainer {
    height: 138px;
  }
}
