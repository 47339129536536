.FieldsContainer {
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 16px;
  max-width: 100%;
  height: 100%;
  width: 100%;
  max-height: 100%;
  .TextInput div > input {
    height: 48px;
    padding: 0;
    padding-left: 16px;
  }
  .TextArea {
    max-height: 170px;
  }
  .ButtonsFieldsContainer {
    width: 100%;
    max-width: 100%;
  }
}
