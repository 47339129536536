@use '../../../../../../styles/variables.scss';
@use '../../../../../../styles/breakpoints.scss';

.Container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  h5 {
    margin: 0;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .Container {
    gap: 32px;
    h5 {
      font-family: variables.$font--text--bold;
      font-size: 20px;
      line-height: 26px;
    }
  }
}
