@use '../../../../../../styles/variables.scss';

.Container {
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  width: 100%;
  cursor: pointer;
  .Label {
    font-family: variables.$font--text--light;
    font-size: 16px;
    line-height: 24px;
    color: variables.$default--text-color;
    text-align: center;
  }
  .Icon {
    display: flex;
    justify-content: center;
    stroke: variables.$default--text-color;
  }
  .ArrowDown {
    transition: transform 0.15s;
    transform: rotate(0deg);
  }
  .ArrowUp {
    transition: transform 0.15s;
    transform: rotate(180deg);
  }
}
.ThemedHeader {
  .Label {
    color: variables.$channel-theme--primary-color-inverted;
  }
  .Icon {
    stroke: variables.$channel-theme--primary-color-inverted;
  }
}
