@use '../../../../../styles/variables.scss';
.Container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  .Text {
    color: variables.$tag-color;
    font-family: variables.$font--text--light;
    font-size: 14px;
    line-height: 14px;
  }
  .Image {
    height: 16px;
    width: fit-content;
    object-fit: contain;
  }
}
