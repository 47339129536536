@use '../../../styles/breakpoints.scss';
.Container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding-top: 64px;
  padding-bottom: 32px;
  .LoginLinkContainer {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .SuccessContent {
    padding: 0 !important;
    width: 100% !important;
    height: 100%;
  }
}

@media screen and (max-width: breakpoints.$sm) {
  .Container {
    padding-top: 0;
    padding: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}
