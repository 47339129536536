@import '../../../../styles/breakpoints.scss';

.TeamsAndScoreContainer {
  display: flex;
  align-items: center;
  .TeamsContainer {
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (max-width: $sm) {
  .TeamsAndScoreContainer {
    .TeamsContainer {
      width: 100%;
    }
  }
}
