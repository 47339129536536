@import '../../../../../styles/variables.scss';
@import '../../../../../styles/breakpoints.scss';

.ProgressBanner {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  padding: 32px 51px;
  border-radius: $component-radius;
  .LeftContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 12px;
    .Title {
      margin: 0;
    }
    .SubTitle {
      font-size: 20px;
      line-height: 28px;
    }
  }
  .RightContainer {
    display: flex;
    align-items: flex-end;
    div {
      width: max-content;
    }
  }
  .CloseButton {
    position: absolute;
    right: 51px;
    top: 32px;
    stroke: $progress--banner--close--icon--color;

    &:hover {
      stroke: $progress--banner--close--icon--hover--color;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: $sm) {
  .ProgressBanner {
    flex-direction: column;
    padding: 24px;
    .LeftContainer {
      width: 100%;
      gap: 10px;
      .Title {
        width: 90%;
        font-family: $font--text--bold;
        font-size: 24px;
        line-height: 28px;
      }
      .SubTitle {
        font-size: 14px;
        line-height: 18px;
      }
    }
    .CloseButton {
      right: 24px;
      top: 24px;
    }
  }
}
