@use '../../../styles/breakpoints.scss';

.Container {
  display: flex;
  flex-direction: column;
  .DateButtonsSection {
    width: 100%;
    display: flex;
    padding: 32px;
    padding-bottom: 25px;
    flex-direction: row;
    justify-content: flex-end;
  }
  .GameListContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: breakpoints.$md) {
  .Container {
    .DateButtonsSection {
      padding: 8px 16px;
    }
  }
}
