@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.SubmenuContainer {
  display: flex;
  gap: $gutter--size--desktop;
  font-size: 20px;
  line-height: 20px;
  padding: 28px $padding--left-right--desktop 29.5px
    $padding--left-right--desktop;

  ul {
    padding: 0;
    margin: 0;
    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      padding-right: 40px;
      a {
        text-decoration: none;
        color: $menu-item--dark--default-color;
      }

      font-weight: 400;
      &:hover,
      &:focus {
        a {
          color: $menu-item--dark--hover-color;
        }
      }
    }
    li:last-child {
      padding-right: 0;
    }
    list-style-type: none;
    display: flex;
    flex-direction: row;
  }

  .Selected {
    color: $menu-item--dark--active-color;
    a {
      color: $menu-item--dark--active-color;
    }
  }
}

@media only screen and (max-width: $sm) {
  .SubmenuContainer {
    padding: 28px $padding--left-right--tablet 29.5px
      $padding--left-right--tablet;

    ul {
      li {
        padding-right: $gutter--size--tablet;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .SubmenuContainer {
    padding: 18px $padding--left-right--mobile;
    font-size: 14px;
    line-height: 14px;
    ul {
      li {
        padding-right: $gutter--size--mobile;
      }
    }
  }
}
