@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.AccordionItemContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  .HeaderContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-radius: variables.$component-radius;
    overflow: hidden;
  }
  .Header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    padding: 24px 48px;
    position: relative;
    cursor: pointer;

    .WithIcon {
      gap: 16px;
    }
    .Title {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-family: variables.$font--text--medium;
      width: 100%;

      .HeaderLink {
        text-decoration: none;
        color: variables.$default--text-color;
        font-family: variables.$font--text--medium;
        width: 100%;
      }
      .HrefLink {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      .Icon {
        display: flex;
        align-items: center;
        svg {
          width: 39px;
          height: 39px;
        }
      }
    }
  }
  .White {
    background-color: variables.$ghost--default-background;
    &:hover {
      background-color: variables.$ghost--hover-background !important;
    }
  }
  .Grey {
    background-color: variables.$secondary--light-background;
    &:hover {
      background-color: variables.$secondary--light--hover-background !important;
    }
  }

  .InformationContainer {
    position: absolute;
  }
}

@media screen and (max-width: breakpoints.$mobile) {
  .AccordionItemContainer {
    .Header {
      padding: 24px 32px;
    }
  }
}
