@use '../../styles/variables.scss';
@use '../../styles/breakpoints.scss';

.LinkContainer {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.ChannelListItemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 458px;
  padding: 26px 24px;
  border-radius: variables.$component-radius;
  background-color: variables.$content--default-background;
  &:hover {
    background-color: variables.$secondary--light--hover-background;
  }
  .Logo {
    height: 50px;
    width: 50px;
    min-width: 50px;
    object-fit: contain;
  }
  .VerticalDivider {
    width: 2px;
    align-self: stretch;
    border-radius: variables.$component-radius;
    background-color: variables.$vertical--divider-background;
  }
  .LabelContainer {
    width: 100%;
    max-width: 100%;
    word-break: break-all;
    display: flex;
    flex-direction: column;
    .Name {
      color: variables.$default--text-color;
      font-family: variables.$font--text--medium;
      font-size: 16px;
      line-height: 24px;
    }
    .TagContainer {
      display: flex;
      flex-direction: row;
      gap: 4px;
      .Tag {
        text-transform: capitalize;
        color: variables.$tag-color;
        font-family: variables.$font--text--light;
        font-size: 16px;
        line-height: 24px;
        &::after {
          content: '|';
          margin-left: 4px;
          display: inline-block;
        }
        &:last-child {
          &::after {
            content: '';
          }
        }
      }
    }

    .RootDomainLabel {
      padding-top: 4px;
      color: variables.$default--text-color;
      font-family: variables.$font--text--light;
      font-size: 16px;
      line-height: 16px;
    }
  }
  .IconContainer {
    width: 24px;
    height: 24px;
  }
}

.HasBorder {
  border: variables.$ghost--default-border;
}

.WithExternalLink {
  padding: 16px 24px;
}

@media only screen and (max-width: breakpoints.$sm) {
  .ChannelListItemContainer {
    width: 100%;
  }
}
