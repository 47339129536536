@use '../../styles/variables.scss';
@use '../../styles/breakpoints.scss';

.AdContainer {
  padding-top: variables.$section--padding--top-bottom--desktop;
  padding-bottom: variables.$section--padding--top-bottom--desktop;
  max-width: 1250px;
  border-radius: variables.$component-radius;
  overflow: hidden;
}

@media screen and (max-width: breakpoints.$sm) {
  .AdContainer {
    padding-top: variables.$section--padding--top-bottom--tablet;
    padding-bottom: variables.$section--padding--top-bottom--tablet;
  }
}
@media screen and (max-width: breakpoints.$mobile) {
  .AdContainer {
    padding-top: variables.$section--padding--top-bottom--mobile;
    padding-bottom: variables.$section--padding--top-bottom--mobile;
  }
}
