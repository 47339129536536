@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.Disabled {
  opacity: 0.5;
  cursor: auto;
  pointer-events: none;
}
.Button {
  margin-top: 32px;
  font-family: variables.$font--text--medium;
  padding: 24px;
  border: none;
  border-radius: variables.$button-border-radius;
  font-size: 14px;
  line-height: 14px;
  text-transform: inherit;
  &:hover {
    cursor: pointer;
  }
}
.Primary {
  background: variables.$primary--default-background;
  color: variables.$primary--default-color;

  &:hover {
    background: variables.$primary--hover-background;
  }
}

.Secondary {
  background: variables.$secondary--default-background;
  color: variables.$secondary--default-color;
  &:hover {
    background: variables.$secondary--hover-background;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .Button {
    padding: 17px 32px;
  }
}
