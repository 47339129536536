@import '../../../../../styles/variables.scss';
@import '../../../../../styles/breakpoints.scss';

.BreadcrumbContainer {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  line-height: 14px;
  padding: 20px $padding--left-right--desktop;
  background-color: $breadcrumb--background;
  max-width: 100%;
  overflow: hidden;

  .Breadcrumb {
    padding-right: 8px;
    white-space: nowrap;
    color: $breadcrumb--text;

    a,
    a:visited {
      text-decoration: none;
      color: $breadcrumb--text;
    }
    a:hover,
    a:focus {
      color: $breadcrumb--text;
    }
  }
  .CurrentPage {
    font-weight: bold;
    color: $breadcrumb--highlight;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 0;
    a,
    a:visited {
      text-decoration: none;
      color: $breadcrumb--highlight;
    }
    a:hover,
    a:focus {
      color: $breadcrumb--highlight;
    }
  }
}
@media screen and (max-width: $sm) {
  .BreadcrumbContainer {
    padding: 10px $padding--left-right--tablet;
    font-size: 10px;
    line-height: 10px;
  }
}
@media screen and (max-width: $mobile) {
  .BreadcrumbContainer {
    padding: 10px $padding--left-right--mobile;
    font-size: 10px;
  }
}
