@use '../../../../styles/breakpoints.scss';
.ShowAsUserButton {
  top: 24px;
  right: 110px;
  white-space: nowrap;
  text-transform: none;
  position: absolute;
}

@media screen and (max-width: breakpoints.$sm) {
  .ShowAsUserButton {
    top: 10px !important;
    right: 32px !important;
    height: 28px !important;
  }
  @media screen and (max-width: breakpoints.$mobile) {
    .ShowAsUserButton {
      top: 10px !important;
      right: 16px !important;
      height: 28px !important;
    }
  }
}
