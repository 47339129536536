@import '../../../../styles/variables.scss';
.InsideContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .ErrorAnimation {
    margin-bottom: 32px;
    align-self: center;
    height: 100px;
    width: 100px;
  }
  .ModalInfoContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .Title {
      font-family: $font--text--bold;
      width: 100%;
      font-size: 32px;
      line-height: 110%;
    }
  }
  .ButtonContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
  }
}
