@use '../../styles/variables.scss';

.ChannelPageContent {
  height: 100%;
  .ChannelPageContainer {
    text-transform: variables.$channel-theme--text-transform;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
