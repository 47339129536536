@use '../../../styles/variables.scss';

.TextEditorContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: auto;
  div > div {
    background-color: variables.$form--text--area--background-color;
    font-size: 16px;
  }
}
