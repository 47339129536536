@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.TimeContainer {
  background-color: variables.$game--card--time--container--background;
  display: flex;
  width: 100px;
  height: 77px;
  padding: 16px 0px;
  justify-content: center;
  align-items: center;
  font-family: variables.$font--text--bold;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 28px;
  box-shadow: variables.$inner--line--bottom--1;
}

@media only screen and (max-width: breakpoints.$md) {
  .TimeContainer {
    width: 100%;
    padding: 4px 16px;
    height: unset;
    font-size: 16px;
    line-height: 24px;
    box-shadow: unset;
  }
}
