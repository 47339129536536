@use '../../../../styles/breakpoints.scss';

.ButtonContentContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  clear: both;
  .CheckBox {
    margin-left: 16px;
    display: flex;
    flex-direction: row;
  }
  .CheckBox div {
    vertical-align: middle;
    align-items: center;
  }

  .TextInput {
    width: 100%;
    input {
      height: 48px;
      padding: 0;
      padding-left: 16px;
    }
  }
  .ButtonText {
    width: 100%;
  }

  .ButtonFields {
    display: flex;
    flex-direction: row;
    gap: 16px;

    div {
      overflow: hidden;
    }
  }
  .Hidden {
    display: none;
  }
}

@media only screen and (max-width: breakpoints.$mobile) {
  .ButtonContentContainer {
    .ButtonFields {
      flex-direction: column;
    }
  }
}
