.FieldContainer {
  width: 130px !important;
  gap: 0 !important;
  padding-right: 8px !important;
  height: unset !important;
}
.SelectedCountry {
  position: unset;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: fit-content;
  gap: 10px;
  img {
    max-width: 21px;
  }
  .ListContainer {
    position: absolute;
    top: 48px;
    left: -150px;
    z-index: 3;
  }
}
