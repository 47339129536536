@use '../../../../../styles/colors.scss';
@import '../../../../../styles/variables.scss';
@import '../../../../../styles/breakpoints.scss';

.Scoreboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 48px 0;

  .ScoreboardTitleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    .ScoreboardTitle {
      margin: 0;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      font-family: $font--text--bold;
    }

    .ScoreboardSubtitle {
      font-family: $font--text--medium;
      font-weight: 500;
      font-size: 16px;
      line-height: 1.5;
      color: colors.$black--dark--2;
    }
  }

  .ScoreboardTeamsContainer {
    display: flex;
    gap: 24px;

    .ScoreboardTeam {
      display: flex;

      .ScoreboardTeamsScore {
        background-color: colors.$grey--light--2;
        color: colors.$black--dark--2;
        font-family: $font--text--bold;
        font-size: 64px;
        line-height: 1.2;
        width: 148px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .IsAwayTeam {
      flex-direction: row-reverse;
    }
  }
}

@media only screen and (max-width: $mobile) {
  .Scoreboard {
    gap: 24px;
    padding: 16px 0;

    .ScoreboardTeamsContainer {
      .ScoreboardTeam {
        flex-direction: column;
        .ScoreboardTeamsScore {
          font-size: 32px;
          line-height: 1.1;
          width: auto;
          padding: 16px 0;
        }
      }
    }
  }
}

.IsViewingStream {
  gap: 24px;
  padding: 0;
  .ScoreboardTeamsContainer {
    .ScoreboardTeam {
      flex-direction: column;
      .ScoreboardTeamsScore {
        font-size: 32px;
        line-height: 1.1;
        width: auto;
        padding: 16px 0;
      }
    }
  }
}
