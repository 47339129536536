@use '../../../../styles/breakpoints.scss';

.FormButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  height: 48px;
  align-items: baseline;
  z-index: 100;
  .SubmitButton {
    padding: 17px 32px;
  }
}
@media only screen and (max-width: breakpoints.$md) {
  .FormButtons {
    justify-content: flex-start;
  }
}
