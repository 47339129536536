@use '../../styles/variables.scss';
.Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  .TextContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .Name {
      color: variables.$default--text-color;
      font-family: variables.$font--text--bold;
      font-size: 16px;
      font-style: normal;
      line-height: 16px;
      letter-spacing: 0.08px;
    }
    .Email {
      color: #000;
      font-family: variables.$font--text--light;
      font-size: 14px;
      font-style: normal;
      line-height: 22px;
      letter-spacing: -0.14px;
    }
  }
}
