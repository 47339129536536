.Container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .ToggleButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
