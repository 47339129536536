@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.ThumbnailLink {
  text-decoration: none;
  color: $global--header--primary--text;
  &:visited {
    color: $brand--primary--text-light;
    text-decoration: none;
  }
  &:hover {
    color: $brand--primary--text-light;
    text-decoration: none;
  }
  &:focus {
    color: $brand--primary--text-light;
    text-decoration: none;
  }
}
.Container {
  overflow: hidden;
  border-radius: $component-radius;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px;
  width: 391px;
  height: 522px;
}

@media only screen and (max-width: $sm) {
  .Container {
    height: 360px;
    width: 240px;
  }
  .FeaturedContainer {
    h4 {
      margin-top: 8px !important;
      color: $brand--primary--text-light !important;
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 28px !important;
      margin-bottom: 0 !important;
    }
  }
  .FeaturedContent {
    padding: 16px;
  }
  .Description {
    display: none;
  }
}
