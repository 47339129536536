@import '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';
.ContentContainer {
  text-transform: none;
  background: $primary--default-color;
  height: 100%;
}
.HasLeftRightPadding {
  padding-left: $padding--left-right--desktop;
  padding-right: $padding--left-right--desktop;
}
.HasTopBottomPadding {
  padding-top: $section--padding--top-bottom--desktop;
  padding-bottom: $section--padding--top-bottom--desktop;
}
.HasLightBackground {
  background: $secondary--light-background;
}

@media only screen and (max-width: $sm) {
  .HasLeftRightPadding {
    padding-left: $padding--left-right--tablet;
    padding-right: $padding--left-right--tablet;
  }
  .HasTopBottomPadding {
    padding-top: $section--padding--top-bottom--tablet;
    padding-bottom: $section--padding--top-bottom--tablet;
  }
}

@media only screen and (max-width: $mobile) {
  .HasLeftRightPadding {
    padding-left: $padding--left-right--mobile;
    padding-right: $padding--left-right--mobile;
  }
  .HasTopBottomPadding {
    padding-top: $section--padding--top-bottom--mobile;
    padding-bottom: $section--padding--top-bottom--mobile;
  }
}
