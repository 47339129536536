.Container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 450px;
  .TextInput {
    width: 100%;

    input {
      height: 48px;
      padding: 0;
      box-sizing: border-box;
      padding-left: 16px;
    }
  }
  .SubmitButton {
    margin-top: 16px;
  }
}
