@use '../../../../styles/variables.scss';
.Container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: variables.$tag-color;
  gap: 4px;
  font-family: variables.$font--text--light;
  font-size: 14px;
  line-height: 14px;
}
