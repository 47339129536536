@use '../.../../../../../styles/colors.scss';
@use '../../../../styles/breakpoints.scss';
.DetailsRows {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  line-height: 12px;
  color: colors.$black--dark--3;
  .Row {
    .Column {
      &::after {
        content: '|';
        margin: 0 5px;
        display: inline-block;
      }
      &:last-child {
        &::after {
          content: '';
        }
      }
    }
  }
}

@media only screen and (max-width: breakpoints.$mobile) {
  .DetailsRows {
    .Row {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: nowrap;
    }
  }
}
