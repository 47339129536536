@use '../../../../../styles/variables.scss';
@use '../../../../../styles/breakpoints.scss';
.TeamLineup {
  border-radius: variables.$component-radius;
  background-color: variables.$content--default-background;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  gap: 32px;
  position: relative;
  flex: 1 0 0;
  align-self: stretch;
  .TeamLabel {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    padding: 8px 10px;
    align-items: center;
    gap: 10px;
    background-color: variables.$ghost--disabled-background;
    border-radius: variables.$component-radius 0px;
    font-family: variables.$font--text--medium;
    font-size: 14px;
    line-height: 14px;
  }
  .TopContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    .TeamLogo {
      height: 100px;
      width: 100px;
    }
    .TeamName {
      font-family: variables.$font--text--bold;
      font-size: 24px;
      line-height: 32px;
    }
  }
  .EmptyLineupText {
    color: variables.$default--text-color;
    text-align: center;
    font-family: variables.$font--text--light;
    font-size: 20px;
    font-style: normal;
    line-height: 28px;
  }
  .List {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 32px;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .TeamLineup {
    padding: 32px 16px;
    .TopContainer {
      .TeamLogo {
        height: 65px;
        width: 65px;
      }
      .TeamName {
        font-size: 20px;
        line-height: 28px;
      }
    }
    .EmptyLineupText {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.IsViewingStream {
  padding: 32px 16px;
  .TopContainer {
    .TeamLogo {
      height: 65px;
      width: 65px;
    }
    .TeamName {
      font-size: 20px;
      line-height: 28px;
    }
  }
  .EmptyLineupText {
    font-size: 16px;
    line-height: 24px;
  }
}
