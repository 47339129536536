@use '../../styles/breakpoints.scss';

.Container {
  padding-bottom: 120px;
  .TopInfo {
    padding: 32px 51px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    h4 {
      margin: 0;
    }
    .Text {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: breakpoints.$sm) {
  .Container {
    .TopInfo {
      padding: 32px;
    }
  }
}
@media screen and (max-width: breakpoints.$mobile) {
  .Container {
    .TopInfo {
      padding: 32px 0px;
    }
  }
}
