@use '../../../../../styles/variables.scss';
@use '../../../../../styles/breakpoints.scss';

.Container {
  position: relative;
  width: 100%;
  height: 100%;
  background: variables.$team--vs--team--left--background-color;
  overflow: hidden;
  .RightBackground {
    position: absolute;
    top: -5%;
    height: 120%;
    width: 57%;
    right: -7%;
    background: variables.$team--vs--team--right--background-color;
    mix-blend-mode: normal;
    transform: rotate(-170deg);
  }
  .InnerContainer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .Teams {
      max-width: 817px;
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
}

@media only screen and (max-width: breakpoints.$mobile) {
  .Container {
    .RightBackground {
      display: none;
    }
    .InnerContainer {
      padding-top: 65px;
      align-items: flex-start;
      .Teams {
        width: unset;
        max-width: unset;
        display: flex;
        align-items: center;
        gap: 65px;
      }
    }
  }
}
