@use '../../../../styles/colors.scss';
@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.FormPrimary {
  background-color: variables.$form--dropdown--background;
  font-size: 16px;
  line-height: 16px;
}
.FormSecondary {
  background-color: variables.$field-background;
  font-size: 16px;
  line-height: 16px;
}
.Placeholder {
  color: colors.$black--dark--3 !important;
}

.Field {
  position: relative;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  gap: 10px;
  height: 48px;
  width: 100%;
  border-radius: variables.$component-radius;
  align-items: center;
  z-index: 3;

  .Label {
    width: 100%;
    font-size: 16px;
    line-height: 1.1;
    overflow: hidden;
    text-transform: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: variables.$field-text-color;
  }
  .Arrow {
    display: flex;
    align-content: center;
    align-items: center;
    vertical-align: center;
  }
  .ArrowDown {
    transition: transform 0.15s;
    transform: rotate(0deg);
  }
  .ArrowUp {
    transition: transform 0.15s;
    transform: rotate(180deg);
  }
  &:hover {
    cursor: pointer;
  }
}

@media only screen and (max-width: breakpoints.$mobile) {
  .Field {
    width: 100%;
  }
}
