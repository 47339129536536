@use '../../styles/breakpoints.scss';
.ChannelList {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}
@media only screen and (max-width: breakpoints.$sm) {
  .ChannelList {
    flex-direction: column;
    flex-wrap: unset;
  }
}
