@use '../../../../styles/variables.scss';
@use '../../../../styles/colors.scss';
@use '../../../../styles/breakpoints.scss';

.CompetitionSelectorHeader {
  display: flex;
  gap: 12px;
  flex-direction: column;

  .CompetitionSelectorHeaderTitle {
    margin: 0;
  }

  .CompetitionSelectorHeaderDescription {
    font-family: variables.$font--text--light;
    font-size: 16px;
    line-height: 24px;
    color: colors.$black--dark--2;
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .CompetitionSelectorHeader {
    .CompetitionSelectorHeaderTitle {
      font-family: variables.$font--text--bold;
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      color: colors.$black--dark--2;
    }
  }
}
