@use '../../../../styles/variables.scss';

.ShareComponentContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .MediaTitle {
    font-family: variables.$font--text--bold;
    font-size: 24px;
    line-height: 28px;
  }
  .ShareComponentSection {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .Heading {
      font-family: variables.$font--text--light;
      font-size: 16px;
      line-height: 24px;
    }
    .shareListSection {
      display: flex;
      gap: 24px;
      .RoundButton {
        padding: 8px;
        :first-child {
          height: 32px;
          width: 32px;
        }
      }
    }
  }
}

.AdvancedSection {
  .AdvancedButton {
    padding-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 16px;
    .AdvancedHeading {
      font-size: 16px;
      line-height: 120%;
      font-family: variables.$font--text--medium;
      width: 100%;
    }
    .Arrow {
      display: flex;
      align-content: center;
      align-items: center;
      vertical-align: center;
      transition: transform 0.15s;
    }
    .ArrowRotated {
      transform: rotate(180deg);
    }
    &:hover {
      cursor: pointer;
    }
  }
}
