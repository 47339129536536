@use '../../../../styles/variables.scss';

.ArticleTitleContainer {
  .Articletitle {
    font-family: variables.$font--text--bold;
    margin: 0px;
    display: -webkit-box;
    width: 100%;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 700;

    &.small {
      font-size: 16px;
      line-height: 24px;
      -webkit-line-clamp: 2;
    }
    &.medium {
      -webkit-line-clamp: 2;
      font-size: 20px;
      line-height: 28px;
    }
    &.large {
      -webkit-line-clamp: 1;
      font-size: 20px;
      line-height: 28px;
    }
    &.xlarge {
      -webkit-line-clamp: 1;
      font-size: 40px;
      line-height: 48px;
    }
  }
  .extraContent {
    padding-top: 24px;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
  }
}
