@use '../../styles/variables.scss';
.SidebarNavigation {
  padding: 32px 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 220px;
  background: variables.$default--light-background;
  border-right: 2px solid variables.$secondary--light-background;
  .MenuLinks {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
