@use '../../../../../styles/breakpoints.scss';
@use '../../../../../styles/variables.scss';

.FormContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  .FieldsContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 16px;

    .PhoneNumberField {
      width: 100%;
    }
  }
  .ButtonContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .SubmitButton {
      padding: 14px 32px;
      margin-top: 0;
    }
  }
}
.TextInputFields {
  width: 100%;
}

@media only screen and (max-width: breakpoints.$sm) {
  .FormContainer {
    .FieldsContainer {
      flex-direction: column;
    }
  }
}
