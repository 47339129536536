@use '../../../../styles/colors.scss';
@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.DropdownFieldContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  .Label {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 12px;
  }

  .TextField {
    border: none;
    width: 100%;
    border-radius: variables.$component-radius;
    height: 48px;
    padding: 16px 24px;
    background-color: colors.$grey--light--4;
    margin-bottom: 18px;
  }

  .DropdownListField {
    background-color: colors.$grey--light--4 !important;
  }

  .DropdownList {
    position: absolute;
    display: flex;
    flex-direction: column;
    max-height: 165px;
    margin-top: 12px;
    width: 100%;
    overflow: auto;
    border-radius: variables.$component-radius;
    z-index: 4;
    box-shadow: variables.$elevation-2;
    background-color: variables.$field--dropdown--item-background;
  }
}
@media only screen and (max-width: breakpoints.$mobile) {
  .DropdownList {
    width: 100%;
  }
}
