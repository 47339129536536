@use '../../../../../styles/variables.scss';
@use '../../../../../styles/breakpoints.scss';

.PurchaseListItem {
  display: flex;
  flex-direction: row;
  padding: 16px 16px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  border-radius: variables.$component-radius;
  border: variables.$ghost--default-border;
  background: variables.$secondary--light-background;
  .Information {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    .Date,
    .Amount {
      font-family: variables.$font--text--medium;
      font-size: 16px;
      font-style: normal;
      line-height: 16px;
    }
    .Date {
      width: 150px;
    }
    .ChannelName,
    .ObjectName {
      font-family: variables.$font--text--light;
      color: variables.$default--link-color;
      font-size: 16px;
      font-style: normal;
      line-height: 16px;
      width: 250px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 1050px) {
  .PurchaseListItem {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    .Information {
      flex-direction: column;
      width: 100%;
      gap: 12px;
      .Label {
        word-break: keep-all;
        color: variables.$default--text-color;
        font-family: variables.$font--text--medium;
        font-size: 16px;
        line-height: 16px;
      }
      .Date {
        font-family: variables.$font--text--medium;
        font-size: 16px;
        line-height: 16px;
      }
      .Date,
      .ChannelName,
      .ObjectName,
      .Amount {
        text-align: right;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 12px;
      }
    }
    .ReceiptButton {
      align-self: flex-end;
    }
  }
}

@media only screen and (max-width: breakpoints.$small-mobile) {
  .PurchaseListItem {
    .ChannelName,
    .ObjectName {
      word-break: break-all;
    }
  }
}
