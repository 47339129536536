@use '../../../../styles/variables.scss';

.Container {
  padding: 24px 51px;
  background-color: variables.$secondary--light-background;

  .Selection {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    padding-bottom: 16px;
  }
}
