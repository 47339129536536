@use '../../../../../styles/variables.scss';

.ProgressControl {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin: 0 12px 0 12px;
  height: 25px;
  padding-top: 5px;
  align-items: center;
  pointer-events: all;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &.ads {
    display: none;
  }
  .TimePop {
    position: absolute;
    color: #fff;
    background: rgba(25, 25, 25, 0.8);
    padding: 0.2em 0.5em;
    font-size: 0.7em;
    top: -25px;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
    border-radius: 2px;
    text-align: center;
    pointer-events: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #fff;
    font-size: 0.85em;
    font-family: 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
    opacity: 1;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
    font-weight: 500;
    &:after {
      content: '';
      width: 0px;
      height: 0px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid rgba(0, 0, 0, 0.8);
      position: absolute;
      margin: 0 auto;
      left: 0;
      right: 0;
      top: 21px;
      font-size: 12px;
    }
  }
  .Track {
    width: 100%;
    border-radius: 0;
    background: rgba(255, 255, 255, 0.4);
    height: 6px;
    position: relative;
    pointer-events: none;
    .Progress {
      width: 100%;
      height: 100%;
      background: variables.$brand--primary;
      pointer-events: none;
      transform-origin: 0 0;
      position: absolute;
      &.pressed {
        transition: none;
      }
      &.ads {
        background: yellow;
        transition: transform 1s linear;
      }
    }
    .Thumb {
      width: 16px;
      height: 16px;
      background: variables.$brand--primary;
      border-radius: 50%;
      position: absolute;
      top: -5px;
      margin-left: -8px;
      pointer-events: none;
      transform: scale(0);
      &.pressed {
        transition: none;
        transform: scale(1);
      }
    }
  }
  &:hover {
    .Track {
      .Thumb {
        transform: scale(1);
      }
    }
    .TimePop {
      // display: block;
    }
  }
  &.animate {
    .Track {
      .Progress {
        transition: transform 1s linear;
        &.pressed {
          transition: none;
        }
      }
      .Thumb {
        transition: left 1s linear, transform 0.1s ease-in;
        &.pressed {
          transition: none;
        }
      }
    }
  }

  input[type='range'] {
    -webkit-appearance: none;
    width: 100%;
    background: none;
    transition: 0.1s width linear;
    -webkit-transition: 0.1s width linear;
    &::-webkit-slider-runnable-track {
      background: rgba(255, 255, 255, 0.4);
      border-radius: 6px;
      height: 8px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    &::-webkit-slider-thumb {
      display: none;
      -webkit-appearance: none;
      cursor: pointer;
      background: #eaeaea;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      opacity: 0.7;
      transition: all linear 0.1s;
      position: absolute;
      left: 0;
      border-radius: 0;
    }
    &:hover {
      &::-webkit-slider-runnable-track {
        // height: 14px;
      }
      &::-webkit-slider-thumb {
        // width: 14px;
        // height: 14px;
      }
    }
  }
  &:hover {
    input[type='range'] {
      &::-webkit-slider-thumb {
        display: block;
      }
    }
  }
}
