@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.Chat {
  .MessagesContainer {
    padding: 10px variables.$padding--left-right--desktop 64px;
    height: 770px;
    overflow-y: auto;
    background: variables.$chat--messages--container--background-color;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .LoaderContainer {
      padding-top: 24px;
    }

    .Message {
      .AlignRight {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .Chat {
    .MessagesContainer {
      height: 500px;
      padding: 10px variables.$padding--left-right--tablet 32px;
    }
  }
}

@media only screen and (max-width: breakpoints.$mobile) {
  .Chat {
    .MessagesContainer {
      padding: 10px variables.$padding--left-right--mobile 32px;
    }
  }
}

.IsViewingStream {
  height: 100%;
  min-height: 100%;
  position: relative;
  padding: 0;
  .MessagesContainer {
    padding: 16px !important;
    min-height: calc(100% - 98.5px);
    height: calc(100% - 98.5px);
    padding-bottom: 70;
    .LoaderContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding-top: 0;
    }
  }
  .InputContainer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .IsViewingStream {
    position: unset;
    .MessagesContainer {
      .LoaderContainer {
        padding-top: 10px;
      }
    }
    .InputContainer {
      position: fixed;
    }
  }
}
