@use '../../../../styles/colors.scss';

.InnerContainer {
  margin-top: -22px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  .ItemsContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-left: 1px solid colors.$black--dark--3;
  }
}
