@use '../../../../../styles/variables.scss';
.DesktopMenu {
  position: fixed;
  background: variables.$hamburger-menu-background;
  border-radius: variables.$component-radius;
  box-shadow: variables.$elevation-1;
  z-index: 102;
  right: 110px;
  top: 80px;
  width: 480px;
  text-transform: none;
  max-height: 80vh;
  overflow: auto;
}
