@use '../../../../../styles/breakpoints.scss';

.PriceFields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  .Field {
    max-width: 218px;
  }
}
@media only screen and (max-width: breakpoints.$mobile) {
  .PriceFields {
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    .Field {
      max-width: unset;
    }
  }
}
