@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.SettingsTabContainer {
  display: flex;
  flex-direction: column;
  background: variables.$default--light-background;
  padding: 0 48px 12px;
  gap: 32px;
  border-radius: variables.$component-radius;
}

@media only screen and (max-width: breakpoints.$sm) {
  .SettingsTabContainer {
    padding: 0;
    gap: 12px;
  }
}
