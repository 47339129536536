@use '../../../../../styles/variables.scss';

.CompanionAppSection {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  margin-top: 48px;
  .Text {
    text-align: center;
    font-family: variables.$font--text--light;
    font-size: 16px;
    line-height: 24px;
  }
}
.Themed {
  padding: 12px;
  border-radius: 2px;
  border: 1px solid variables.$channel-theme--primary-color-inverted;
  background-color: variables.$channel-theme--primary-color;
  .Text {
    color: variables.$channel-theme--primary-color-inverted;
  }
}
