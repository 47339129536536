@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.StreamOverview {
  width: 100%;
  height: 84vh;
  min-height: 600px;
  max-height: 800px;
  text-transform: none;
  position: relative;
  .Background {
    width: 100%;
    height: 100%;
    position: relative;
    .Overlay {
      position: absolute;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.75) 8.71%,
        rgba(0, 0, 0, 0.7) 89.22%
      );
      z-index: 2;

      .InnerContainer {
        padding-left: variables.$padding--left-right--desktop;
        padding-right: variables.$padding--left-right--desktop;
        padding-bottom: 80px;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 16px;
        .CountdownContainer {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: center;
          padding-bottom: 12vh;
        }
        .PlayIconContainer {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-bottom: 16vh;
        }
      }
    }
    .TeamVsTeamBackground {
      width: 100%;
      height: 100%;
    }
    .BackgroundImage {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }
}
.HasTeamVsTeamBackgrond {
  .Background {
    .Overlay {
      .InnerContainer {
        .CountdownContainer {
          padding-bottom: 6vh !important;
        }
        .PlayIconContainer {
          padding-bottom: 6vh !important;
        }
      }
    }
  }
}
@media only screen and (max-height: 780px) {
  .StreamOverview {
    .Background {
      .Overlay {
        .InnerContainer {
          padding-bottom: 40px;
        }
      }
    }
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .StreamOverview {
    .Background {
      .Overlay {
        .InnerContainer {
          padding-left: variables.$padding--left-right--tablet;
          padding-right: variables.$padding--left-right--tablet;
          padding-bottom: 32px;
          .PlayIconContainer,
          .CountdownContainer {
            height: 100%;
            align-items: center;
            justify-content: center;
            padding-bottom: unset;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: breakpoints.$mobile) {
  .StreamOverview {
    height: 82vh;

    .Background {
      .Overlay {
        .InnerContainer {
          padding-left: variables.$padding--left-right--mobile;
          padding-right: variables.$padding--left-right--mobile;
          padding-bottom: 24px;
          .CountdownContainer {
            align-items: start;
            padding-top: 60px;
            justify-content: center;
          }
        }
      }
    }
  }
}
