.ImageLinkForm {
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-sizing: border-box;

  .TextInput {
    width: 100%;

    input {
      height: 48px;
      padding: 0;
      box-sizing: border-box;
      padding-left: 16px;
    }
  }
  .NewTabToggle {
    padding-left: 16px;
  }

  .FormError {
    display: flex;
    justify-content: flex-start;
  }
}
