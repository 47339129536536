@import '../../../../styles/variables.scss';
@import '../../../../styles/breakpoints.scss';

.ArticleDateContainer {
  .LightSubText {
    color: $article-thumnbnail--extra--data--text-color;
    font-weight: 400;
    &.small {
      font-size: 10px;
      line-height: 10px;
    }
    &.medium,
    &.large {
      font-size: 12px;
      line-height: 12px;
    }
    &.xlarge {
      font-size: 14px;
      line-height: 22px;
    }
  }
}
