@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.ExpandedToggleContainer {
  display: flex;
  justify-content: left;
  align-items: left;
  padding-top: 32px;
  cursor: pointer;
  svg {
    transition: transform 0.15s;
  }

  svg:not(.IsExpanded) {
    transform: rotate(0deg);
  }
  .IsExpanded {
    transform: rotate(180deg);
  }
  .UpAndDownIcon {
    height: 24px;
    width: 24px;
  }
}

@media screen and (max-width: breakpoints.$sm) {
  .ExpandedToggleContainer {
    padding-top: 24px;
  }
}
@media screen and (max-width: breakpoints.$mobile) {
  .ExpandedToggleContainer {
    padding-top: 24px;
  }
}
