@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.InfoBanner {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  align-items: center;
  border-radius: $component-radius;
  background: $info--banner--background;
  max-width: $banner--desktop--max-width;
  gap: 32px;
  padding: 32px;
  justify-content: space-between;
  &:hover {
    background: $info--banner--hover-background;
    text-decoration: none;
  }

  .Content {
    display: flex;
    flex-direction: column;
    .TopContainer {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 32px;
      .Icon {
        align-self: flex-start;
        height: 36px;
        width: 36px;
      }
      .Title {
        color: $info--banner-color;
        font-family: $font--text--bold;
        font-size: 24px;
        line-height: 28px;
      }
    }
    .SubTitle {
      color: $info--banner-color;
      font-size: 16px;
      line-height: 24px;
      padding-left: 68px;
    }
  }
  .ArrowIcon {
    width: 40px;
    height: 40px;
    min-width: 40px;
  }
}

@media only screen and (max-width: $sm) {
  .InfoBanner {
    gap: 10px;
    padding: 16px 24px 16px 20px;
    .Content {
      .TopContainer {
        gap: 10px;
        .Icon {
          width: 16px;
          height: 16px;
        }
        .Title {
          font-size: 16px;
          line-height: 24px;
        }
      }
      .SubTitle {
        font-size: 14px;
        line-height: 18px;
        padding-left: 24px;
      }
    }
    .ArrowIcon {
      width: 32px;
      height: 32px;
      min-width: 32px;
    }
  }
}
