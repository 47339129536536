@use '../../styles/variables.scss';
.Avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
  width: 42px;
  height: 42px;
  border-radius: 100px;
  border: 2px solid;
  text-align: center;
  font-family: variables.$font--text--bold;
  font-size: 17px;
  font-style: normal;
  line-height: 26px;
}
.Red {
  border-color: variables.$brand--primary;
  color: variables.$brand--primary;
}
.Dark {
  border-color: variables.$secondary--default-background;
  color: variables.$default--text-color;
}
.Light {
  border-color: variables.$white;
  color: variables.$white;
}
