@use './colors.scss';
@use './fonts.scss';

:root {
  --channel-theme--text-transform: #{fonts.$transform};
  --channel-theme--primary-color: #{colors.$black--dark--1};
  --channel-theme--primary-background-color: #{colors.$white};

  --channel-theme--primary-color-inverted: #{colors.$black--dark--1};
  --channel-theme--primary-background-color-inverted: #{colors.$white};
  --channel-theme--heading-font-family: #{fonts.$font--text--bold};
  --channel-theme--heading-font-weight: #{fonts.$font--heading--weight};
  --channel-theme--submenu-font-family: #{fonts.$font--text--medium};
  --channel-theme--submenu-font-weight: #{fonts.$font--submenu--weight};
  --channel-theme--primary-highlight-color: #{colors.$black--dark--1};
  --channel-theme--primary-highlight-color: #{colors.$black--dark--1};
  --channel-theme--call-to-action-color: #{colors.$white};
  --channel-theme--call-to-action-background-color: #{colors.$solid-red};
}

$channel-theme--text-transform: var(--channel-theme--text-transform);
$channel-theme--primary-color: var(--channel-theme--primary-color);
$channel-theme--primary-background-color: var(
  --channel-theme--primary-background-color
);
$channel-theme--primary-color-inverted: var(
  --channel-theme--primary-color-inverted
);
$channel-theme--primary-background-color-inverted: var(
  --channel-theme--primary-background-color-inverted
);
$channel-theme--heading-font-family: var(--channel-theme--heading-font-family);
$channel-theme--heading-font-weight: var(--channel-theme--heading-font-weight);
$channel-theme--submenu-font-family: var(--channel-theme--submenu-font-family);
$channel-theme--submenu-font-weight: var(--channel-theme--submenu-font-weight);
$channel-theme--primary-highlight-color: var(
  --channel-theme--primary-highlight-color
);
$channel-theme--cta--background-color: var(
  --channel-theme--call-to-action-background-color
);
$channel-theme--cta-color: var(--channel-theme--call-to-action-color);
