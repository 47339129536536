@use '../../../styles/variables.scss';
.PhoneNumberField {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  .FieldContainer {
    height: 48px;
    display: flex;
    flex-direction: row;
    position: relative;
    .PhoneNumberField {
      border: none;
      width: 100%;
      border-radius: variables.$component-radius;
      font-size: 16px;
      font-family: variables.$font--text--light;
      padding: 16px 24px;
      letter-spacing: -0.16px;
      background-color: variables.$form--text--area--background-color;
    }
  }
  .Error {
    border: 1px variables.$form--error--text-color solid;
  }
}
