@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.SponsorSlider {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  padding: 24px $padding--left-right--desktop;
  background: $sponsor--slider--background-color;

  .Sponsors {
    max-width: 100%;
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    div {
      margin: 0 1vw;
    }
  }
}

@media only screen and (max-width: $sm) {
  .SponsorSlider {
    padding: 24px $padding--left-right--tablet;
    .Sponsors {
      div {
        margin: 0 5px;
      }
    }
  }
}

@media only screen and (max-width: $mobile) {
  .SponsorSlider {
    padding: 16px $padding--left-right--mobile;

    .Sponsors {
      div {
        margin: 2px 8px;
      }
    }
  }
}
