@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.ToggleItem {
  box-sizing: border-box;
  padding: 16px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-family: $font--text--medium;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  border-radius: $component-radius;
}
.Active {
  background-color: $secondary--default-background;
  border-right: 2px solid $secondary--default-background !important;
  color: $secondary--default-color;
}
.Default {
  color: $ghost-color;
  cursor: pointer;
  &:hover {
    background-color: $ghost--hover-background;
  }
}

@media only screen and (max-width: $mobile) {
  .ToggleItem {
    text-align: center;
    padding: 8px 10px;
    line-height: 12px;
  }
}
