@use '../../../../../../styles/variables.scss';
@use '../../../../../../styles/breakpoints.scss';

.RowCell {
  color: variables.$default--text-color;
}
.TeamRowCell {
  display: flex;
  align-items: center;
  .TeamImage {
    height: 32px;
    width: 32px;
  }
}
.DefaultTeamRowCell {
  width: 66px;
}
.CategoryTeamRowCell {
  width: 26px;
  .TeamImage {
    height: 26px;
    width: 26px;
  }
}

.DefaultRankRowCell {
  text-align: center;
  font-family: variables.$font--text--bold;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  width: 38px;
}
.CategoryLeaderRankRowCell {
  width: 18px;
  text-align: left;
  font-size: 16px;
  font-family: variables.$font--text--medium;
  line-height: 24px;
}
.PlayerRowCell {
  width: 308px;
  display: flex;
  flex-direction: column;
  .Name {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
  }
  .TeamName {
    font-family: variables.$font--text--light;
    font-size: 14px;
    line-height: 14px;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
  }
}
.DefaultPlayerRowCell {
  .Name {
    font-family: variables.$font--text--light;
  }
}
.CategoryLeaderPlayerRowCell {
  width: 100%;
  max-width: 255px;
  .Name {
    font-size: 16px;
    line-height: 24px;
    font-family: variables.$font--text--medium;
  }
  .TeamName {
    font-family: variables.$font--text--light;
    font-size: 12px;
    line-height: 12px;
  }
}
.DefaultPlayerRowCell {
  .Name {
    font-family: variables.$font--text--light;
  }
}
.CategoryLeaderPlayerRowCell {
  width: 100%;
  .Name {
    font-size: 16px;
    line-height: 24px;
    font-family: variables.$font--text--medium;
  }
  .TeamName {
    font-family: variables.$font--text--light;
    font-size: 12px;
    line-height: 12px;
  }
}
.DefaultRowCell {
  width: 150px;
  text-align: center;
  font-family: variables.$font--text--light;
  font-size: 20px;
  line-height: 28px;
  &.Selected {
    font-family: variables.$font--text--semi--bold;
  }
}
.CategoryLeaderRowCell {
  font-family: variables.$font--text--semi--bold;
  width: 25px;
  text-align: right;
}

@media only screen and (max-width: breakpoints.$sm) {
  .DefaultTeamRowCell {
    width: 100px;
    justify-content: center;

    &.Selected {
      width: 110px;
    }
    .TeamImage {
      height: 30px;
      width: 30px;
    }
  }
  .DefaultRankRowCell {
    width: 30px;
    font-size: 16px;
    line-height: 24px;
  }
  .CategoryLeaderPlayerRowCell {
    max-width: 164px;
  }
  .PlayerRowCell {
    width: 100%;
    .Name {
      font-size: 16px;
      line-height: 24px;
    }
    .TeamName {
      font-size: 12px;
      line-height: 12px;
    }
  }
  .DefaultRowCell {
    width: 80px;
    font-size: 16px;
    line-height: 24px;
    &.Selected {
      width: 100px;
    }
  }
}
@media only screen and (max-width: breakpoints.$mobile) {
  .DefaultPlayerRowCell {
    width: 150px;
  }
  .DefaultTeamRowCell {
    width: 60px;
    &.Selected {
      width: 80px;
    }
  }
  .DefaultRowCell {
    width: 60px;
    &.Selected {
      width: 90px;
    }
  }
}
