@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.AsideMediaSectionContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 64px;
  padding: 120px $padding--left-right--desktop;
  max-width: 100%;
  .MediaContainer {
    flex: 1;
    .Media {
      width: 100%;
      max-width: 100%;
      max-height: 600px;
      object-fit: contain;
      align-self: flex-end;
    }
    .Video {
      box-shadow: $media--content--shadow;
    }
  }
  .TextButtonContainer {
    flex: 1;
  }
}
.PrimaryBackground {
  background-color: $aside--media--section--background--primary;
}
.SecondaryBackground {
  background-color: $aside--media--section--background--secondary;
}

@media only screen and (max-width: $sm) {
  .AsideMediaSectionContainer {
    gap: 32px;
    flex-direction: column;
    padding: 80px $padding--left-right--tablet;
    align-items: center;
    .MediaContainer {
      .Media {
        max-height: 320px;
      }
    }
    .TextButtonContainer {
      flex: 1;
      font-family: $font--text--light;
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: $xs) {
  .AsideMediaSectionContainer {
    padding: 80px $padding--left-right--mobile;
    .MediaContainer {
      .Video {
        box-shadow: $media--content--shadow;
      }
    }
  }
}
