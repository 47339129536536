@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.InfoSectionContainer {
  background-color: $info--section-background-dark;
  padding: 120px $padding--left-right--desktop;
  .InfoSectionHeading {
    font-size: 30px;
    color: $info--section--text-color;
    font-family: $font--text--semi--bold;
  }
  .InfoItemsContainer {
    display: flex;
    flex-flow: row wrap;
    gap: 56px 32px;
    padding-top: 56px;
  }
}
.Black {
  background-color: $info--section-background-dark;
}

.Red {
  background-color: $info--section-background-red;
}
@media only screen and (max-width: $sm) {
  .InfoSectionContainer {
    padding: 72px $padding--left-right--tablet;
    .InfoItemsContainer {
      gap: 40px;
      padding-top: 40px;
    }
  }
}

@media only screen and (max-width: $mobile) {
  .InfoSectionContainer {
    padding: 72px $padding--left-right--mobile;
  }
}
