@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.OuterContainer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  background-color: variables.$viewer--videoplayer--background-color;
  .Container {
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 2363px;
    display: flex;
    flex-direction: column;
    .InnerContainer {
      max-height: 100%;
      max-width: 100%;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      .VideoContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        background-color: variables.$viewer--videoplayer--background-color;
        position: relative;
        .VideoPlayer {
          display: flex;
          width: 100%;
          max-height: 100%;
          max-width: 1870px;
        }
        .TopActionsBar {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          z-index: 101;
        }
      }
      .Navigation {
        max-height: 100%;
        height: 100%;
        width: 500px;
      }
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .OuterContainer {
    top: 0;
    justify-content: flex-start;
    .Container {
      overflow: hidden;
      flex-direction: column;
      .InnerContainer {
        justify-content: flex-start;
        flex-direction: column;
        .VideoContainer {
          align-items: flex-start;
          height: unset;
          .VideoPlayer {
            display: flex;
            flex-direction: column;

            .TopActionsBar {
              width: 100%;
              z-index: 101;
            }
          }
        }
        .Navigation {
          background-color: variables.$content--default-background;
          width: 100%;
        }
      }
    }
  }
}
