@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.ThemedFooterContainer {
  color: variables.$channel-theme--primary-color-inverted;
  background-color: variables.$channel-theme--primary-background-color-inverted;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;

  .ParentChannelContainer {
    display: flex;
    flex-direction: column;
    padding: 32px 0;
  }
  .SocialMediaContainer {
    display: flex;
    flex-direction: row;
  }
  .ChannelContactInfo {
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 22px;
  }
  .SectionDivider {
    padding: 0;
    margin: 0;
    width: 100%;
    line-height: 2px;
    border-top: 1px solid variables.$channel-theme--primary-color-inverted;
    mix-blend-mode: overlay;
    padding-bottom: 16px;
  }
  .GeneralSectionContainer {
    width: 100%;
    font-size: 14px;
    line-height: 22px;
    div {
      color: variables.$channel-theme--primary-color-inverted;
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .ThemedFooterContainer {
    padding-bottom: 48px;
    .ParentChannelContainer {
      padding: 24px 0;
    }
    .SectionDivider {
      padding-bottom: 8px;
    }
  }
}
