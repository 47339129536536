@use '../../../styles/variables.scss';
.CopyLinkField {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  position: relative;
  .LabelContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    vertical-align: middle;
    gap: 8px;
    .Icon {
      max-width: 16px;
      max-height: 16px;
      stroke: black !important;
      svg {
        width: 16px !important;
        height: 16px !important;
      }
    }
  }
  .FieldContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: variables.$copy-modal--input-background;
    border-radius: variables.$component-radius;
    overflow: hidden;
    .Field {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 16px 24px;
      background-color: variables.$copy-modal--input-background;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.04em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: variables.$font--text--semi--bold;
      border: none;
    }
    .CopyButton {
      padding: 16px 24px;
      background-color: variables.$copy-modal--input-background;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .ConfirmationBox {
    position: absolute;
    right: 0;
    top: 0;
    background-color: variables.$secondary--default-background;
    color: white;
    padding: 4px 6px;
    font-size: 14px;
    border-radius: variables.$component-radius;
    box-shadow: variables.$elevation-1;
  }
}
