@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.Medium {
  height: 500px;
}
.Large {
  height: 824px;
}
.TextFormModal {
  background: variables.$modal--default--background-color;
  border-radius: variables.$component-radius;
  display: flex;
  flex-direction: row;
  max-height: 80vh;

  .ModalLeftContent {
    width: 100%;
    height: 100%;
    padding-left: 110px;
    padding-right: 67px;
    background: variables.$modal--secondary--background-color;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: hidden;
    .TextContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding-top: 56px;
      padding-bottom: 64px;

      .SubTitle {
        color: variables.$modal--sub-title--color;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 110%;
        letter-spacing: 0.03em;
      }
      .Title {
        font-family: variables.$font--text--bold;
        color: variables.$modal--text--form--title-color;
        font-size: 64px;
        line-height: 120%;
        letter-spacing: -0.01em;
        padding: 16px 0;
        word-wrap: break-word;
      }
      .Description {
        color: variables.$modal--text--form--description-color;
        font-family: variables.$font--text--light;
        font-size: 20px;
        line-height: 28px;
      }
    }
    .SupportContent {
      height: fit-content;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      align-items: center;
      gap: 15px;
      font-size: 12px;
      padding-bottom: 73px;
      padding-right: 110px;
      a {
        display: inline-block;
      }
    }
  }

  .Children {
    max-height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 110px;
  }
}

@media only screen and (max-width: breakpoints.$md) {
  .Medium {
    height: 600px !important;
  }

  .TextFormModal {
    min-height: 80%;
    .ModalLeftContent {
      padding-left: 56px;
      padding-right: 56px;
      .TextContent {
        .Title {
          font-size: 40px;
        }
      }
    }
    .Children {
      padding-left: 56px;
      padding-right: 56px;
    }
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .Medium .Large {
    height: unset;
  }
  .TextFormModal {
    flex-direction: column;
    max-height: 100%;
    .ModalLeftContent {
      width: 100%;
      min-height: unset;
      height: fit-content;
      padding: unset;
      overflow-y: unset;
      .SupportContent {
        visibility: hidden;
        display: none;
      }
      .TextContent {
        padding: 80px 24px variables.$gutter--size--mobile 24px;
        height: 100%;
        .SubTitle {
          font-size: 12px;
          line-height: 13.2px;
          letter-spacing: 0.03em;
        }
        .Title {
          font-size: 24px;
          line-height: 28px;
          letter-spacing: -0.01em;
        }
        .Description {
          color: variables.$global--header--primary--text;
          font-size: 14px;
          line-height: 18px;
          font-weight: 400;
        }
      }
    }
    .Children {
      justify-content: center;
      box-sizing: border-box;
      width: 100%;
      padding: 16px 24px 56px;
    }
  }
}
