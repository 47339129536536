@use '../../../../../../styles/variables.scss';
.DetailsContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  .DetailsItem {
    font-family: variables.$font--text--medium;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    .Icon {
      height: 24px;
      width: 24px;
      svg {
        path {
          stroke: variables.$default--text-color !important;
        }
      }
    }
  }
  .ScoreboardDetailsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }
  .RefereeContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 880px;
    justify-content: center;
    gap: 4px;
    .DetailsItem {
      &::after {
        content: ', ';
      }
      &:last-child {
        &::after {
          content: '';
        }
      }
    }
  }
}
