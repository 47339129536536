@use '../../../../styles/variables.scss';
@use '../../../../styles/colors.scss';
@use '../../../../styles/breakpoints.scss';

.LabelContainer {
  border-top-right-radius: variables.$component-radius;
  border-bottom-left-radius: variables.$component-radius;
  display: inline-block;
  padding: 8px 10px;
  background: colors.$grey--light--2;
  color: colors.$black--dark--2;
  font-size: 14px;
  line-height: 14px;
  font-family: variables.$font--text--medium;
}

@media only screen and (max-width: breakpoints.$mobile) {
  .LabelContainer {
    font-size: 12px;
    line-height: 12px;
  }
}
