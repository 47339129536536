@use '../../../ContentVariables.scss' as contentVar;
@use '../../../../../styles/breakpoints.scss';

.StandingsTeam {
  display: flex;
  align-items: center;
  position: sticky;
  .StandingsTeamImage,
  svg {
    height: contentVar.$standings-team-image-size-desktop;
    width: contentVar.$standings-team-image-size-desktop;
    min-width: contentVar.$standings-team-image-size-desktop;
    margin-right: contentVar.$standings-team-image-gap-desktop;
  }

  .StandingsTeamPosition {
    text-align: center;
    width: contentVar.$standings-team-position-size-desktop;
    min-width: contentVar.$standings-team-position-size-desktop;
    margin-right: contentVar.$standings-team-position-margin-desktop;
  }
  .StandingsTeamName {
    max-height: contentVar.$standings-row-height-mobile;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@media screen and (max-width: breakpoints.$sm) {
  .StandingsTeam {
    .StandingsTeamImage,
    svg {
      height: contentVar.$standings-team-image-size-mobile;
      width: contentVar.$standings-team-image-size-mobile;
      min-width: contentVar.$standings-team-image-size-mobile;
      margin-right: contentVar.$standings-team-image-gap-mobile;
    }

    .StandingsTeamPosition {
      width: contentVar.$standings-team-position-size-mobile;
      min-width: contentVar.$standings-team-position-size-mobile;
      margin-right: contentVar.$standings-team-position-margin-mobile;
    }
    .StandingsTeamName {
      max-height: contentVar.$standings-row-height-mobile;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
@media screen and (max-width: breakpoints.$mobile) {
  .StandingsTeam {
    .StandingsTeamName {
    }
  }
}
