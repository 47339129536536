@use '../../../../../styles/variables.scss';
@use '../../../../../styles/breakpoints.scss';

.InputContainer {
  max-width: 100%;
  .FormContainer {
    display: flex;
    flex-direction: row;
    gap: 16px;
    width: 100%;
    .TextFieldContainer {
      width: 100%;
      .TextField {
        width: 100%;
        box-sizing: border-box;
        padding: 16px 24px;
        color: variables.$chat--input--text-color;
        font-size: 16px;
        line-height: 16px;
        border: none;
        border-radius: variables.$component-radius;
        background: variables.$chat--input--background-color;
      }
      .ErrorText {
        margin-top: 12px;
        font-size: 12px;
        line-height: 12px;
        color: variables.$form--error--text-color;
      }
    }
    .ButtonContainer {
      display: flex;
      flex-direction: row;
      gap: 16px;
      height: fit-content;
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .InputContainer {
    .FormContainer {
      display: flex;
      flex-direction: column;
      .TextFieldContainer {
        .ErrorText {
          font-size: 10px;
          line-height: 10px;
        }
      }
    }
  }
}
.IsViewingStream {
  .FormContainer {
    flex-direction: row;
  }
}
