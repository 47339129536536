@import '../../../../../styles/variables.scss';

.MobileSelectListContainer {
  position: fixed;
  inset: 0;
  z-index: 111;
  background: $mobile--select--list--background;
  background-blend-mode: darken;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .ListContainer {
    overflow-y: scroll;
    padding-top: 48px;
    padding-bottom: 92px;
    border-top-left-radius: $component-radius;
    border-top-right-radius: $component-radius;
    padding-left: $padding--left-right--mobile;
    padding-right: $padding--left-right--mobile;
    align-items: center;
    background-color: $mobile--select--list--inner--background;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .CloseIconContainer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: $mobile--select--list--close--container-background;
    padding-bottom: 24px;
    padding-top: 24px;
  }
}
