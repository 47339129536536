@import '../../../../../styles/variables.scss';
@import '../../../../../styles/breakpoints.scss';

.LogosContainer {
  .LogosContent {
    padding-top: 32px;
    padding-left: 120px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 48px;
  }
}

@media only screen and (max-width: $sm) {
  .LogosContainer {
    .LogosHeader {
      padding: 24px 16px;
    }
    .LogosContent {
      flex-direction: column;
      align-items: flex-start;
      gap: 32px;
      padding-left: 16px;
    }
  }
}
