@use '../../../../../styles/variables.scss';
@use '../../../../../styles/breakpoints.scss';

.BuyOptionBannerContent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  vertical-align: middle;
  gap: 16px;
  background-color: variables.$secondary--light-background;
  padding: 16px 28px;
  stroke: variables.$viewer--section--icon--default-color;
  width: 100%;
  color: variables.$modal--text--form--title-color;
  .IconContainer {
    display: flex;
    vertical-align: middle;
  }
  cursor: pointer;
  &:focus,
  &:focus-visible,
  &:hover {
    background-color: variables.$secondary--default-background;
    color: variables.$white !important;
    svg {
      stroke: variables.$viewer--section--icon--inverted-color;
      fill: variables.$viewer--section--icon--inverted-color;
    }
  }

  .TextSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    .BannerAmounText {
      font-family: variables.$font--text--bold;
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
    }

    .BannerDescriptionText {
      font-size: 14px;
      line-height: 14px;
      font-weight: 300;
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) and (orientation: portrait) {
  .BuyOptionBannerContent {
    box-sizing: border-box;
    padding: 18px 28px;
    width: unset;
    width: auto;
    .TextSection {
      .BannerDescriptionText {
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) and (orientation: landscape) {
  .BuyOptionBannerContent {
    box-sizing: border-box;
    padding: 18px 28px;
    width: auto;
    .TextSection {
      .BannerDescriptionText {
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
}

@media only screen and (max-width: breakpoints.$xs) {
  .BuyOptionBannerContent {
    padding: 18px 28px;
    width: auto;
    .TextSection {
      .BannerDescriptionText {
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
}
