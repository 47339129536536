.QualityPicker {
  position: relative;
  .Levels {
    position: absolute;
    min-width: 130px;
    background: rgba(0, 0, 0, 0.8);
    bottom: 0;
    right: 0;
    margin-bottom: 40px;
    padding: 1em;
    &:after {
      content: '';
      width: 0px;
      height: 0px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid rgba(0, 0, 0, 0.8);
      position: absolute;
      margin: 0 auto;
      // left: 0;
      right: 17px;
      bottom: -5px;
    }
    .Level {
      color: #ccc;
      font-size: 0.85em;
      font-family: 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande',
        sans-serif;
      opacity: 1;
      text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
      font-weight: 400;
      .addon {
        background: #444;
        padding: 1px 3px;
        font-weight: bold;
        color: #fff;
        font-size: 80%;
        border-radius: 3px;
        margin-left: 3px;
      }
      &:hover {
        color: #fff;
      }
      &.manual {
        font-weight: 600;
        color: #fff;
      }
      &.loading {
        color: #666;
      }
      .spinner {
        margin-left: 0.5em;
      }
      .check {
        margin-left: 0.5em;
      }
    }
  }
  .QualityPickerButton {
    color: #fff;
    width: 44px;
    height: 34px;
    align-items: center;
    justify-content: center;
    font-size: 0.7em;
    .QualityPickerIcon {
      &:hover {
        cursor: pointer;
        opacity: 1;
        -webkit-animation-name: cogSpin;
        -webkit-animation-duration: 400ms;
        -webkit-animation-iteration-count: 1;
        -webkit-animation-timing-function: linear;
        -moz-animation-name: cogSpin;
        -moz-animation-duration: 400ms;
        -moz-animation-iteration-count: 1;
        -moz-animation-timing-function: linear;
        -ms-animation-name: cogSpin;
        -ms-animation-duration: 400ms;
        -ms-animation-iteration-count: 1;
        -ms-animation-timing-function: linear;
        animation-name: cogSpin;
        animation-duration: 400ms;
        animation-iteration-count: 1;
        animation-timing-function: linear;
      }
    }
  }
}

// Animations
@-moz-keyframes cogSpin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(60deg);
  }
}
@-webkit-keyframes cogSpin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(60deg);
  }
}
@keyframes cogSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(60deg);
  }
}

@media (max-width: 370px) {
  .QualityPicker {
    display: none;
  }
}
