@use '../../../../../styles/variables.scss';
@use '../../../../../styles/breakpoints.scss';

.CommentBubbleContainer {
  gap: 8px;
  display: flex;
  padding: 16px 0 8px;
  max-width: variables.$message--bubble--max-width;
  .UserImage {
    height: 42px;
    width: 42px;
    object-fit: cover;
    border-radius: 50%;
    border: variables.$chat--message--user--image--border;
    align-self: flex-end;
  }
  .UserNameAndTextBox {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4px;
    .UserName {
      font-family: variables.$font--text--light;
      font-size: 12px;
      line-height: 12px;
      margin-left: 16px;
      color: variables.$chat--message--user--name-color;
    }
    .CommentBoxContainer {
      display: flex;
      position: relative;
      padding-left: 7px;
      .CommentText {
        border-radius: variables.$component-radius;
        padding: 8px;
        font-family: variables.$font--text--light;
        font-size: 16px;
        line-height: 24px;
        min-width: 100px;
        width: 100%;
        display: flex;
        flex-direction: column;
        background: variables.$chat--message--box-color;

        .TimeStamp {
          color: variables.$chat--message--user--name-color;
          padding-top: 4px;
          display: flex;
          justify-content: flex-end;
          line-height: 16px;
          font-size: 12px;
        }
      }
      .Triangle {
        position: absolute;
        bottom: 0;
        left: 0;
        border-left: 10px solid transparent;
        border-right: 0px solid transparent;
        border-bottom: 15px solid variables.$chat--message--box-color;
      }
    }
    .AdminFunctionality {
      position: absolute;
      display: flex;
      flex-direction: row;
      opacity: 0;
      right: 0;
      bottom: -20px;
      gap: 8px;
      .DeleteButton {
        color: variables.$chat--message--user--name-color;
        font-family: variables.$font--text--light;
        font-size: 14px;
        line-height: 18px;
        &:hover {
          cursor: pointer;
          color: variables.$chat--message--button--hover--text-color;
        }
      }
    }
    &:hover {
      .AdminFunctionality {
        opacity: 1;
      }
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .CommentBubbleContainer {
    .UserImage {
      height: 32px;
      width: 32px;
    }
    .UserNameAndTextBox {
      .UserName {
        font-size: 10px;
        line-height: 10px;
      }
      .CommentBoxContainer {
        .CommentMessageText {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
}
