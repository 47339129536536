@use '../../../styles/breakpoints.scss';
@use '../../../styles/variables.scss';

.SelectedTeamHeader {
  display: flex;
  flex-direction: column;
  gap: 24px;
  h4 {
    margin: 0;
    padding: 0 8px;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .SelectedTeamHeader {
    h4 {
      font-family: variables.$font--text--bold;
      font-size: 24px;
      line-height: 28px;
    }
  }
}
