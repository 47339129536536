@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';
.error {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  background-color: variables.$content--default-background;
  border: 1px solid #dddbdb;
  max-width: 100%;
  padding: 1.5rem;
}
@media screen and (max-width: breakpoints.$sm) {
  .error {
    width: unset;
    margin-top: -55px;
  }
}
