@import '../../../../styles/variables.scss';
@import '../../../../styles/breakpoints.scss';

.FeaturedContainer {
  h4 {
    font-family: $font--text--medium !important;
    margin-top: 8px !important;
    color: $brand--primary--text-light !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 32px !important;
    line-height: 110% !important;
    margin-bottom: 16px !important;
  }
  .Dates {
    font-family: $font--text--light;
    text-transform: uppercase;
    color: $brand--primary--text-light;
    margin-bottom: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
  }
  p {
    color: $brand--primary--text-light;
    font-size: 20px !important;
    line-height: 28px;
    margin: 0;
  }
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  height: 100%;
  width: 100%;
}
.FeaturedContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 32px;
  height: 100%;
  width: 100%;
}
.FeaturedContentData {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
@media only screen and (max-width: $sm) {
  .FeaturedContainer {
    h4 {
      margin-top: 8px !important;
      color: $brand--primary--text-light !important;
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 28px !important;
      margin-bottom: 0 !important;
    }
  }
  .FeaturedContent {
    padding: 16px;
  }
  .Description {
    display: none;
  }
}
