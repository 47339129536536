@use '../../styles/variables.scss';
@use '../../styles/breakpoints.scss';

.TooltipContainer {
  width: fit-content;
  position: relative;
  animation: fadeIn 1.5s;
  .Content {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: variables.$component-radius;
    color: variables.$tooltip--text-color;
    box-shadow: variables.$elevation-2;
    .Title {
      font-family: variables.$font--text--bold;
      font-size: 16px;
      font-style: normal;
      line-height: 24px;
    }
    .TextBox {
      font-family: variables.$font--text--light;
      font-size: 16px;
      line-height: 24px;
    }
    .ButtonContainer {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: flex-end;
    }
  }
  .Triangle {
    position: absolute;
  }
}
.Top {
  .Triangle {
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 15px solid;
    bottom: -15px;
  }
  .Left {
    right: 88%;
  }
  .Center {
    right: 45%;
  }
  .Right {
    right: 5%;
  }
}
.Bottom {
  .Triangle {
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 15px solid;
    top: -15px;
  }
  .Left {
    right: 90%;
  }
  .Center {
    right: 45%;
  }
  .Right {
    right: 5%;
  }
}
.light {
  .Content {
    background-color: variables.$tooltip--background-color;
  }
  .Triangle {
    color: variables.$tooltip--background-color;
  }
}
.dark {
  .Content {
    background-color: variables.$ghost--disabled-background;
  }
  .Triangle {
    color: variables.$ghost--disabled-background;
  }
}
@media only screen and (max-width: breakpoints.$mobile) {
  .TooltipContainer {
    width: fit-content;
    position: relative;
    .Content {
      .TextBox {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.01em;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
