@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.ViewerSubmenuContainer {
  padding: 28px variables.$padding--left-right--desktop;
  box-shadow: inset 0px 1px 0px variables.$ghost--inverted--disabled-color;
  overflow-x: auto;
  ul {
    padding: 0;
    margin: 0;
    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: variables.$menu-item--dark--active-color;
      gap: 40px;
      a {
        text-decoration: none;
        color: variables.$menu-item--dark--active-color;
      }
    }
    li:last-child {
      padding-right: 0;
    }
    list-style-type: none;
    display: flex;
    flex-direction: row;
  }
  display: flex;
  gap: variables.$gutter--size--desktop;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;

  .NotSelected {
    opacity: 0.65;
    font-weight: 400;
    &:hover,
    &:focus {
      opacity: 1;
    }
  }
}
.Divider {
  height: 1px;
  width: 100%;
  padding-top: 18px;
  box-shadow: 0px -1px 0px 0px variables.$ghost--inverted--disabled-color inset;
}
@media only screen and (max-width: breakpoints.$sm) {
  .ViewerSubmenuContainer {
    padding: 27px variables.$padding--left-right--tablet;
    ul {
      li {
        gap: 20px;
      }
    }
  }
}

@media screen and (max-width: breakpoints.$mobile) {
  .ViewerSubmenuContainer {
    padding: 18px variables.$padding--left-right--mobile;
    font-size: 14px;
    line-height: 14px;
    ul {
      li {
        gap: 16px;
      }
    }
  }
}

.IsViewingStream {
  padding: 18px 16px;
  font-size: 14px;
  line-height: 14px;
  box-shadow: 0px -1px 0px 0px variables.$ghost--inverted--disabled-color inset;
  ul {
    li {
      gap: 20px;
      padding-right: 0;
    }
  }
}
