.Loader {
  display: flex;
  justify-content: center;
}

.Large {
  div {
    width: 214px;
    height: 214px;
  }
}

.Small {
  div {
    width: 107px;
    height: 107px;
  }
}
