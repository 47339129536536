@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.TabListContainer {
  display: flex;
  flex-direction: column;
  border-radius: variables.$component-radius;
  min-height: 850px;
  color: variables.$default--text-color;
  .TabHeaderList {
    overflow-x: auto;
    display: flex;
    text-transform: uppercase;
    flex-direction: row;
    border-radius: variables.$component-radius;
    div:not(:last-child) {
      border-right: 2px solid variables.$inner--line--right;
    }
  }
  .TabHeader {
    display: flex;
    padding: 16px 10px;
    min-width: 200px;
    justify-content: center;
    align-items: center;
    border-radius: variables.$component-radius;
    background-color: variables.$ghost--disabled-background;
    font-size: 12px;
    line-height: 16px;
    font-family: variables.$font--text--bold;
    cursor: pointer;
  }
  .Selected {
    background-color: variables.$ghost--default-background;
    cursor: default;
  }
  .TabContent {
    padding: 48px 32px;
    background-color: variables.$ghost--default-background;
  }
}
@media screen and (max-width: breakpoints.$mobile) {
  .TabListContainer {
    .TabHeaderList {
      overflow-x: auto;
    }
    .TabContent {
      padding: 32px 16px;
      background-color: variables.$ghost--default-background;
    }
  }
}
