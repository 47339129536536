@use '../../../../../styles/variables.scss';
@use '../../../multiStageTournamentVariables.scss' as mst;
.Container {
  display: flex;
  padding: 8px 12px;
  width: fit-content;
  align-items: center;
  vertical-align: center;
  gap: 8px;
  border-radius: variables.$component-radius;
  border: mst.$filter--header--item--active--border;
  background: mst.$filter--header--item--active--background--color;
  &:hover {
    background: mst.$filter--header--item--active--hover--color;
    cursor: pointer;
  }
  .Label {
    font-family: variables.$font--text--bold;
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
    letter-spacing: 0.48px;
    text-transform: uppercase;
  }
  .Icon {
    display: flex;
    align-items: center;
    stroke: mst.$filter--header--item--active--color;
    height: 16px;
    width: 16px;
  }
}
.Disabled {
  background: mst.$filter--header--item--disabled--background--color;
  color: mst.$filter--header--item--disabled--color;
  border: mst.$filter--header--item--disabled--border;
  &:hover {
    cursor: unset;
  }
}
