@import '../../../../styles/variables.scss';
@import '../../../../styles/breakpoints.scss';

.AdminPanelContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  text-transform: none;
  gap: 16px;
  a {
    div {
      margin-bottom: 0 !important;
    }
  }
}
@media screen and (max-width: $sm) {
  .AdminPanelContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    a {
      width: 100% !important;
      div {
        width: unset;
      }
    }
  }
  .IsInChannelInfoSection {
    flex-direction: row;
    .ContentManagerButton,
    .SettingsButton {
      border-radius: 50px;
      padding: 16px !important;
    }
  }
}
