@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.LoginContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 24px;
  padding-top: 64px;
  padding-bottom: 12px;
  .BottomLogo {
    justify-self: flex-end;
    .Logo {
      margin-top: 24px;
      height: 16px;
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .LoginContent {
    padding: 0;
    .BottomLogo {
      margin-top: 32px;
    }
  }
}
