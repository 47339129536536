$solid-red: #ec1e24;
$solid-red--dark--1: #a90e13;

$black--dark--1: #000000;
$black--dark--2: #2f3135;
$black--dark--3: #93979f;

$grey--light--1: #c9cbcf;
$grey--light--2: #eaeaea;
$grey--light--3: #f5f5f5;
$grey--light--4: #e5e5e5;
$grey--light--5: #f0f0f0;

$white: #ffffff;

$link--color: #1e3fec;

$active--green--1: #25a741;
$active--green--2: #2ac24b;
