.SubtitlePicker {
  position: relative;
  .Subtitles {
    position: absolute;
    width: 80px;
    // height: 100px;
    background: rgba(0, 0, 0, 0.8);
    bottom: 0;
    right: 0;
    margin-bottom: 40px;
    padding: 1em;
    &:after {
      content: '';
      width: 0px;
      height: 0px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid rgba(0, 0, 0, 0.8);
      position: absolute;
      margin: 0 auto;
      right: 17px;
      bottom: -5px;
    }
    .Subtitle {
      color: #ccc;
      font-size: 0.85em;
      font-family: 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande',
        sans-serif;
      opacity: 1;
      text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
      font-weight: 400;
      &:hover {
        color: #fff;
      }
      &.current {
        font-weight: 600;
        color: #fff;
      }
      &.loading {
        color: #666;
      }
      .spinner {
        margin-left: 0.5em;
      }
      .check {
        margin-left: 0.5em;
      }
    }
  }
  .SubtitlePickerButton {
    color: #fff;
    width: 44px;
    height: 34px;
    align-items: center;
    justify-content: center;
    font-size: 0.7em;
  }
}
