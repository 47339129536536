@use '../../../../../styles/variables.scss';
.SearchIcon {
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  stroke: variables.$default--text-color;
  &:hover {
    cursor: pointer;
  }
}
.Themed {
  stroke: variables.$channel-theme--primary-color-inverted;
}
.Default {
  stroke: variables.$white;
}
.SearchFieldContainer {
  position: absolute;
  top: 64px;
  left: 0;
  right: 0;
}
.IsRootOrChildChannel {
  top: 104px !important;
}
.IsRootClubChannel {
  top: 64px !important;
}
