@use '../../../../../styles/variables.scss';
@use '../../../../../styles/breakpoints.scss';

.IconRowContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  .IconRow {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;

    gap: 16px;
  }
  .VerticalDivider {
    border-right: 1px solid variables.$channel-theme--primary-color-inverted;
    height: 20px;
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .IconRowContainer {
    flex-direction: column;
  }
}
