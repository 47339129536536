@import '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';

.Theme {
  text-transform: $channel-theme--text-transform;
  color: $channel-theme--primary-color;
  background-color: $channel-theme--primary-background-color;
}

.Section {
  flex: 1;
}
