@use '../../styles/colors.scss';
@use '../../styles/variables.scss';
@use '../../styles/breakpoints.scss';

.Banner {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 32px;
  width: 100%;
  max-width: variables.$banner--desktop--max-width;

  background: colors.$black--dark--2;
  &:hover {
    background: colors.$black--dark--1;
  }
  text-decoration: none;
  a {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-decoration: none;
    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
    &:active {
      text-decoration: none;
    }
    &:visited {
      text-decoration: none;
    }
    &:focus {
      text-decoration: none;
    }
  }

  border-radius: variables.$component-radius;
  justify-content: space-between;
  align-items: center;

  .IconAndTitleAndPriceTextAndDescription {
    display: flex;
    flex-direction: row;
    .TopContainer {
      display: flex;
      flex-direction: row;
      .Icon {
        margin-right: 24px;
        width: 36px;
        height: 36px;
      }

      .TitleAndPriceText {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .Title {
          font-family: variables.$font--text--bold;
          color: colors.$white;

          font-size: 32px;
          line-height: 110%;
          letter-spacing: -0.01em;

          margin-right: 16px;
        }
      }
    }

    .Description {
      display: flex;
      align-items: center;
      font-family: variables.$font--text--light;
      color: colors.$white;
      margin-left: 60px;
      font-size: 16px;
      line-height: 24px;
      min-height: 36px;
    }
  }
  .Button {
    display: flex;
    align-items: center;
    margin-left: 32px;
    .CloseButton {
      color: colors.$white;
    }
  }
}

@media screen and (max-width: breakpoints.$mobile) {
  .Banner {
    padding: 20px;
    width: 100%;

    .IconAndTitleAndPriceTextAndDescription {
      .TopContainer {
        .Icon {
          padding-top: 2px;
          align-content: center;
          margin-right: 10px;
          width: 16px;
          height: 16px;

          align-items: flex-start;
        }
        .TitleAndPriceText {
          flex-direction: column;
          align-items: flex-start;

          .Title {
            font-size: 16px;
            line-height: 24px;
            margin-right: 0;
          }
        }
      }
      .Description {
        font-size: 14px;
        line-height: 18px;
        margin-left: 24px;
        min-height: 18px;
      }
    }
    .Button {
      margin-left: 20px;
    }
  }
}
