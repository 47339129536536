@use '../../../../../../styles/colors.scss';
@use '../../../../../../styles/variables.scss';
@use '../../../../../../styles/breakpoints.scss';

.LogosItem {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.LogosItemLabel {
  font-family: variables.$font--text--bold;
  font-size: 16px;
  line-height: 1.25;
  color: colors.$black--dark--2;
}
