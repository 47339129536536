/* Branding on the banner for cookiebot*/
a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
  display: none;
}

/* Branding on the widget for cookiebot */
#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
  display: none;
}
