.adyen-checkout__button {
  background: #008000;
}
.adyen-checkout__payment-method__header {
  width: unset;
}
.adyen-checkout__status {
  max-width: 100%;
  margin: 0;
}
