@use '../../../../../../styles/variables.scss';
@use '../../../../../../styles/breakpoints.scss';

.SocialMediaContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
  align-items: center;
  a {
    height: 32px;
    width: 32px;
    svg path {
      fill: variables.$channel-theme--primary-color-inverted;
    }
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .SocialMediaContainer {
    padding-left: 0;
    gap: 24px;
  }
}
