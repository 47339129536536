@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.QuickEditModal {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  width: 100%;
  .ToggleButtonContainer {
    background-color: variables.$field--section-background !important;
    div > div {
      width: 167px;
    }
  }
}

@media only screen and (max-width: breakpoints.$mobile) {
  .QuickEditModal {
    .ToggleButtonContainer {
      width: 100%;
      div > div {
        width: 100%;
      }
    }
  }
}
