@import '../../../../styles/variables.scss';
@import '../../../../styles/breakpoints.scss';

.PromotedCategoriesContainer {
  padding: $section--padding--top-bottom--desktop 0;
}

@media screen and (max-width: $sm) {
  .PromotedCategoriesContainer {
    padding: $section--padding--top-bottom--mobile 0;
  }
}
