@import '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';

.PagePost {
  display: flex;
  flex-direction: column;
  gap: 88px;

  .PagePostContent {
    padding: 0 110px;
  }
}

@media only screen and (max-width: $mobile) {
  .PagePost {
    gap: 32px;

    .PagePostContent {
      padding: 0;
    }
  }
}
