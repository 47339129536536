@import '../.././../../styles/variables.scss';
@import '../.././../../styles/breakpoints.scss';

.ChecklistItemsContainer {
  border-radius: $component-radius;
  background: $checklist--container--background;
  .List {
    display: flex;
    flex-direction: column;
  }
}
.HelpTextContainer {
  padding: 32px 51px;
  color: $checklist--bottom--text;
  font-size: 14px;
  line-height: 22px;
  .Link {
    color: $report--link-color;
    text-decoration: none;
  }
}

@media only screen and (max-width: $sm) {
  .HelpTextContainer {
    padding: 32px 24px 24px 24px;
  }
}
