@use '../../../../../styles/breakpoints.scss';
@use '../../../../../styles/variables.scss';
@use '../../../ContentVariables.scss' as contentVar;

.StandingsForm {
  display: flex;
  gap: contentVar.$standings-form-bead-gap-desktop;
}

.StandingsFormBead {
  display: flex;
  justify-content: center;
  align-items: center;
  color: contentVar.$standings-form-text-color;
  width: contentVar.$standings-form-bead-size-desktop;
  height: contentVar.$standings-form-bead-size-desktop;
  text-align: center;
  font-size: 20px;
  font-family: variables.$font--text--medium;
  font-style: normal;
  line-height: 28px;
  color: contentVar.$standings-form-text-color;
  width: contentVar.$standings-form-bead-size-desktop;
  height: contentVar.$standings-form-bead-size-desktop;
  border-radius: 50%;
}

.Undecided {
  background-color: #979797;
}

.Win {
  background-color: contentVar.$standings-form-win-background-color;
}

.Loss {
  background-color: contentVar.$standings-form-loss-background-color;
}

.Draw {
  background-color: contentVar.$standings-form-draw-background-color;
}

.Unknown {
  background-color: contentVar.$standings-form-unknown-background-color;
}

@media screen and (max-width: breakpoints.$sm) {
  .StandingsForm {
    display: flex;
    gap: contentVar.$standings-form-bead-gap-mobile;
  }

  .StandingsFormBead {
    font-size: 16px;
    line-height: 24px;
    width: contentVar.$standings-form-bead-size-mobile;
    height: contentVar.$standings-form-bead-size-mobile;
  }
}
