@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';
.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 22px;
  gap: 32px;
}

@media only screen and (max-width: breakpoints.$sm) {
  .Container {
    padding-top: 0;
  }
}
