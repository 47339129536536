@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.Container {
  padding: 32px 120px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  .Selected {
    border: 2px solid variables.$dark--selected-border;
  }
  .BannerManager {
    border-radius: variables.$component-radius;
  }
}
@media only screen and (max-width: breakpoints.$md) {
  .Container {
    padding: 32px 48px;
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .Container {
    padding: 32px 16px;
  }
}
