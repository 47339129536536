@use '../../../../../styles/variables.scss';

.AccessRestrictionText {
  display: flex;
  flex-direction: column;
  color: variables.$default--text-color;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  .Label {
    font-family: variables.$font--text--bold;
    white-space: nowrap;
  }
  .Description {
    font-family: variables.$font--text--light;
  }
}
