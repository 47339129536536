@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.MyChannels {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px 24px;
  flex: 1;
  .ChannelsContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .SubText {
      font-family: variables.$font--text--light;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .MyChannels {
    padding: 24px 16px;
  }
}
