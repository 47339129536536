$icon-color: #ffffff;
$icon-hover-color: #e0e0e0;

#VideoPlayer {
  display: contents;
  cursor: pointer;

  video::-webkit-media-controls-panel {
    display: none !important;
    opacity: 1 !important;
  }
  button {
    background: none;
    border: none;
    cursor: pointer;
  }

  .BigCastButton {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    pointer-events: none;
    background-color: #000000ff;
  }
  #VideoControls {
    z-index: 98;
    max-width: 100%;
    width: 100%;
    .OverlayControls {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .OverlayControlsInner {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        .OverlayText {
          color: $icon-color;
          text-align: center;
          .OverlayTextTop {
            font-size: 2.1em;
            text-align: center;
            text-shadow: 1px 1px 14px rgba(0, 0, 0, 0.5);
          }
          .OverlayTextBottom {
            font-size: 1.2em;
            text-align: center;
            text-shadow: 1px 1px 14px rgba(0, 0, 0, 0.5);
          }
        }
        .OverlayCenter {
          width: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .ForwardButtonContainer,
        .RewindButtonContainer {
          min-width: 79px;
        }
      }
    }
    .ControlBar {
      position: absolute;
      z-index: 100;
      bottom: 0;
      left: 0;
      width: 100%;
      .Background {
        background: transparent;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
        background-repeat: repeat-x;
        background-position: bottom;
        height: 100px;
        bottom: 0;
        width: 100%;
        pointer-events: none;
        position: absolute;
      }
      .GoLiveButton {
        width: 100%;
        display: flex;
        padding-right: 12px;
        justify-content: flex-end;
      }
      .Controls {
        flex: 1;
        display: flex;
        justify-content: left;
        flex-direction: column;
        .PlayerButton {
          display: flex;
          z-index: 110;
          align-items: center;
          padding: 0;
          position: relative;
          width: 35px;
          height: 25px;
          justify-items: center;
          justify-content: center;
          .material-icons,
          .PlayerButtonIcon {
            font-size: 34px;
            color: $icon-color;
            position: absolute;
            &.AirplayIcon {
              font-size: 26px;
            }
          }
          &.Chromecast {
            .PlayerButtonIcon {
              font-size: 26px;
            }
          }
          &.FullscreenButton {
            &:hover {
              .PlayerButtonIcon {
                font-size: 38px;
              }
            }
          }
          &.QualityPickerButton {
            .PlayerButtonIcon {
              font-size: 26px;
            }
          }
          &:hover {
            .material-icons,
            .PlayerButtonIcon {
              color: $icon-hover-color;
            }
          }
        }
        .VolumeControl .PlayerButton {
          .PlayerButtonIcon {
            font-size: 26px;
          }
        }
        .ControlsRow {
          justify-content: space-between;
          align-items: center;
          flex: 1;
          flex-direction: row;
          display: flex;
          &:last-child {
            padding: 0 10px 5px 10px;
            margin-bottom: 3px;
          }
        }
      }
    }
    &.ads {
      font-size: 0.7em;
      .Background {
        background: rgba(0, 0, 0, 1);
        height: 30px;
      }
      .ControlBar {
        .Controls {
          .ControlsRow {
            padding: 0 5px 0px 5px;
          }
          .PlayerButton {
            font-size: 0.9em;
            height: 25px;
          }
        }
      }
    }
  }
}
