@import '../../../../../styles/variables.scss';
@import '../../../../../styles/breakpoints.scss';

.CustomDomainContainer {
  .CustomDomainForm {
    padding-top: 32px;
    padding-left: 120px;
  }
  .CustomDomainField {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
  .ButtonsContainer {
    padding-left: 16px;
  }
}

.DomainActionsButton {
  font-family: $font--text--medium;
}

@media only screen and (max-width: $sm) {
  .CustomDomainContainer {
    .CustomDomainField {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      div {
        width: 100%;
        input {
          text-overflow: ellipsis;
        }
      }
    }
    .CustomDomainForm {
      padding-left: 16px;
    }
    .ButtonsContainer {
      padding-left: 0;
    }
  }
}
