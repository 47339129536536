@use '../../../../styles/colors.scss';
@use '../../../../styles/breakpoints.scss';

.SubheadingContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 32px;
  align-items: center;
  .AddButton {
    cursor: pointer;
    svg {
      rect {
        stroke: #eaeaea;
      }
      path {
        stroke: colors.$black--dark--2;
      }
    }
    &:hover {
      rect {
        stroke: colors.$black--dark--1 !important;
      }
      path {
        stroke: colors.$black--dark--1 !important;
      }
    }
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .SubheadingContainer {
    .AddButton {
      padding-right: 8px;
    }
  }
}
