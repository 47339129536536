@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.LiveContentSection {
  padding-top: 64px;
  min-height: 10px;
  text-decoration: none;
  text-transform: none;
  color: $channel-page--slider--not--themed-color;
}

@media only screen and (max-width: $sm) {
  .LiveContentSection {
    padding-top: 24px;
  }
}
