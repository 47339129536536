@import '../../../../styles/variables.scss';
@import '../../../../styles/breakpoints.scss';

.TestimonialContainer {
  display: flex;
  flex-direction: column;
  .Quote {
    font-family: $font--text--bold;
    font-size: 40px;
    padding-bottom: 30px;
  }
  .QuoterInformation {
    font-size: 20px;
    .Name {
      font-family: $font--text--bold;
    }
  }
}

@media only screen and (max-width: $sm) {
  .TestimonialContainer {
    flex-direction: column;
    .Quote {
      font-size: 20px;
    }
    .QuoterInformation {
      font-size: 16px;
      padding-bottom: 0px;
    }
  }
}

@media only screen and (max-width: $xs) {
  .TestimonialContainer {
    flex-direction: column;
    .Quote {
      font-size: 20px;
    }
    .QuoterInformation {
      font-size: 16px;
      padding-bottom: 0px;
    }
  }
}

@media only screen and (max-width: $small-mobile) {
  .TestimonialContainer {
    .Quote {
      font-size: 16px;
    }
    .QuoterInformation {
      font-size: 14px;
    }
  }
}
