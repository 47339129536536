@use '../../../../../styles/variables.scss';
@use '../../../../../styles/breakpoints.scss';
.SubscriptionItem {
  background: variables.$secondary--light-background;
  border-radius: variables.$component-radius;
  display: flex;
  padding: 26px 27px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 274px;
  align-items: center;
  .ScarfIcon {
    height: 40px;
    width: 40px;
    margin-bottom: 8px;
  }
  .Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    text-align: center;
    .expireDate {
      font-style: italic;
      color: variables.$grey;
      font-size: 14px;
    }
    .TopContainer {
      display: flex;
      flex-direction: row;
      .Title {
        font-family: variables.$font--text--bold;
        font-size: 20px;
        line-height: 28px;
      }
    }
    .ChannelLink {
      color: variables.$default--link-color;
      font-family: variables.$font--text--bold;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.08px;
      text-decoration-line: underline;
      &:hover {
        cursor: pointer;
      }
    }
    .RootDomain {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 6px;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px;
      svg {
        height: 12px;
        width: 12px;
      }
    }
    .ProductPeriodCost {
      font-family: variables.$font--text--medium;
      font-size: 16px;
      line-height: 24px;
    }
    .AutoRenewText {
      margin-top: 8px;
      display: flex;
      flex-direction: column;
      font-family: variables.$font--text--bold;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.06px;
    }
  }

  .CancelButton {
    width: 100%;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .SubscriptionItem {
    align-items: flex-start;
    width: 100%;
    text-align: left;
    justify-content: center;
    .ScarfIcon {
      display: none;
    }
    .Content {
      align-items: flex-start;
      text-align: left;
      gap: 6px;
      width: 100%;
      .TopContainer {
        justify-content: space-between;
        align-items: center;
        align-content: center;
        width: 100%;
        .Title {
          font-size: 16px;
        }
        .ProductPeriodCost {
          font-size: 14px;
          text-align: right;
        }
        .AutoRenewText {
          margin-top: 0;
        }
      }
      .ChannelLink {
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.07px;
      }
    }
  }
}
