@use '../../styles/variables.scss';
@use '../../styles/breakpoints.scss';
@use './multiStageTournamentVariables.scss' as mst;

.SelectedEventPageContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: variables.$component-radius;
  background: mst.$event--schedule--background--color;
  gap: 16px;
  .CategoryFilterContainer {
    width: 100%;
    align-self: center;
    max-width: 599px;
  }
  .PreviousEventsDropdownContainer {
    width: 448px;
    padding-bottom: 32px;
  }
}

@media only screen and (max-width: breakpoints.$mobile) {
  .SelectedEventPageContainer {
    padding: 32px 0;

    .PreviousEventsDropdownContainer {
      width: 100%;
    }
  }
}
