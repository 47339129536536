@use '../../styles/breakpoints.scss';
@use '../../styles/variables.scss';
.NotSignedIn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  height: 55vh;
  .TextContent {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .Title,
    .SubText {
      text-align: center;
    }
  }
  .ButtonContainer {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
}
.LoaderContainer {
  background-color: variables.$content--default-background;
  height: 750px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: breakpoints.$mobile) {
  .NotSignedIn {
    .ButtonContainer {
      flex-direction: column;
      width: 100%;
      button {
        width: 100%;
      }
    }
  }
}
