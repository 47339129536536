@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.Light {
  color: $brand--primary--text-light;
}
.Dark {
  color: $landing--page--dark-color;
}
.LightBackground {
  background-color: $landing--page--light-background;
}
.BoldBackground {
  background-color: $brand--primary;
}
.LandingPageHeroLink {
  text-decoration: none;
  width: 100%;
  .LandingPageHeroSection {
    position: relative;
    width: 100%;
    height: calc(100vh - #{$footer-height} - #{$landing--page--header-height});
    min-height: 420px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      opacity: 0.15;
      filter: grayscale(100%);
    }

    .InnerContainer {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
      .TextContainer {
        h3 {
          margin: 0;
          font-family: $font--text--semi--bold;
          margin-bottom: 11px;
          text-transform: uppercase;
          font-size: 16px;
          line-height: 28px;
          text-align: center;
        }
        h2 {
          margin-top: 0;
          margin-bottom: 0;
          line-height: 120%;
          width: 452px;
          height: 197px;
          text-align: center;
          .Light {
            color: #ffffff;
          }
          .Dark {
            color: #000000;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $sm) {
  .LandingPageHeroSection {
    width: 100%;
    height: 332px !important;
    .InnerContainer {
      justify-content: center;
      .TextContainer {
        margin-top: unset !important;
        h3 {
          margin-bottom: 8px;
          font-size: 12px !important;
          line-height: 16px !important;
        }
        h2 {
          max-width: 273px;
          height: unset !important;
          font-size: 40px;
          padding-bottom: 32px;
        }
      }
    }
  }
}
