@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.LandingPageHeader {
  max-width: 100%;
  height: $landing--page--header-height;
  background: $white;
  padding-left: 110px;
  img {
    margin-top: 46px;
    height: 40px;
    width: auto;
  }
}

@media screen and (max-width: $sm) {
  .LandingPageHeader {
    padding-left: unset;
    height: 61px;
    text-align: center;
    img {
      margin-top: 16px;
      height: 18px;
    }
  }
}
