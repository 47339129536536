@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.ChannelInfoSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0 variables.$padding--left-right--desktop;
  gap: 16px;
  padding-top: 16px;
  .LeftContainer {
    display: flex;
    align-items: center;
    gap: 12px;
    img {
      height: 45px;
      width: 45px;
      object-fit: contain;
    }
    .ChannelName {
      width: 100%;
      font-family: variables.$font--text--medium;
      font-size: 32px;
      font-style: normal;
      line-height: 36px;
      text-transform: none;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      display: -moz-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      -moz-line-clamp: 2;
    }
  }
  .AdminButtons {
    width: fit-content;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .ChannelInfoSection {
    padding: 0 variables.$padding--left-right--tablet;
    padding-top: 16px;
    .LeftContainer {
      .ChannelName {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
}

@media only screen and (max-width: breakpoints.$mobile) {
  .ChannelInfoSection {
    padding: 0 variables.$padding--left-right--mobile;
    padding-top: 16px;
  }
  .IsAdmin {
    .LeftContainer {
      .ChannelName {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

@media only screen and (max-width: breakpoints.$small-mobile) {
  .IsAdmin {
    .LeftContainer {
      .ChannelName {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
}
