@use './colors.scss';
@forward './colors.scss';
@forward './fonts.scss';
@use './theming.scss';
@forward './theming.scss';

$white: colors.$white;
$grey: colors.$black--dark--3;

$brand--primary: colors.$solid-red;
$brand--primary--text-light: colors.$white;
$brand--primary--text-dark: colors.$black--dark--2;

$primary--default-background: colors.$solid-red;
$primary--hover-background: colors.$solid-red--dark--1;
$primary--disabled-background: rgba(colors.$solid-red, 0.5);
$primary--default-color: colors.$white;

$secondary--light-background: colors.$grey--light--3;
$secondary--light--hover-background: colors.$grey--light--2;

$secondary--default-background: colors.$black--dark--2;
$secondary--hover-background: colors.$black--dark--1;
$secondary--disabled-background: colors.$black--dark--3;
$secondary--default-color: colors.$white;

$content--default-background: colors.$white;

$active--default-background: colors.$active--green--2;
$active--hover-background: colors.$active--green--1;
$active--disabled-background: colors.$active--green--2;
$active--default-color: colors.$white;

$footer--primary--text: colors.$black--dark--3;
$footer--highlight--text: colors.$black--dark--1;
$footer-height: 148px;

$live--background-color: colors.$solid-red;
$planned--color: colors.$solid-red;
$ghost-color: colors.$black--dark--2;
$global--header--primary--text: colors.$black--dark--3;
$global--header--highlight--text: colors.$white;
$global--header--background: colors.$black--dark--1;

$dark--selected-border: colors.$black--dark--1;

$ghost--default-background: colors.$white;
$ghost--default-border: 1.5px solid colors.$grey--light--2;
$ghost--hover-background: colors.$grey--light--2;
$ghost--disabled-background: colors.$grey--light--2;

$ghost--inverted--default-background: rgba(colors.$white, 0.2);
$ghost--inverted--default-border: 1.5px solid colors.$white;
$ghost--inverted--default-color: colors.$white;
$ghost--inverted--hover-background: colors.$white;
$ghost--inverted--hover-color: colors.$black--dark--2;
$ghost--inverted--disabled-background: colors.$grey--light--1;
$ghost--inverted--disabled-color: colors.$grey--light--1;
$ghost--inverted--disabled-border: 1.5px solid colors.$grey--light--1;

$default--light-background: colors.$white;
$default--light-color: colors.$black--dark--2;
$hover--light-background: colors.$black--dark--3;
$hover--light-background-list: colors.$grey--light--2;
$hover--light-color: colors.$white;

$default--text-color: colors.$black--dark--2;
$default--link-color: colors.$link--color;

$default--dark-background: colors.$black--dark--2;
$default--dark-color: colors.$white;
$hover--dark-background: colors.$grey--light--2;
$hover--dark-color: colors.$black--dark--2;

$default--black-background: colors.$black--dark--1;
$default--black-color: colors.$white;
$hover--black-background: colors.$black--dark--2;
$hover--black-color: colors.$grey--light--2;

$default--alternate-background: colors.$black--dark--2;
$hover--alternate-background: colors.$solid-red;

$vertical--divider-background: colors.$grey--light--2;

$filter--pill--button-border: 1.5px solid colors.$black--dark--2;

$searchfield--default-background: colors.$grey--light--3;
$searchfield--default--hover-background: colors.$grey--light--2;
$searchfield--default--placeholder-color: colors.$black--dark--3;
$searchfield--default--hover--placeholder-color: colors.$black--dark--2;
$searchfield--default--active--text-color: colors.$black--dark--2;
$searchfield--default--active--background: colors.$grey--light--2;

$searchfield--dark-background: colors.$black--dark--2;
$searchfield--dark--hover-background: colors.$grey--light--2;
$searchfield--dark--placeholder-color: colors.$black--dark--3;
$searchfield--dark--hover--placeholder-color: colors.$black--dark--2;
$searchfield--dark--active-color: colors.$white;
$searchfield--dark--active--background: colors.$black--dark--2;

$searchfield--desktop--dark-background: rgba(colors.$black--dark--3, 0.32);
$searchfield--desktop--dark--hover-background: rgba(
  colors.$black--dark--3,
  0.6
);
$searchfield--desktop--dark--placeholder-color: colors.$grey--light--2;

$searchfield--desktop--dark--hover--placeholder-color: colors.$black--dark--2;
$searchfield--desktop--dark--active-color: colors.$white;
$searchfield--desktop--dark--active--background: rgba(
  colors.$black--dark--3,
  0.6
);

$breadcrumb--text: colors.$black--dark--3;
$breadcrumb--highlight: colors.$black--dark--1;
$breadcrumb--background: colors.$grey--light--3;

$padding--left-right--desktop: 110px;
$gutter--size--desktop: 24px;

$padding--left-right--tablet: 32px;
$gutter--size--tablet: 20px;

$padding--left-right--mobile: 16px;
$gutter--size--mobile: 16px;

$toggle--switch--on--background: colors.$grey--light--1;
$toggle--switch--off--background: colors.$black--dark--2;
$toggle--switch--circle--color: colors.$white;

$search-result--padding--top-bottom--desktop: 88px;
$search-result--padding--top-bottom--tablet: 32px;
$search-result--padding--top-bottom--mobile: 24px;

$search-result--channel-background: colors.$grey--light--3;
$search-result--video-background: colors.$white;

$section--padding--top-bottom--desktop: 40px;
$section--padding--top-bottom--tablet: 24px;
$section--padding--top-bottom--mobile: 24px;

$padding--hamburger--menu--left-right: 32px;

$padding--content--slider--arrow--left-right: 32px;

$elevation-1: 0px 100px 80px rgba(0, 0, 0, 0.07),
  0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
  0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
  0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
  0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
  0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
$elevation-2: 0px 0px 80px rgba(0, 0, 0, 0.07),
  0px 0px 33.4221px rgba(0, 0, 0, 0.0503198),
  0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
  0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
  0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
  0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);

$component-radius: 2px;
$button-border-radius: 2px;
$hover--transition-time: 0.4s;

$landing--page--light-background: colors.$grey--light--3;
$landing--page--dark-color: colors.$black--dark--1;
$landing--page--header-height: 128px;

$navigation--header-background: colors.$white;
$navigation--header--desktop-height: 96px;
$navigation--header--mobile-height: 56px;

$navigation--header--top-bottom--padding-desktop: 24px;
$navigation--header--top-bottom--padding-tablet: 24px;
$navigation--header--top-bottom--padding-mobile: 16px;

$menu-item--light--default-color: colors.$black--dark--3;
$menu-item--light--active-color: colors.$white;
$menu-item--light--hover-color: colors.$white;
$menu-icon-items--light--default-color: colors.$white;

$menu-item--dark--default-color: colors.$black--dark--3;
$menu-item--dark--active-color: colors.$black--dark--1;
$menu-item--dark--hover-color: colors.$black--dark--1;

$thumbnail--background--hover-color: colors.$black--dark--2;
$thumbnail--label--background--hover-color: colors.$black--dark--1;

$link--thumbnail--label-color: colors.$white;
$link--thumbnail--linear-gradient: linear-gradient(
  0deg,
  #000000 0%,
  rgba(47, 49, 53, 0) 50.13%
);
$link--thumbnail--linear-gradient-hover: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.42),
    rgba(0, 0, 0, 0.42)
  ),
  linear-gradient(0deg, #000000 0%, rgba(47, 49, 53, 0) 50.13%);

$vs--box--background-color: colors.$white;
$vs--box--text-color: colors.$black--dark--2;

$team--logo--background-color: colors.$white;
$team--vs--team--left--background-color: colors.$grey--light--1;
$team--vs--team--right--background-color: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.05),
    rgba(0, 0, 0, 0.05)
  ),
  colors.$grey--light--1;

$hamburger-menu-background: colors.$white;

$search-item--container-background-color: colors.$white;
$search-item--container-background-color--hover: colors.$grey--light--3;

$search-item--title-text-color: colors.$black--dark--2;
$search-item--published-text-color: colors.$black--dark--3;
$search-item--description-text-color: colors.$black--dark--3;
$search-item--event-text-color: colors.$black--dark--3;
$search-item--event-teams-text-color: colors.$black--dark--1;
$search-item--live-text-color: colors.$solid-red;
$search-item--category-text-color: colors.$black--dark--3;

$channel-page--channel-image-border-color: colors.$grey--light--3;
$channel-item--hover-color: colors.$grey--light--3;
$channel-page--slider--not--themed-color: colors.$black--dark--2;

$root-channel-page--hero--image--size--desktop: calc(350 / 1440 * 100%);
$root-channel-page--hero--image--size--mobile: calc(150 / 375 * 100%);

$channel-page--hero--image--size: calc(350 / 1440 * 100%);
$channel-page--hero--image--size--mobile: calc(150 / 375 * 100%);

$channel-page--hero--image--overlay--background: rgba(
  colors.$black--dark--1,
  0.67
);
$channel-page--hero--image--overlay--secondary-color: colors.$grey--light--3;
$thumbnail--section--Header--link--default-color: colors.$black--dark--3;
$thumbnail--section--Header--link--hover-color: colors.$black--dark--2;

$game--schedule--default--background-color: colors.$white;
$game--schedule--win-color: colors.$black--dark--2;
$game--schedule--lose-color: colors.$black--dark--3;
$game--schedule--equal-color: colors.$black--dark--2;
$game--schedule--default-color: colors.$black--dark--2;
$game--schedule--box-shadow: inset 0px -1px 0px #c9cbcf;

$game--card--primary--background-color: colors.$white;
$game--card--secondary--background-color: colors.$grey--light--3;
$game--card--link--section--border: 1px solid colors.$black--dark--1;
$game--card--time--container--background: colors.$grey--light--2;

$subscription--page-color: colors.$black--dark--2;

$subscription--card--is--purchased--tag-color: colors.$white;

$modal--default--background-color: colors.$white;
$modal--default--color: colors.$black--dark--2;
$modal--secondary--background-color: rgba(colors.$grey--light--2, 0.3);
$modal--blur--background-color: rgba(#2f3135, 0.3);
$modal--button--color: colors.$black--dark--3;
$modal--header--color: colors.$black--dark--3;
$modal--sub-title--color: colors.$black--dark--3;
$modal--button--hover: colors.$black--dark--1;

$modal--text--form--subtitle-color: colors.$solid-red;
$modal--text--form--title-color: colors.$black--dark--2;
$modal--text--form--description-color: colors.$black--dark--2;
$tag-color: colors.$black--dark--3;

$dragzone-border: 2px dashed colors.$black--dark--3;
$form--text--area--background-color: colors.$grey--light--3;
$form--text--area--hover--background-color: colors.$grey--light--2;
$form--text--area-color: colors.$black--dark--3;
$form--dropdown--background: colors.$grey--light--3;

$form--error--border: 1px solid colors.$solid-red;
$form--error--text-color: colors.$solid-red;

$sponsor--slider--background-color: colors.$white;
$sponsor--slider--text-color: colors.$black--dark--2;
$sponsor--vertical--divider--background-color: colors.$solid-red;

$viewer--section--league-color: colors.$black--dark--3;
$viewer--section-color: colors.$black--dark--2;
$viewer--about--section--background: colors.$grey--light--3;
$viewer--videoplayer--background-color: colors.$black--dark--1;
$viewer--actionPanel--background-color: colors.$white;

$preloader--background: colors.$grey--light--3;

$preloader--linear-gradient: linear-gradient(
  to right,
  colors.$grey--light--3 8%,
  colors.$grey--light--1 18%,
  colors.$grey--light--3 33%
);
$preloader--linear-gradient-2: linear-gradient(
  to right,
  colors.$grey--light--3 3%,
  colors.$grey--light--4 18%,
  colors.$grey--light--3 33%
);
$preloader--linear-gradient-3: linear-gradient(
  to right,
  colors.$grey--light--3 3%,
  colors.$grey--light--5 15%,
  colors.$grey--light--3 33%
);
$icon--default--stroke--color: colors.$black--dark--2;
$icon--light--stroke--color: colors.$black--dark--3;

$viewer--section--icon--default-color: colors.$black--dark--2;
$viewer--section--icon--inverted-color: colors.$white;

$chat--messages--container--background-color: colors.$white;
$chat--input--background-color: colors.$white;
$chat--input--text-color: colors.$black--dark--2;
$reporter--message--box-color: colors.$grey--light--4;

$chat--message--box-color: colors.$grey--light--2;
$chat--message--user--name-color: colors.$black--dark--3;
$message--bubble--max-width: 430px;

$chat--message--button--text-color: colors.$black--dark--3;
$chat--message--button--hover--text-color: colors.$black--dark--1;
$chat--message--user--image--border: 1px solid colors.$black--dark--2;
$chat--input--container--background-color: colors.$grey--light--3;
$chat--help--text-color: colors.$black--dark--3;
$chat--header--border: 1px solid colors.$grey--light--2;

$article-thumnbnail--extra--data--text-color: colors.$black--dark--3;

$copy-modal--boarder-color: colors.$black--dark--3;
$copy-modal--input-background: colors.$grey--light--5;

$voucher--input--background: colors.$grey--light--3;
$voucher--input--color: colors.$black--dark--2;

$ticket--icon--stroke-color: colors.$white;

$report--link-color: colors.$link--color;

$coming--soon--powered--by--text-color: colors.$white;
$coming--soon--powered--by--container--background: colors.$black--dark--1;

$info--section--text-color: colors.$white;
$info--section-background-dark: colors.$black--dark--1;
$info--section-background-red: colors.$solid-red;

$disabled--arrow--button--color: colors.$black--dark--1;
$hero--section--text-color: colors.$white;

$step--description--text--color: colors.$black--dark--1;
$step--number--border: 2.5px solid colors.$solid-red;
$step--number--border--mobile: 2px solid colors.$solid-red;
$step--number--color: colors.$solid-red;

$media--content--shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
$our--teams--section--background-color: colors.$grey--light--3;
$step--section--description--background: colors.$white;

$aside--media--section--background--primary: colors.$white;
$aside--media--section--background--secondary: colors.$grey--light--3;

$testimonials--section--background: colors.$white;

$footer--background--color: colors.$black--dark--1;
$footer--text--color: colors.$white;
$footer--sidebar--border: 5px solid;

$admin--link-color: colors.$link--color;

$tooltip--background-color: colors.$white;
$tooltip--text-color: colors.$black--dark--2;

$preloader--linear-gradient-themed: linear-gradient(
  to right,
  theming.$channel-theme--primary-background-color 3%,
  theming.$channel-theme--primary-color 18%,
  theming.$channel-theme--primary-background-color 33%
);
$all-sports-page-banner-background-color: colors.$grey--light--3;
$all-sport-section-background-color: colors.$white;

$field--section-background: colors.$grey--light--3;
$field-background: colors.$white;
$field-text-color: colors.$black--dark--2;
$field--dropdown--item-background: colors.$white;
$field--dropdown--item-color: colors.$black--dark--2;
$field--dropdown--item--disabled-color: colors.$black--dark--3;
$field--dropdown--item--hover-background: colors.$grey--light--2;
$field--dropdown--item--selected-background: colors.$grey--light--4;
$videos--no--content--text-color: colors.$black--dark--2;

$mobile--select--list--item-color: colors.$white;
$mobile--select--list--background: rgba(colors.$black--dark--2, 0.92);
$mobile--select--list--inner--background: rgba(colors.$black--dark--2, 0.8);
$mobile--select--list--close--container-background: linear-gradient(
  180deg,
  rgba(47, 49, 53, 0) 0%,
  rgba(47, 49, 53, 0.9) 60.17%
);

$channel--setting--content--container-background: colors.$grey--light--3;
$alternating--list--background-color-2: colors.$grey--light--2;
$alternating--list--background-color-1: colors.$grey--light--3;

$status--message--background: colors.$white;
$status--message--border: 1px solid colors.$grey--light--4;
$status--message--cross--icon--color: colors.$black--dark--2;

$progress--bar--background: colors.$grey--light--2;
$progress--bar--progress--background: colors.$solid-red;
$progress--bar--completed--background: colors.$active--green--1;
$progress--banner--close--icon--color: colors.$black--dark--3;
$progress--banner--close--icon--hover--color: colors.$black--dark--2;

$checklist--item--default--background: colors.$white;
$checklist--item--default--color: colors.$black--dark--2;

$checklist--item--active--background: colors.$black--dark--2;
$checklist--item--active--color: colors.$white;

$checklist--item--completed--background: colors.$grey--light--2;
$checklist--item--completed--color: colors.$black--dark--3;

$checklist--item--hover--background: colors.$grey--light--2;
$checklist--item--hover--color: colors.$black--dark--2;
$checklist--container--background: colors.$white;
$checklist--bottom--text: colors.$black--dark--3;

$inner--line--bottom--1: inset 0px -1px 0px colors.$grey--light--1;
$inner--line--bottom--2: inset 0px -1px 0px colors.$black--dark--3;

$inner--line--right: colors.$grey--light--1;

$action-panel-mix-color: rgba(lighten(colors.$black--dark--1, 20%), 0.05);

$info--banner--background: colors.$white;
$info--banner--hover-background: colors.$grey--light--2;
$info--banner-color: colors.$black--dark--2;

$banner--desktop--max-width: 1215px;

$team--list--team--name--width: 300px;
$team--list--team--name--width-mobile: 200px;

$team--list--ongoing--competitions--width: 180px;

$team--list--status--width: 100px;
$team--list--status--width-mobile: 80px;
$team--list--icon--width: 32px;

$list--item--background: colors.$white;
$list--item--background-hover: colors.$grey--light--2;
$list--header--item: colors.$black--dark--3;

$status--active--color: colors.$active--green--2;
$status--upcoming--color: colors.$black--dark--3;
$status--not--active--color: colors.$solid-red--dark--1;
$status--disabled--color: colors.$black--dark--3;
$searchbar-color: colors.$black--dark--3;

$filter--item--primary--background--color: colors.$grey--light--3;
$filter--item--primary--hover--background--color: colors.$grey--light--2;

$filter--item--secondary--background--color: colors.$white;
$filter--item--secondary--hover--background--color: colors.$grey--light--2;
