.LockIconContainer {
  position: relative;
  .LockIcon {
    position: relative;
    display: flex;
    align-items: center;
    &:hover {
      + .TooltipWrapper {
        display: block;
      }
    }
  }
  .TooltipWrapper {
    bottom: 50px;
    right: -14px;
    min-width: 300px;
    max-width: 321px;
    position: absolute;
    display: none;
  }
}
