@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.TextContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .Title {
    text-align: center;
    font-family: variables.$font--text--bold;
    font-size: 32px;
    font-style: normal;
    line-height: 110%;
    letter-spacing: -0.32px;
  }
  .SubText {
    text-align: center;
    font-family: variables.$font--text--light;
    font-size: 14px;
    font-style: normal;
    line-height: 22px;
    letter-spacing: -0.14px;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .TextContainer {
    .Title {
      font-size: 24px;
      line-height: 28px;
    }
  }
}
