@use '../../../../styles/variables.scss';

.DividerContainer {
  width: 100%;
  padding: 0 6px;
  .Divider {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.5px solid variables.$secondary--light-background;
    width: 100%;
    height: 0;
    position: rrelative;
    margin: 17.24px 0;
    position: relative;
    .TextContainer {
      position: absolute;
      background-color: variables.$content--default-background;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      padding: 10px;
      height: 35px;
      min-width: 35px;
      font-family: variables.$font--text--medium;
      font-size: 14px;
      line-height: 14px;
      color: variables.$secondary--disabled-background;
    }
  }
}
