@import '../../../../styles/variables.scss';
@import '../../../../styles/breakpoints.scss';

.TeamScoreContainer {
  box-sizing: border-box;
  font-size: 20px;
  line-height: 28px;
  font-family: $font--text--medium;
  width: 56px;
  .Score {
    display: flex;
    justify-content: flex-end;
    text-align: right;
  }
}

@media only screen and (max-width: $sm) {
  .TeamScoreContainer {
    width: unset;
    .Score {
      margin-left: 16px;
    }
  }
}

.Win {
  .Score {
    color: $game--schedule--win-color;
  }
}
.Loss {
  .Score {
    color: $game--schedule--lose-color;
  }
}
.Equal {
  .Score {
    color: $game--schedule--equal-color;
  }
}
.Default {
  .Score {
    color: $game--schedule--default-color;
  }
}
