@import '../../../../../../styles/variables.scss';
.MobileSelectListItem {
  font-family: $font--text--light;
  color: $mobile--select--list--item-color;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
}
.Selected {
  font-family: $font--text--bold;
  font-size: 20px;
  line-height: 28px;
  &:hover {
    cursor: pointer;
  }
}
