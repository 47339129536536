@use '../../../styles/breakpoints.scss';
@use '../../../styles/variables.scss';
.TeamSlider {
  background-color: variables.$default--light-background;
  .TeamsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 32px;
    padding: variables.$section--padding--top-bottom--desktop
      variables.$padding--left-right--desktop;
    .Team {
      position: relative;
      width: 64px;
      height: 64px;
      padding-right: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      &:hover {
        img {
          animation-name: animateImg;
          opacity: 0.2;
          animation-duration: 2s;
        }
        .DefaultTeamBadgeContainer {
          animation-name: animateImg;
          opacity: 0.2;
          animation-duration: 2s;
        }
        .TeamNameWrapper {
          display: flex;
          animation-name: animateTeamWrapper;
          animation-duration: 2s;
        }
      }
      .TeamNameWrapper {
        align-items: center;
        justify-content: center;
        margin: auto;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
        position: absolute;
        display: none;
        .TeamName {
          color: variables.$default--text-color;
          text-align: center;
          font-family: variables.$font--text--medium;
          color: #000;
          font-size: 12px;
          line-height: 12px;
        }
      }
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .TeamSlider {
    .TeamsWrapper {
      gap: 16px;
      padding: variables.$section--padding--top-bottom--tablet
        variables.$padding--left-right--desktop
        variables.$section--padding--top-bottom--tablet
        variables.$padding--left-right--tablet;
      .Team {
        height: 60px;
        width: 60px;
      }
    }
  }
}
@media only screen and (max-width: breakpoints.$mobile) {
  .TeamSlider {
    .TeamsWrapper {
      padding: variables.$section--padding--top-bottom--mobile
        variables.$padding--left-right--mobile;
    }
  }
}
@keyframes animateImg {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
@keyframes animateTeamWrapper {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
