@use '../../../../../styles/breakpoints.scss';

.Container {
  padding: 32px 120px;
}

@media only screen and (max-width: breakpoints.$sm) {
  .Container {
    padding: 24px 16px;
  }
}
