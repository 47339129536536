@use '../../../../styles/variables.scss';
@use '../../multiStageTournamentVariables.scss' as mst;

.Container {
  padding: 16px;
  border-radius: variables.$component-radius;
  width: fit-content;
  font-family: variables.$font--text--bold;
  font-size: 12px;
  font-style: normal;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.48px;

  text-transform: uppercase;
  &:hover {
    cursor: pointer;
  }
}
.Primary {
  background: mst.$filter--item--primary--background--color;
  border: 1px solid mst.$filter--item--primary--hover--background--color;
  &:hover {
    background: mst.$filter--item--primary--hover--background--color;
  }
}
.Secondary {
  background: mst.$filter--item--secondary--background--color;

  &:hover {
    background: mst.$filter--item--secondary--hover--background--color;
  }
}
