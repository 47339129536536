@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.Container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .Icon {
    height: 56px;
    width: 56px;
    svg {
      stroke: variables.$white !important;
    }
  }
  .TextContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .Title {
      color: variables.$white;
      font-family: variables.$font--text--bold;
      font-size: 32px;
      line-height: 110%;
      letter-spacing: -0.32px;
    }
    .Text {
      color: variables.$white;
      font-size: 20px;
      line-height: 28px;
    }
  }
  .ReadMoreButton {
    svg {
      stroke: unset !important;
      fill: variables.$white !important;
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .Container {
    .Icon {
      width: 32px;
      height: 32px;
    }
    .TextContainer {
      gap: 8px;
      .Title {
        font-size: 24px;
        line-height: 28px;
      }
      .Text {
        font-size: 16px;
        line-height: 24px; /* 150% */
      }
    }
  }
}

@media only screen and (max-width: breakpoints.$mobile) {
  .Container {
    .ReadMoreButton {
      width: 100% !important;
    }
  }
}
