@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.ProducedByContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: middle;
  font-family: 20px;
  line-height: 28px;
  color: variables.$white;
  .VideoCameraIcon {
    display: flex;
    align-items: center;
    padding-right: 16px;
    svg {
      path {
        stroke: variables.$white !important;
      }
    }
  }
  .Text {
    font-family: variables.$font--text--light;
  }
  .Producer {
    padding-left: 6px;
    font-family: variables.$font--text--bold;
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .ProducedByContainer {
    font-size: 14px;
    line-height: 18px;
    .VideoCameraIcon {
      padding-right: 8px;
    }
  }
}
