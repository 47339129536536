@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';
@use '../CustomContentVariables.scss' as ccv;

.Container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: ccv.$start--page--banner--content--background;
  border-radius: variables.$component-radius;

  .TopWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .ButtonContainer {
      display: flex;
      flex-direction: row;
      gap: 16px;
    }
    .Title {
      font-family: variables.$font--text--bold;
      font-size: 20px;
      line-height: 28px;
    }
  }
  .ItemOverview {
    display: flex;
    flex-direction: row;
    border-radius: variables.$component-radius;
    gap: 32px;
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .Container {
    .ItemOverview {
      flex-direction: column;

      .ButtonContainer {
        flex-direction: row;
        width: 100%;
        justify-content: flex-end;
      }
    }
    .BottomViewOnStartPageBtn {
      width: 100%;
    }
  }
}
