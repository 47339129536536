@use '../../../../styles/breakpoints.scss';
.PaymentInformation {
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
  .CardList {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  min-height: 75vh;
  .AddCreditCardButton {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .PaymentInformation {
    padding: 24px 16px;
  }
}
