@use '../../styles/variables.scss';
.Shield {
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  .Text {
    color: variables.$default--text-color;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: variables.$font--text--bold;
    font-size: 1em;
    text-transform: uppercase;
    font-style: normal;
    word-wrap: break-word;
  }
}
.large {
  height: 80px;
  width: 80px;
  .Text {
    font-size: 20px;
  }
}
.medium {
  height: 64px;
  width: 65px;
  .Text {
    font-size: 16px;
  }
}
.small {
  height: 40px;
  width: 40px;
  .Text {
    font-size: 13px;
  }
}
