@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.ChannelName {
  display: flex;
  flex-direction: row;
  position: static;
  padding-left: variables.$padding--left-right--desktop;
  padding-right: variables.$padding--left-right--desktop;

  h1 {
    font-family: variables.$font--text--bold;
    margin-bottom: 28px !important;
    margin-top: 42px !important;
    -webkit-line-clamp: 1;
    font-size: 64px !important;
    font-style: normal;
    line-height: 77px !important;
    letter-spacing: -0.01em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.ChannelHeaderText {
  font-family: variables.$channel-theme--heading-font-family !important;
  font-weight: variables.$channel-theme--heading-font-weight !important;
}

.NotThemed {
  text-transform: none;
}
.Spacer {
  position: static;
}

.ChannelNameNoImage {
  z-index: 0;
  position: relative;
  padding-left: variables.$padding--left-right--desktop;
  padding-right: variables.$padding--left-right--desktop + 180px;
  top: -75px;
  h1 {
    margin-bottom: 28px !important;
    margin-top: 42px !important;
    font-family: variables.$font--text--bold;
    font-size: 64px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 77px !important;
    margin-bottom: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media screen and (max-width: breakpoints.$sm) {
  .ChannelNameNoImage {
    top: -32px;
    padding-right: 160px;
    h1 {
      margin-top: 28px !important;
      margin-bottom: 16px !important;
      font-size: 24px !important;
      line-height: 28px !important;
    }
    padding-left: 32px !important;
  }
  .ChannelName {
    h1 {
      margin-top: 28px !important;
      margin-bottom: 16px !important;
      font-size: 24px !important;
      line-height: 28px !important;
    }
    padding-left: 32px !important;
  }
}
@media screen and (max-width: breakpoints.$mobile) {
  .ChannelName {
    padding-left: variables.$padding--left-right--mobile !important;
    padding-right: variables.$padding--left-right--mobile;
  }
  .ChannelNameNoImage {
    padding-left: variables.$padding--left-right--mobile !important;
  }
}
