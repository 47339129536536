@use '../../../styles/variables.scss';

.DateFieldContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  label {
    padding-bottom: 0 !important;
    font-family: variables.$font--text--medium !important;
    font-size: 16px;
    line-height: 20px;
  }
  .DateField {
    border: none;
    width: 100%;
    border-radius: variables.$component-radius;
    font-family: variables.$font--text--light;
    height: 48px;
    font-size: 16px;
    padding: 16px 24px;
    background-color: variables.$form--text--area--background-color;
  }
  .Error {
    border: 1px variables.$form--error--text-color solid;
  }
}
