@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.StreamCountdown {
  display: flex;
  flex-direction: row;
  gap: 16px;
  color: variables.$ghost--disabled-background;
  text-align: center;
  font-family: variables.$font--text--bold;
  font-size: 120px;
  font-style: normal;
  line-height: 120%;
  letter-spacing: -0.64px;
  .CountdownItem {
    display: flex;
    flex-direction: column;
    width: 150px;
    .Text {
      font-size: 32px;
      line-height: 130%;
    }
  }
  .OnlyDaysText {
    font-size: 100px;
    line-height: 120%;
  }
}

@media only screen and (max-width: breakpoints.$md) {
  .StreamCountdown {
    font-size: 100px;
    .CountdownItem {
      width: 120px;
    }
    .OnlyDaysText {
      font-size: 80px;
    }
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .StreamCountdown {
    font-size: 10vw;
    letter-spacing: -0.4px;
    .CountdownItem {
      width: 12vw;
      .Text {
        font-size: 5vw;
        line-height: 28px;
      }
    }
    .OnlyDaysText {
      font-size: 10vw;
    }
  }
}
