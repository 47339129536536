@use '../../../../../styles/variables.scss';
@use '../../../../../styles/breakpoints.scss';

.Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  color: variables.$default--text-color;
  .Number {
    font-family: variables.$font--text--light;
    font-size: 20px;
    line-height: 28px;
    width: 35px;
  }
  .Name {
    text-transform: capitalize;
    font-family: variables.$font--text--medium;
    font-size: 20px;
    line-height: 28px;
  }
  .LabelContainer {
    display: inline-flex;
    padding: 6px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 2px;
    color: variables.$default--text-color;
    font-family: variables.$font--text--medium;
    font-size: 16px;
    line-height: 16px;
  }
  .IsCaptain {
    background: variables.$ghost--inverted--disabled-background;
  }
  .isHighlightedPosition {
    background: variables.$ghost--disabled-background;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .Container {
    gap: 6px;
    .Number,
    .Name {
      font-size: 16px;
      line-height: 24px;
    }
    .LabelContainer {
      font-size: 12px;
      line-height: 12px;
    }
  }
}

.IsViewingStream {
  gap: 6px;
  .Number,
  .Name {
    font-size: 16px;
    line-height: 24px;
  }
  .LabelContainer {
    font-size: 12px;
    line-height: 12px;
  }
}
