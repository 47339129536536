@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.TallImageSliderPreloader {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: $padding--left-right--desktop;
  .Wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    .ObjectPreloader {
      padding-right: 16px;
      width: 390px;
      height: 522px;
      display: flex;
      flex-direction: column;
      .LoaderImage {
        display: block;
        width: 390px;
        height: 522px;
      }
    }
  }
}

@keyframes animate {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.LoaderImage {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: animate;
  animation-timing-function: linear;
  background: #f6f6f6;
  position: relative;
  display: flex;
  vertical-align: center;
  background: $preloader--linear-gradient;
  background-size: 1000px 100%;
}

@media only screen and (max-width: $sm) {
  .TallImageSliderPreloader {
    padding-left: $padding--left-right--tablet;
    .Wrapper {
      .ObjectPreloader {
        width: 240px;
        height: 360px;
        .LoaderImage {
          display: block;
          width: 240px;
          height: 390px;
        }
      }
      .BottomContainer {
        padding-right: 0;
        .LoaderTitle {
          width: 100%;
        }
        .LoaderTime {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile) {
  .TallImageSliderPreloader {
    padding-left: $padding--left-right--mobile;
  }
}
