@import '../../../../styles/variables.scss';
@import '../../../../styles/breakpoints.scss';

.NewsDateContainer {
  .LightSubText {
    color: #93979f;
    font-weight: 400;

    font-size: 14px;
    line-height: 22px;
  }
}

@media only screen and (max-width: $sm) {
  .NewsDateContainer {
    .LightSubText {
      font-size: 12px;
      line-height: 12px;
    }
  }
}
