@use '../../../../../../styles/variables.scss';
@use '../../../../../../styles/breakpoints.scss';

.Container {
  display: flex;
  background-color: variables.$team--logo--background-color;
  border-radius: 50%;
  padding: 48px;
  justify-content: center;
  align-items: center;
  height: fit-content;
  img {
    object-fit: contain;
    max-width: 160px;
    max-height: 160px;
    width: 160px;
    height: 160px;
  }
}

@media only screen and (max-width: breakpoints.$mobile) {
  .Container {
    img {
      max-width: 160px;
      max-height: 160px;
      width: 160px;
      height: 160px;
    }
  }
}
