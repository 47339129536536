@use '../../../styles/variables.scss';

.AddGamesFormContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .FormInformation {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: variables.$field-text-color;
  }
  .LockIcon {
    svg {
      width: 18px;
      min-width: 18px;
    }
  }
}
