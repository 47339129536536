@use '../../../styles/breakpoints.scss';

.GameSchedulesContainer {
  display: flex;
  flex-direction: column;
  padding: 48px 0;
  gap: 48px;
}
@media only screen and (max-width: breakpoints.$sm) {
  .GameSchedulesContainer {
    padding: 32px 0;
  }
}
