@use '../../../../../styles/variables.scss';

.CompanyInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.572px;
  text-decoration: none;
  .CompanyText {
    line-height: 8.781px;
    font-size: 8.781px;
    color: variables.$channel-theme--primary-color-inverted;
  }

  .CompanyLogoContainer {
    line-height: 1;
    svg {
      height: 13px;
      width: 73px;
    }
  }
  .CompanyLogoContainer svg path {
    fill: variables.$channel-theme--primary-color-inverted !important;
  }
  &:hover {
    text-decoration: none;
  }
}
