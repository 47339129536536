@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.AccessContainer {
  overflow: hidden;
  border-radius: variables.$component-radius;
  background-color: variables.$content--default-background;
  .Header {
    background-color: variables.$default--dark-background;
    height: 250px;
    width: 100%;
    position: relative;
    border-radius: variables.$component-radius;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .HeaderText {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: variables.$primary--default-color;
    }
  }
  .Content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    padding: 40px 110px 48px 110px;
    .Description {
      white-space: pre-wrap;
    }
    .FormContainer {
      width: 100%;
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .AccessContainer {
    .Header {
      height: 150px;
    }
    .Content {
      padding: 32px 16px 48px 16px;
      gap: 16px;
    }
  }
}
