@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.SponsorSectionPreloader {
  width: 100%;
  max-height: 123px;
  display: flex;
  flex-direction: column;
  .LoaderImage {
    display: block;
    width: 100%;
    height: 522px;
  }
}

@keyframes animate {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.LoaderImage {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: animate;
  animation-timing-function: linear;
  background: #f6f6f6;
  position: relative;
  display: flex;
  vertical-align: center;
  background: $preloader--linear-gradient-2;
  background-size: 1000px 100%;
}

@media only screen and (max-width: $sm) {
  .SponsorSectionPreloader {
    max-height: 108px;
    .LoaderImage {
      display: block;
      width: 100%;
      height: 390px;
    }
  }
}
