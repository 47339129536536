@use '../../styles/variables.scss';
@use '../../styles/colors.scss';
@use '../../styles/breakpoints.scss';

.PageLinkContainer {
  display: flex;
  justify-content: center;
  padding: 10px;
  border-radius: variables.$component-radius;
  width: 38px;
  height: 38px;
  cursor: pointer;
  .PageNumber {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
}
.CurrentPage {
  background-color: colors.$black--dark--2;
  color: colors.$white;
  box-shadow: variables.$elevation-2;
}

@media only screen and (max-width: breakpoints.$sm) {
  .PageLinkContainer {
    width: 32px;
    height: 32px;
    padding: 8px;
    .PageNumber {
      font-size: 14px;
    }
  }
}
