@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.RelativeContentSliderParent {
  position: relative;
  overflow-y: hidden;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  height: 100%;
  &.LightBackground {
    background: $white;
  }
  .arrowContainer {
    display: none;
    position: absolute;
    align-self: center;
    z-index: 11;
    height: 100%;
    padding: 10px;
    align-content: center;
    button {
      box-shadow: $elevation-1;
    }
  }
  &:hover {
    .arrowContainer {
      display: block;
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
}
.arrowLeft {
  left: $padding--content--slider--arrow--left-right;
}
.arrowRight {
  right: 32px;
}
.ListContainer {
  box-sizing: border-box;
  display: flex;
  overflow-x: scroll;
  width: 100%;
  padding-right: 1px;
  height: 100%;
  a {
    padding-right: 16px;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.LeftPadding {
  padding-left: $padding--left-right--desktop;
}
@media only screen and (max-width: $sm) {
  .LeftPadding {
    padding-left: $padding--left-right--tablet;
  }
}

@media only screen and (max-width: $mobile) {
  .RelativeContentSliderParent {
    &:hover {
      .arrowContainer {
        display: none;
      }
    }
  }
  .ListContainer {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .LeftPadding {
    padding-left: $padding--left-right--mobile;
  }
}
