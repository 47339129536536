@use '../../../styles/variables.scss';
@use '../../../styles/colors.scss';

.ArrowContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1000px;
  background-color: colors.$grey--light--2;
  height: 36px;
  width: 36px;
  min-width: 36px;
  min-height: 36px;
  &:hover {
    cursor: pointer;
    background-color: colors.$grey--light--3;
  }
}
.Disabled {
  pointer-events: none;
  opacity: 0.4;
}

.ArrowLightBack {
  background-color: colors.$grey--light--2;
  &:hover {
    cursor: pointer;
    background-color: colors.$grey--light--3;
  }
}
.ArrowLightForeground {
  svg > path {
    stroke: colors.$white;
  }
}

.ArrowDarkBack {
  background-color: colors.$black--dark--1 !important;
  &:hover {
    cursor: pointer;
    background-color: colors.$grey--light--2;
  }
}
.ArrowDarkForeground {
  svg > path {
    stroke: colors.$black--dark--1;
  }
}
