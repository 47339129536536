@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.FormContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .FormFields {
    display: flex;
    flex-direction: column;
  }
  .PasswordFields {
    gap: 12px;
  }
  .ButtonContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .BackButton {
      width: 100%;
    }
  }
  .SubmitButton {
    width: 100%;
    margin-top: 0;
  }
}
.CheckboxContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 16px;
  .PrivacyPolicyTextContainer {
    a {
      color: variables.$default--text-color;
      font-family: variables.$font--text--medium;
    }
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .FormContainer {
    .FormFields {
      gap: 4px;
    }
    .PasswordFields {
      gap: 16px;
    }
    .PhoneNumberField {
      gap: 16px;
    }
  }
}
