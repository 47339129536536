@use '../../../../styles/variables.scss';
@use '../../../../styles/colors.scss';

.GameScheduleRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: variables.$font--text--medium;
  .NameContainer {
    display: flex;
    flex-direction: column;
    gap: 0;
    .Name {
      font-family: variables.$font--text--semi--bold;
      min-width: 200px;
      width: 300px;
    }
    .ConnectedAs {
      font-family: variables.$font--text--medium;
      color: colors.$black--dark--3;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.14px;
    }
  }
  .StartDate {
    color: colors.$black--dark--3;
    min-width: 130px;
  }
  .EndDate {
    color: colors.$black--dark--3;
    min-width: 130px;
  }
  .Status {
    min-width: 100px;
  }
  .TotalGames {
    width: 100px;
  }
}
.Header {
  padding: 20px 51px;
  font-weight: 500;
  color: colors.$black--dark--3;
  font-family: variables.$font--text--medium;
  font-size: 16px;
  line-height: 24px;
}
.Item {
  padding: 24px 51px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: variables.$game--schedule--box-shadow;
}
.RemoveButton {
  cursor: pointer;
  width: 48px;
}

.StatusActive {
  color: variables.$active--default-background;
}
.StatusUpcoming {
  color: variables.$secondary--disabled-background;
}
