@use '../../../../../../../styles/variables.scss';

.LoadingUploadContainer {
  position: relative;
  width: 184px;
  height: 184px;
}

.LoadingUploadBackdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  border-radius: variables.$component-radius;
}

.LoadingUploadOverlay {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  background: rgba(245, 245, 245, 0.7);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: variables.$component-radius;
  opacity: 1;
}
