@use '../../../../../styles/variables.scss';
.DeleteAccountSection {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .TextContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .SubText {
      font-family: variables.$font--text--light;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
