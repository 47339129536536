@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.ContentSliderWrapper {
  padding: $section--padding--top-bottom--desktop 0;

  & > div {
    background: $content--default-background;
  }
}

.Content {
  display: flex;
  flex-direction: row;
  padding: 32px 0;

  a {
    padding-right: 40px;
  }

  @media screen and (max-width: $sm) {
    padding: 24px 0;

    a {
      padding-right: 24px;
    }
  }
}
