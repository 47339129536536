@use '../../../../../styles/variables';
.Wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .Container {
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    width: 100%;
    .ToggleContainer {
      background-color: variables.$content--default-background;
    }
    .InnerContainer {
      display: flex;
      flex-direction: row;
      gap: 32px;
      width: 100%;
    }
  }
}

.IsViewingStream {
  padding: 24px 16px;
  .Container {
    gap: 24px;
    .InnerContainer {
      gap: 24px;
    }
  }
}
