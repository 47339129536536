@use '../../styles/colors.scss';
@use '../../styles/variables.scss';

.DragZoneContainer {
  display: flex;
  flex-direction: column;

  .DragZone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    height: 180px;
    border: variables.$dragzone-border;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 16px 24px;
    gap: 8px;

    .UploadVideo {
      font-family: variables.$font--text--bold;
      font-size: 14px;
      line-height: 22px;
      color: variables.$default--text-color;
    }

    .SecondText {
      font-size: 14px;
      line-height: 22px;
      color: colors.$black--dark--3;
    }

    .UnderlinedText {
      color: colors.$black--dark--1;
      text-decoration: underline;
    }
    .RecommededSize {
      font-size: 14px;
      line-height: 22px;
      color: colors.$black--dark--3;
    }
  }

  .IsDragging {
    background-color: variables.$secondary--light-background;
  }

  .ErrorText {
    color: variables.$form--error--text-color;
    padding-left: 15px;
  }
}
