@use '../../../../../styles/variables.scss';
@use '../../../../../styles/colors.scss';

.VoucherContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  h5 {
    margin: 0;
  }
  .GoBackContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-family: variables.$font--text--medium;
    line-height: 14px;
    margin-bottom: 64px;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .VoucherInputSection {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .InputTitle {
      font-family: variables.$font--text--medium;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.01em;
    }
    .FormContainer {
      display: flex;
      gap: 16px;
      flex-direction: column;
      .InputField {
        margin-top: 12px;
        padding: 16px 24px;
        border: none;
        background: variables.$voucher--input--background;
        color: variables.$voucher--input--color;
        border-radius: 2px;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: -0.01em;
      }
      .InputError {
        font-size: 12px;
        line-height: 12px;
        color: colors.$solid-red;
      }
      .SubmitButton {
        font-size: 14px;
        line-height: 14px;
        border: none;
        border-radius: variables.$button-border-radius;
        background: variables.$secondary--default-background;
        color: variables.$secondary--default-color;
        padding: 17px 16px;
        &:hover {
          cursor: pointer;
          background: variables.$secondary--hover-background;
        }

        &:disabled {
          background: variables.$secondary--disabled-background;
        }
      }
    }
  }
}
