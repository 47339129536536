@import '../../styles/variables.scss';

.InputSearchContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  .Label {
    font-family: $font--text--medium;
    font-size: 16px;
    line-height: 20px;
  }
  .InputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    gap: 10px;
    height: 48px;
    background: $searchfield--default-background;
    border-radius: $component-radius;
    &:hover {
      background-color: $searchfield--default--hover-background;
    }
    .SearchInput {
      width: 100%;
      border: none;
      font-family: $font--text--light;
      font-size: 16px;
      text-overflow: ellipsis;
      letter-spacing: -0.01em;
      padding: unset;
      overflow: visible;
      background: none;
      &::placeholder {
        color: $searchfield--default--placeholder-color;
      }
      &:focus-visible {
        outline-style: none;
      }
    }
    .SearchIconButton {
      background: none;
      border: none;
      height: 16px;
      padding: unset;
      &:hover {
        cursor: pointer;
      }
      svg {
        stroke: $searchfield--default--active--text-color;
      }
      &:disabled {
        background: none;
        padding: none;
        border: none;
        &:hover {
          cursor: default;
        }
        svg {
          stroke: $searchfield--default--placeholder-color;
        }
      }
    }
  }
  .HasError {
    border: $form--error--border;
  }
  .ErrorMessage {
    color: $form--error--text-color;
    font-size: 10px;
    line-height: 10px;
  }
}
