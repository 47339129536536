.BigPlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  z-index: 100;
  .BigPlayIcon {
    color: #fff;
    font-size: 6rem;
    -webkit-filter: drop-shadow(1px 1px 14px rgba(0, 0, 0, 0.5));
    filter: drop-shadow(1px 1px 14px rgba(0, 0, 0, 0.5));
  }
}
