@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.FollowButton {
  position: absolute;
  right: variables.$padding--left-right--desktop;
  height: 48px;

  .FilledHeartIcon {
    display: flex;
    align-items: center;
    svg {
      stroke: variables.$default--text-color !important;
      fill: variables.$default--text-color !important;
    }
  }
  .ThemedFilledHeart {
    display: flex;
    align-items: center;
    svg {
      stroke: variables.$channel-theme--primary-background-color !important;
      fill: variables.$channel-theme--primary-color !important;
    }
  }
  .ThemedFilledHeartOnHover {
    svg {
      stroke: variables.$channel-theme--primary-background-color !important;
      fill: variables.$channel-theme--primary-background-color !important;
    }
  }
}
@media screen and (max-width: breakpoints.$sm) {
  .FollowButton {
    height: 28px !important;
    right: 92px !important;
  }
}
