@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.CompetitionsSelectorContainer {
  padding: 0 variables.$padding--left-right--desktop 56px
    variables.$padding--left-right--desktop;

  .HeaderContainer {
    padding-bottom: 40px;
  }
  .PaginationContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 40px;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .CompetitionsSelectorContainer {
    padding: 0 variables.$padding--left-right--tablet 56px
      variables.$padding--left-right--tablet;
    .HeaderContainer {
      padding-bottom: 32px;
    }
    .PaginationContainer {
      padding-top: 40px;
    }
  }
}

@media only screen and (max-width: breakpoints.$mobile) {
  .CompetitionsSelectorContainer {
    padding: 0 variables.$padding--left-right--mobile 48px
      variables.$padding--left-right--mobile;
  }
}
