@use '../../../../components//video//VideoPlayerControls/VideoPlayerControls.scss'
  as vars;

.OverlayButton {
  z-index: 99;
}

.LargeOverlayIcon {
  font-size: 6rem !important;
}

.MediumOverlayIcon {
  font-size: 4rem !important;
}

.OverlayIcon {
  color: vars.$icon-color;

  -webkit-filter: drop-shadow(1px 1px 14px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(1px 1px 14px rgba(0, 0, 0, 0.5));

  &:hover {
    color: vars.$icon-hover-color;
  }
}
