.UploadFontContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  gap: 32px;

  .UploadFontIdentifierText {
    width: 448px;
  }
}
