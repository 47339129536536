@use '../../styles/breakpoints.scss';

.Container {
  padding: 48px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .TopContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .ButtonContainer {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
  .MenuLinks {
    display: flex;
    flex-direction: column;
  }
  .BottomContainer {
    display: flex;
    flex-direction: column;
    margin-left: -32px;
    gap: 24px;
  }
  .HelpText {
    padding: 0 32px;
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .Container {
    padding: unset;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    .SecondDivider {
      display: none;
    }
    .TopContainer {
      .UserSection {
        padding: 16px 32px;
      }
      .ButtonContainer {
        padding: 0 32px;
      }
    }
    .BottomContainer {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-left: 0;
    }
  }
}
