@use '../../../styles/variables.scss';
.Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 32px;
  gap: 16px;
  color: variables.$default--text-color;
  font-family: variables.$font--text--bold;
  font-size: 16px;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0.08px;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
