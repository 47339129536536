@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.LinkThumbnail {
  width: 603px;
  height: 340px;
  border-radius: variables.$component-radius;
  position: relative;
  overflow: hidden;
  padding-right: 0 !important;
  & > img {
    object-position: center center;
    object-fit: cover;
    width: 100%;

    overflow: hidden;
    height: 100%;
  }

  &:hover {
    cursor: pointer;
    & > img {
      transform: scale(1.2);
      transition: 1s;
    }
    .GradientContainer {
      background: variables.$link--thumbnail--linear-gradient-hover;
    }
  }
  .GradientContainer {
    position: absolute;
    inset: 0;
    background: variables.$link--thumbnail--linear-gradient;
    border-radius: variables.$component-radius;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 40px;
    height: 100%;
    width: 100%;
    overflow: hidden;

    .BottomContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .Label {
        margin: 0;
        color: variables.$link--thumbnail--label-color;
        text-transform: none;
      }
    }
  }
}

@media screen and (max-width: breakpoints.$sm) {
  .LinkThumbnail {
    width: 343px;
    height: 193px;
    .GradientContainer {
      padding: 12px 16px;
      .BottomContainer {
        .Label {
          font-size: 16px;
          line-height: 24px;
        }
        .Button {
          display: none;
        }
      }
    }
  }
}
