@use '../../styles/variables.scss';
.ColorPickerContainer {
  display: flex;
  flex-direction: column;
  width: 222px;
  border-radius: variables.$component-radius;
  overflow: hidden;
  .ColorInput {
    width: 100%;
    height: 154px;
    border: none;
    padding-block: 0px;
    padding-inline: 0px;
    padding: 4px 4px 2px 4px;
    background-color: #eaeaea;
    inline-size: 100%;
    border-radius: 2px;
    &:hover:enabled {
      cursor: pointer;
    }
    &::-webkit-color-swatch {
      border: none;
      border-radius: variables.$component-radius;
    }
  }

  .FieldContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 16px;
    background-color: variables.$grey--light--2;
    .Label {
      font-family: variables.$font--text--bold;
      font-size: 12px;
      font-style: normal;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.48px;
      text-transform: uppercase;
    }
    .ColorValueInput {
      border: none;
      border-radius: variables.$component-radius;
      font-size: 16px;
      padding: 16px 24px;
      background-color: variables.$form--text--area--background-color;
      &:disabled {
        color: variables.$form--text--area-color;
      }
    }
  }
}
