@use '../../../../../styles/variables.scss';
@use '../../../../../styles/colors.scss';

.ItemContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 0px 16px;
  font-size: 16px;
  font-family: variables.$font--text--medium;
  line-height: 24px;
  .ItemTitle {
    color: colors.$black--dark--2;
  }
  .Item {
    color: colors.$black--dark--3;
  }
}
