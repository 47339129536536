@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.InfoBoxFormFieldsContainer {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 32px;
  width: 100%;

  .TextInput div > input {
    height: 48px;
    padding: 0;
    padding-left: 16px;
  }
  .FormItem {
    clear: both;
    padding-bottom: 16px;

    label {
      font-family: variables.$font--text--medium !important;
      font-size: 16px;
      line-height: 20px;
    }

    .TextEditorContainer {
      padding-top: 12px;
      height: 150px;
      max-height: 150px;
      overflow: hidden;
    }
  }
  .LeftSide {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  .RightSide {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
}

.TextInput {
  width: 100%;

  input {
    height: 48px;
    padding: 0;
    padding-left: 16px;
  }
}

@media only screen and (max-width: breakpoints.$md) {
  .InfoBoxFormFieldsContainer {
    flex-direction: column;
    justify-content: flex-start;
    .LeftSide {
      width: 100%;
    }
    .RightSide {
      width: 100%;
    }
    .TextEditorContainer {
      display: block;
      padding-top: 16px;
      max-height: 200px;
    }
  }
}
