@use '../../../../../styles/colors.scss';
@use '../../../../../styles/variables.scss';

.GameThumbNail {
  border-radius: variables.$component-radius;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: variables.$team--vs--team--left--background-color;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .ThumbnailOverlay {
    position: absolute;
    top: 0;
    right: 0;
    background: colors.$black--dark--1;
    left: 0;
    bottom: 0;
    opacity: 0;
    z-index: 10;
  }
  .RightBackground {
    position: absolute;
    top: -5%;
    height: 120%;
    width: 57%;
    right: -7%;
    background: variables.$team--vs--team--right--background-color;
    mix-blend-mode: normal;
    transform: rotate(-170deg);
  }
  .TeamsWrapper {
    display: flex;
    position: relative;

    justify-content: space-between;
    align-items: center;
    .LeftContainer {
      background-color: variables.$team--logo--background-color;
      border-radius: 50%;
    }
    .VsBox {
      display: flex;
      justify-content: center;
      align-items: center;
      color: variables.$vs--box--text-color;
      background: variables.$vs--box--background-color;
      border-radius: variables.$component-radius;
      font-family: variables.$font--text--bold;
    }
    .LeftContainer,
    .RightContainer {
      display: flex;
      justify-content: center;
      box-sizing: border-box;

      background-color: variables.$team--logo--background-color;
      border-radius: 50%;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
  .ThumbnailLabelContainer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    border-radius: variables.$component-radius;
  }
  .TimeLabelContainer {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 10;
    border-radius: variables.$component-radius;
  }
  .RootDomainLabelContainer {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 10;
  }
}
.WithHoverEffect {
  &:hover {
    .ThumbnailOverlay {
      opacity: 0.42 !important;
      transition: variables.$hover--transition-time;
    }
  }
}
.xxSmall {
  height: 109px;
  padding: 0 32px;
  .TeamsWrapper {
    width: 240px;
    .VsBox {
      width: 36px;
      height: 36px;
      font-size: 16px;
      line-height: 20px;
    }
    .LeftContainer,
    .RightContainer {
      height: 82px;
      width: 82px;
      padding: 20px;
    }
  }
}
.xSmall {
  height: 160px;
  .TeamsWrapper {
    width: 248px;
    .VsBox {
      width: 36px;
      height: 36px;
      font-size: 16px;
      line-height: 20px;
    }
    .LeftContainer,
    .RightContainer {
      height: 82px;
      width: 82px;
      padding: 20px;
    }
  }
}
.small {
  height: 179px;
  .TeamsWrapper {
    width: 248px;
    .VsBox {
      width: 36px;
      height: 36px;
      font-size: 16px;
      line-height: 20px;
    }
    .LeftContainer,
    .RightContainer {
      height: 82px;
      width: 82px;
      padding: 20px;
    }
  }
}
.medium {
  height: 220px;
  .TeamsWrapper {
    width: 300px;
    .VsBox {
      width: 48px;
      height: 48px;
      font-size: 20px;
      line-height: 28px;
    }
    .LeftContainer,
    .RightContainer {
      height: 94px;
      width: 94px;
      padding: 20px;
    }
  }
}
.large {
  height: 278px;
  .TeamsWrapper {
    width: 392px;
    .VsBox {
      width: 56px;
      height: 56px;
      font-size: 24px;
      line-height: 26.4px;
    }
    .LeftContainer,
    .RightContainer {
      height: 128px;
      width: 128px;
      padding: 24px;
    }
  }
}
.xLarge {
  height: 565px;
  .TeamsWrapper {
    width: 712px;
    .VsBox {
      width: 72px;
      height: 72px;
      font-size: 40px;
      line-height: 44px;
    }
    .LeftContainer,
    .RightContainer {
      height: 256px;
      width: 256px;
      padding: 48px;
    }
  }
}
.keepRatio {
  &.GameThumbNail {
    position: absolute;
    width: 100%;
    .TeamsWrapper {
      width: 80%;
      .VsBox {
        width: 75px;
        height: 75px;
        font-size: 40px;
        line-height: 40px;
      }
      .LeftContainer,
      .RightContainer {
        height: 200px;
        width: 200px;
        padding: 38px;
      }
    }
  }
}
@media only screen and (max-width: 620px) {
  .keepRatio {
    &.GameThumbNail {
      .TeamsWrapper {
        .VsBox {
          width: 60px;
          height: 60px;
          font-size: 25px;
          line-height: 25px;
        }
        .LeftContainer,
        .RightContainer {
          height: 150px;
          width: 150px;
          padding: 28px;
        }
      }
    }
  }
}
@media only screen and (max-width: 540px) {
  .keepRatio {
    &.GameThumbNail {
      .TeamsWrapper {
        .VsBox {
          width: 50px;
          height: 50px;
          font-size: 20px;
          line-height: 20px;
        }
        .LeftContainer,
        .RightContainer {
          height: 100px;
          width: 100px;
          padding: 18px;
        }
      }
    }
  }
}
@media only screen and (max-width: 400px) {
  .keepRatio {
    .LeftContainer,
    .RightContainer {
      height: 80px !important;
      width: 80px !important;
      padding: 15px;
    }
  }
}
