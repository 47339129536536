@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.Comments {
  display: flex;
  flex-direction: column;

  .CommentsContainer {
    padding: 10px variables.$padding--left-right--desktop 64px;
    height: 770px;
    overflow-y: auto;
    background: variables.$chat--messages--container--background-color;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .LoaderContainer {
      padding-top: 24px;
    }
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .Comments {
    height: 100%;
    .CommentsContainer {
      height: 500px;
      padding: 10px variables.$padding--left-right--tablet 32px;
    }
  }
}

@media only screen and (max-width: breakpoints.$mobile) {
  .Comments {
    .CommentsContainer {
      padding: 10px variables.$padding--left-right--mobile 32px;
    }
  }
}

.IsViewingStream {
  height: 100%;
  position: relative;
  padding: 0;
  .LoaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-top: 0;
  }
  .CommentsInput {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
  }
  .CommentsContainer {
    padding: 16px !important;
    height: calc(100% - 98.5px);
    padding-bottom: 70;
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .IsViewingStream {
    position: unset;
    .CommentsContainer {
      .LoaderContainer {
        padding-top: 10px;
      }
    }
    .CommentsInput {
      position: fixed;
    }
  }
}
