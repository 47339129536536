@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.AccountInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 58px;
  height: 100%;
  .TopContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 286px;
  }
  .PrivacyPolicySection {
    max-width: 366px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: variables.$default--text-color;
    font-family: variables.$font--text--light;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    .TextContainer {
      a {
        color: variables.$report--link-color;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .AccountInfoContainer {
    gap: 16px;
    width: 100%;
    height: unset;
    .TopContainer {
      width: fit-content;
      gap: 32px;
      .AccountLogo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .MobileTitle {
          text-align: center;
          font-family: variables.$font--text--bold;
          font-size: 24px;
          line-height: 28px;
          letter-spacing: -0.32px;
        }
      }
    }
    .PrivacyPolicySection {
      gap: 8px;
      max-width: 300px;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.12px;
    }
  }
}
