@use '../../../../styles/breakpoints.scss';

.PaymentContainer {
  display: flex;
  align-items: center;
  justify-self: center;
}

@media only screen and (max-width: breakpoints.$sm) {
  .PaymentContainer {
    width: breakpoints.$sm;
  }
}
