@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.ModalContent {
  display: flex;
  flex-direction: row;
  background: variables.$modal--default--background-color;
  color: variables.$modal--default--color;
  gap: 0;
  height: 694px;
  max-height: 90vh;
  .FirstContainerContent {
    padding: 0 87px 0 79px;
    width: 532px;
    overflow-x: hidden;
    overflow: auto;
    &.primary {
      background: variables.$modal--default--background-color;
    }
    &.secondary {
      background: variables.$modal--secondary--background-color;
    }
  }

  .SecondContainerContent {
    padding: 64px 79px 64px 87px;
    width: 532px;
    overflow-x: hidden;
    overflow: auto;
    &.primary {
      background: variables.$modal--default--background-color;
    }
    &.secondary {
      background: variables.$modal--secondary--background-color;
    }
  }
}
@media screen and (max-width: breakpoints.$sm) {
  .ModalContent {
    flex-direction: column;
    padding: 103px 48px;
    height: 100%;
    max-height: 100%;
    gap: 16px;
    overflow: auto;
    .FirstContainerContent,
    .SecondContainerContent {
      background-color: variables.$modal--default--background-color !important;
      width: 100%;
      height: unset;
      padding: 0;
      overflow: unset;
    }
  }
}
