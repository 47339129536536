@import '../../../../../styles/variables.scss';
@import '../../../../../styles/breakpoints.scss';

.ChecklistItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px 51px;
  gap: 16px;
  box-shadow: $inner--line--bottom--1;
  border-radius: $component-radius;
  .TextContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .Title {
      margin: 0;
    }
    .SubTitle {
      font-family: $font--text--light;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .Icon {
    width: 32px;
    height: 32px;
  }
  &:hover {
    cursor: pointer;
    background-color: $checklist--item--hover--background;
    color: $checklist--item--hover--color;
    svg {
      stroke: $checklist--item--default--color !important;
    }
  }
}

.Default {
  background-color: $checklist--item--default--background;
  color: $checklist--item--default--color;
  svg {
    stroke: $checklist--item--default--color !important;
  }
}
.Active {
  background-color: $checklist--item--active--background;
  color: $checklist--item--active--color;
  svg {
    stroke: $checklist--item--active--color !important;
  }
}
.Completed {
  background-color: $checklist--item--completed--background;
  color: $checklist--item--completed--color;
  svg {
    stroke: $checklist--item--completed--color !important;
  }
}

@media only screen and (max-width: $sm) {
  .ChecklistItem {
    padding: 24px;
    .Title {
      font-family: $font--text--bold;
      font-size: 20px;
      line-height: 26px;
    }
    .SubTitle {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
