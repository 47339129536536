@use '../../../styles/breakpoints.scss';
@use '../../../styles/variables.scss';

.Container {
  display: flex;
  flex-direction: column;
  color: variables.$brand--primary--text-dark;
  gap: 40px;
}

@media only screen and (max-width: breakpoints.$sm) {
  .Container {
    gap: 16px;
    h4 {
      font-family: variables.$font--text--medium;
      font-size: 20px;
      line-height: 26px;
    }
  }
}
