@use '../../../../styles/breakpoints.scss';
@use '../../../../styles/variables.scss';

.InvoiceContactFormContainer {
  h4 {
    margin: 0;
    padding: 32px 0;
  }
  display: flex;
  flex-direction: column;

  .FormDescription {
    margin: 0 !important;
    padding: 0 0 30px !important;
    font-size: 16px;
    line-height: 24px;
  }
  .SubmitButton {
    display: flex;
    width: 100%;
    justify-content: left;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .InvoiceContactFormContainer {
    h4 {
      font-size: 24px !important;
      font-family: variables.$font--text--bold !important;
    }
    .FormDescription {
      padding: 12px 0 30px 0;
      font-size: 14px;
      line-height: 18px;
    }
  }
}
