@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.ListContainer {
  display: flex;
  flex-direction: column;
  gap: 1px;
  min-height: 700px;
  text-transform: none;
  a,
  a:visited {
    text-decoration: none;
    color: variables.$brand--primary--text-dark;
  }
  a:hover,
  a:focus {
    color: variables.$brand--primary--text-dark;
    cursor: pointer;
  }
}
.EmptyListMessage {
  display: flex;
  min-height: 75vh;
  color: variables.$brand--primary--text-dark;
  justify-content: center;
  align-items: flex-start;
  padding: 40px;
}

.Loading {
  height: 900px;
}

@media screen and (max-width: breakpoints.$sm) {
  .Loading {
    height: 650px;
  }
}
