@import './Reset.scss';
@import '../styles/variables.scss';
@import '../styles/font.scss';
@import '../styles/typography.scss';
a,
body {
  font-family: $font--text--light;
}

.SolidSport {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    word-break: break-word;
    white-space: normal;
  }
  background: $white;
  height: 100%;
  display: flex;
  flex-direction: column;
  .Main {
    flex: 1;
  }
}
