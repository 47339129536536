@import '../../../../../styles/variables.scss';
@import '../../../../../styles/breakpoints.scss';

.DropdownList {
  position: absolute;
  display: flex;
  flex-direction: column;
  max-height: 480px;
  width: 448px;
  overflow-y: scroll;
  z-index: 2;
  border-bottom-left-radius: $component-radius;
  border-bottom-right-radius: $component-radius;
  box-shadow: $elevation-2;
  background-color: $field--dropdown--item-background;
}
@media only screen and (max-width: $mobile) {
  .DropdownList {
    width: 100%;
    max-height: 290px;
  }
}
