@import '../../../../../styles/variables.scss';
.BackButton {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  padding: 8px;
  font-family: $font--text--medium;
  font-size: 14px;
  line-height: 14px;
  &:hover {
    cursor: pointer;
  }
}
