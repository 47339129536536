@import '../../../../styles/variables.scss';
@import '../../../../styles/breakpoints.scss';

.TitleTextButtonContainer {
  display: flex;
  flex-direction: column;
  .Title {
    font-family: $font--text--bold;
    font-size: 40px;
    padding-bottom: 24px;
    line-height: 48px;
  }
  .Text {
    font-family: $font--text--light;
    font-size: 20px;
    padding-bottom: 32px;
    line-height: 28px;
  }
}

@media only screen and (max-width: $sm) {
  .TitleTextButtonContainer {
    gap: 32px;
    .Title {
      font-size: 32px;
      line-height: 36px;
      padding: 0px;
    }
    .Text {
      font-size: 16px;
      line-height: 24px;
      padding: 0px;
    }
  }
}
