@use '../../../../../styles/variables.scss';
@use '../../../../../styles/breakpoints.scss';

.BottomContainer {
  max-width: 1460px;
  padding: 0 variables.$padding--left-right--desktop;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .InnerContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .DataProviderInfo {
    width: 100%;
    display: flex;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .BottomContainer {
    padding-left: variables.$padding--left-right--tablet;
    padding-right: variables.$padding--left-right--tablet;
    gap: 16px;

    .DataProviderInfo {
      gap: 4px;
      flex-direction: column-reverse;
    }
  }
}

@media only screen and (max-width: breakpoints.$mobile) {
  .BottomContainer {
    padding-left: variables.$padding--left-right--mobile;
    padding-right: variables.$padding--left-right--mobile;
  }
}
