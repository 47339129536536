@import '../../../../../styles/variables.scss';
@import '../../../../../styles/breakpoints.scss';
.CommentsInput {
  padding: 40px $padding--left-right--desktop;
  background: $chat--input--container--background-color;
}
@media only screen and (max-width: $sm) {
  .CommentsInput {
    padding: 24px $padding--left-right--tablet;
  }
}
@media only screen and (max-width: $mobile) {
  .CommentsInput {
    padding: 24px $padding--left-right--mobile;
  }
}

.IsViewingStream {
  padding: 16px 16px 32px 16px;
}
