@use '../../../../../styles/breakpoints.scss';
@use '../../../../../styles/variables.scss';

.GeneralSectionContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  padding: 0 variables.$padding--left-right--desktop;
}
.GeneralSectionContainer svg path {
  stroke: variables.$channel-theme--primary-color-inverted !important;
}

.LanguageAndCompany {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.LanguageContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.MenuItem {
  text-decoration: none;
  color: variables.$channel-theme--primary-color;
  text-align: center;
  a,
  a:visited {
    text-decoration: none;
    color: variables.$channel-theme--primary-color;
  }
  a:hover,
  a:focus {
    color: variables.$channel-theme--primary-color-inverted;
  }
}
.DropDownButton {
  padding-right: 14px;
  div {
    &:hover {
      color: variables.$channel-theme--primary-color-inverted !important;
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .GeneralSectionContainer {
    flex-direction: column;
    justify-content: center;
    padding: 0 variables.$padding--left-right--tablet;
  }
}
@media only screen and (max-width: breakpoints.$mobile) {
  .GeneralSectionContainer {
    padding: 0 variables.$padding--left-right--mobile;
  }
}
