@use '../../styles/variables.scss';
@use '../../styles/colors.scss';

.CheckboxWrapper {
  display: flex;
  flex-direction: row;
  label {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  :last-child {
    border-bottom: none;
    margin-bottom: 0 !important;
  }

  input[type='checkbox'] {
    -webkit-appearance: none;
    appearance: none;
    min-width: 32px;
    width: 32px;
    height: 32px;
    border-radius: variables.$component-radius;
    margin: 0 16px 0 0;
    background-color: colors.$grey--light--4;
    color: colors.$black--dark--2;
    outline: none;
    cursor: pointer;
  }

  input:checked {
    position: relative;
    color: colors.$black--dark--2;
  }
  input:checked::after {
    position: relative;
    color: colors.$black--dark--2;
    left: -7px;
    top: -5px;
    display: block;
    font-size: 22px;
    content: 'L';
    transform: rotate(45deg) scaleX(-1);
  }

  input:checked::before {
    font-size: 1.5em;
    color: colors.$white;
    position: absolute;
    right: 1px;
    top: -5px;
  }
}
