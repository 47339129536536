@use '../../../styles/breakpoints.scss';

.ShareModal {
  padding-top: 56px;
  padding-bottom: 56px;
  height: fit-content;
}

@media only screen and (max-width: breakpoints.$sm) {
  .ShareModal {
    height: 100%;
  }
}
