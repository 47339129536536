@use '.././../../../styles/breakpoints.scss';
@use '.././../../../styles/variables.scss';

.Container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  text-transform: none !important;
  .PrimaryButton {
    svg {
      path {
        stroke: variables.$channel-theme--cta-color !important;
      }
    }
  }
  .InteractionButtonContainer {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
}

@media only screen and (max-width: breakpoints.$mobile) {
  .Container {
    flex-direction: column;
    .PrimaryButton,
    .WatchFromBeginingButton {
      width: 100%;
    }
  }
}
