@use '../../../../../../styles/variables.scss';
.SubmitButton {
  box-sizing: border-box;
  background: variables.$secondary--default-background;
  border: none;
  color: variables.$secondary--default-color;
  border-radius: variables.$component-radius;
  width: 100%;
  padding: 17px 32px;
  text-transform: capitalize;
  &:hover {
    cursor: pointer;
    background: variables.$secondary--hover-background;
  }
}
