@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';
.SettingsContentHeader {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding-top: 48px;
  padding-bottom: 64px;
  padding-left: variables.$padding--left-right--desktop;
  padding-right: variables.$padding--left-right--desktop;
  background: variables.$channel--setting--content--container-background;

  .TopContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .SettingsButtonWrapper {
      position: relative;
    }
    .TooltipWrapper {
      width: 321px;
      position: absolute;
      right: 0;
      top: 70px;
    }
  }
  .Title {
    font-family: variables.$font--text--semi--bold;
    margin: 0;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .SettingsContentHeader {
    padding-left: variables.$padding--left-right--tablet;
    padding-right: variables.$padding--left-right--tablet;
    gap: 10px;
    .Title {
      font-family: variables.$font--text--semi--bold;
      font-size: 40px;
      line-height: 120%;
      letter-spacing: -0.01em;
    }
    .TopContainer {
      .TooltipWrapper {
        top: 48px;
      }
    }
  }
}
@media only screen and (max-width: breakpoints.$mobile) {
  .SettingsContentHeader {
    padding-left: variables.$padding--left-right--mobile;
    padding-right: variables.$padding--left-right--mobile;
    padding-top: 16px;
    padding-bottom: 16px;
    .TopContainer {
      .TooltipWrapper {
        :first-child {
          width: 100%;
        }
      }
    }
  }
}
@media only screen and (max-width: breakpoints.$xs) {
  .SettingsContentHeader {
    .TopContainer {
      .TooltipWrapper {
        width: 300px;
      }
    }
  }
}
@media only screen and (max-width: breakpoints.$small-mobile) {
  .SettingsContentHeader {
    .TopContainer {
      .TooltipWrapper {
        width: 90vw;
      }
    }
  }
}
