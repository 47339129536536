@use '../../../../../styles/variables.scss';
.ScoreContainer {
  display: flex;
  text-align: center;
  justify-content: center;
  font-family: variables.$font--text--medium;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.14px;
  min-width: 55px;
}
