@import '../../../../styles/variables.scss';
.Broadcast {
  display: flex;
  flex-direction: column;
  .ImageWrapper {
    overflow: hidden;
    position: relative;
    padding-bottom: 56.25%;
    background: $thumbnail--background--hover-color;
    border-radius: $component-radius;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      object-position: center;
      border-radius: $component-radius;
    }
  }

  .ThumbnailLabelContainer {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    border-radius: $component-radius;
    z-index: 10;
  }
  .TimeLabelContainer {
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: $component-radius;
    z-index: 10;
  }
  .RootDomainLabelContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
  }
  .BottomContainer {
    position: relative;
    box-sizing: border-box;
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 16px;
    display: flex;
    width: 100%;
    flex-direction: column;
    .Title {
      position: relative;
      font-family: $font--text--bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        + .TooltipWrapper {
          display: block;
        }
      }
    }
    .TooltipWrapper {
      bottom: 65px;
      z-index: 100;
      width: 100%;
      position: absolute;
      display: none;
    }
  }
}
.HasBottomContainer {
  &:hover {
    .ImageWrapper {
      img {
        opacity: 0.42;
        transition: $hover--transition-time;
      }
    }
  }
  &.xSmall {
    width: 280px;
    height: 243px;
    .BottomContainer {
      padding-top: 8px;
      .Title {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
  &.small {
    width: 318px;
    height: 243px;
    .BottomContainer {
      padding-top: 8px;
      .Title {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
  &.medium {
    width: 390px;
    height: 290px;
    .BottomContainer {
      padding-top: 8px;
      .Title {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  &.large {
    width: 494px;
    height: 356px;
    .BottomContainer {
      padding-top: 12px;
      .Title {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
  &.xLarge {
    padding: 0px;
    width: 1004px;
    height: 643px;
    .BottomContainer {
      padding-top: 12px;
      .Title {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
  &.keepRatio {
    width: 100%;
  }
}

.small {
  width: 318px;
  height: 179px;
}
.medium {
  width: 390px;
  height: 220px;
}
.large {
  width: 494px;
  height: 272px;
}
.xLarge {
  width: 1004px;
  height: 565px;
}
.keepRatio {
  width: 100%;
}
