@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.Container {
  display: flex;
  height: 48px;
  width: 100%;
  border-radius: variables.$component-radius;
  padding: 1px;
  background-color: white;
  .Wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    .InnerContainer {
      position: absolute;
      width: 100%;
      height: 100%;
      background: variables.$preloader--linear-gradient-3;
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .Container {
    height: 32px;
  }
}
@keyframes animate {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.Loader {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: animate;
  animation-timing-function: linear;
  display: flex;
  vertical-align: center;
}
