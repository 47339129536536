@use '../../../../styles/variables.scss';

.SubmenuLink {
  display: flex;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  &:hover {
    background: variables.$secondary--light-background;
    cursor: pointer;
  }
  a {
    width: 100%;
    color: variables.$default--text-color;
    text-decoration: none;
    font-family: variables.$font--text--medium;
    font-size: 14px;
    line-height: 14px;
    opacity: 0.85;
    padding: 8px 12px 8px 48px;
    &:hover {
      cursor: pointer;
    }
  }
}
.Active {
  background: variables.$secondary--light-background;
}
