@use '../multiStageTournamentVariables.scss' as mst;
@use '../../../styles/breakpoints.scss';
@use '../../../styles/variables.scss';

.FilteredGamesListContainer {
  text-transform: none;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: variables.$component-radius;

  .SelectedDateContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 32px;
  }

  .FilterSection {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    border-radius: variables.$component-radius;
    .DateSelector {
      position: absolute;
      right: 32px;
    }
    &.Primary {
      background-color: mst.$event--card--list--primary-background;
    }

    &.Secondary {
      background: mst.$event--card--list--secondary-background;
    }
    .TimeFilterContainer {
      width: 100%;
      max-width: 600px;
      &.Primary {
        background: mst.$event--card--list--secondary-background;
      }
      &.Secondary {
        background-color: mst.$event--card--list--primary-background;
      }
    }
  }
}
@media only screen and (max-width: breakpoints.$md) {
  .FilteredGamesListContainer {
    .HasDateFilter {
      margin-top: 32px;
      padding-top: 48px !important;
    }
    .FilterSection {
      .DateSelector {
        position: absolute;
        right: 16px;
        top: -24px;
      }
    }
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .FilteredGamesListContainer {
    padding-top: 32px;
    .FilterSection {
      padding: 16px;
      padding-top: 48px;
    }
  }
}
