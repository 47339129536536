@use '../../../../../styles/breakpoints.scss';
.InfoSection {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .FieldContainer {
    display: flex;
    flex-direction: row;
    gap: 16px;
    width: 100%;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .InfoSection {
    .FieldContainer {
      flex-direction: column;
    }
  }
}
