@use '../../../../../styles/variables.scss';
.Container {
  position: absolute;
  top: 48px;
  display: flex;
  flex-direction: column;
  border-radius: variables.$component-radius;
  width: 100%;
  z-index: 10;
  .List {
    max-height: 260px;
    overflow: auto;
    width: 100%;
    max-width: 100%;
    border-radius: variables.$component-radius;
    .Item {
      display: flex;
      flex-direction: row;
      padding: 16px 24px;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      font-family: variables.$font--text--light;
      font-size: 16px;
      font-style: normal;
      line-height: 16px;
      letter-spacing: -0.16px;
      border-radius: variables.$component-radius;
      background: variables.$secondary--light-background;

      &:hover {
        background: variables.$secondary--light--hover-background;
        cursor: pointer;
      }
      .CountryContent {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        .Flag {
          overflow: hidden;
          max-width: 21px;
          width: 21px;
          max-height: 16px;
          flex-shrink: 0;
          object-fit: contain;
          border-radius: 2px;
        }
        .Name {
          color: variables.$default--text-color;
          word-wrap: break-word;
        }
        .CountryCode {
          color: variables.$field--dropdown--item--disabled-color;
          width: 100px;
        }
      }
      .IsSelectedIcon {
        svg {
          height: 16px;
          width: 16px;
          path {
            stroke: black !important;
          }
        }
      }
    }
  }
}
