@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.ThemedHeader {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  max-height: variables.$navigation--header--desktop-height;
  padding: variables.$navigation--header--top-bottom--padding-desktop
    variables.$padding--left-right--desktop;
  z-index: 102;
  background-color: variables.$channel-theme--primary-background-color-inverted;
  color: variables.$channel-theme--primary-color-inverted;
  display: flex;
  flex-direction: row;
  align-items: center;
  .HeaderContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    gap: 40px;
    .LeftContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      overflow: hidden;
    }
    .RightContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
      width: 100%;
      .HamburgerMenuIcon {
        stroke: variables.$channel-theme--primary-color-inverted !important;
      }
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .ThemedHeader {
    flex-direction: column;
    max-height: unset;
    align-items: flex-start;
    gap: 16px;
    padding: variables.$navigation--header--top-bottom--padding-mobile
      variables.$padding--left-right--tablet !important;
    .RightContainer {
      gap: 0 !important;
      max-height: 24px;
    }
  }
}
.NavigationHeaderWithoutMenuItems {
  flex-direction: row;
  align-items: center;
}
@media only screen and (max-width: breakpoints.$mobile) {
  .ThemedHeader {
    padding: variables.$navigation--header--top-bottom--padding-mobile
      variables.$padding--left-right--mobile !important;
    .RightContainer {
      width: fit-content !important;
    }
    .HeaderContent {
      gap: 16px;
    }
  }
}
