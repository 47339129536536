@use '../../../../styles/breakpoints.scss';

.PasswordContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px 24px;
  flex: 1;
}

@media only screen and (max-width: breakpoints.$sm) {
  .PasswordContainer {
    padding: 24px 16px;
  }
}
