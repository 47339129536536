@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.FormModalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: variables.$modal--default--background-color;
  justify-content: center;
  padding: 56px 110px 64px;
  width: 1140px;
  gap: 16px;

  border-radius: variables.$component-radius;
  color: variables.$modal--default--color;
  .InsideContainer {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    .ModalHeader {
      color: variables.$modal--header--color;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 110%;
    }
  }
}

@media only screen and (max-width: breakpoints.$md) {
  .FormModalContainer {
    padding: 56px 64px 64px;
    width: 850px;
    .InsideContainer {
      max-height: 500px;
      overflow: auto;
    }
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .FormModalContainer {
    padding: 103px 48px;
    width: 100%;
    max-width: 100%;
    height: 100%;
    justify-content: flex-start;
    overflow: auto;
    .InsideContainer {
      justify-content: flex-start;
      padding: 0 0;
      max-height: unset;
      overflow: unset;
      .ModalHeader {
        font-size: 14px;
        line-height: 110%;
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .FormModalContainer {
    padding: 56px 24px;
  }
}
