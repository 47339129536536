@import '../../../../../styles/colors.scss';
@import '../../../../../styles/breakpoints.scss';

.StoreLinks {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  img {
    width: 144.51px;
    height: 42.81px;
  }
}

@media only screen and (max-width: $mobile) {
  .StoreLinks {
    gap: 16px;
  }
}
