@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.StreamMessage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
  padding: 16px;
  padding-left: variables.$padding--left-right--desktop;
  padding-right: variables.$padding--left-right--desktop;
  font-family: variables.$font--text--light;
  font-size: 16px;
  line-height: 24px;
  background-color: variables.$content--default-background;
  box-shadow: 0px -1px 0px 0px variables.$ghost--inverted--disabled-color inset;

  .LeftContainer {
    display: flex;
    gap: 16px;
    align-items: center;
    .Icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 24px;
    }
    .TextContent {
      overflow: auto;
      max-height: 100px;
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .StreamMessage {
    padding-left: variables.$padding--left-right--tablet;
    padding-right: variables.$padding--left-right--tablet;
  }
}
@media only screen and (max-width: breakpoints.$mobile) {
  .StreamMessage {
    padding-left: variables.$padding--left-right--mobile;
    padding-right: variables.$padding--left-right--mobile;
  }
}

.IsViewingStream {
  padding-left: 16px;
  padding-right: 16px;
}
