@use '../../../styles/variables.scss';
@use '../ContentVariables.scss' as contentVar;
@use '../../../styles/breakpoints.scss';

.StandingsContainer {
  width: 100%;
  background: variables.$default--light-background;
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  margin-bottom: 40px;
  .TableWrapper {
    background-color: variables.$default--light-background;
    margin-right: 32px;
  }
  .Table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .Even {
      background-color: contentVar.$standings-row-second-background-color;
    }
    .GroupTitle {
      font-size: 120%;
      border-bottom: 1px solid contentVar.$standings-standard-divider-color;
      padding-bottom: 14px;
    }
  }
}

.StandingsCenteredWrapper {
  margin: 0 auto;
}

.StandingsTable {
  display: flex;
  flex-direction: column;
  background-color: variables.$default--light-background;
  padding-top: 32px;
  padding-bottom: 32px;
}

@media screen and (max-width: breakpoints.$sm) {
  .StandingsContainer {
    gap: 48px;
    padding-top: 24px;
    padding-bottom: 24px;
    .TableWrapper {
      padding: 24px 0;
    }
    .Table {
    }
  }
}
@media screen and (max-width: breakpoints.$mobile) {
  .StandingsContainer {
    .Table {
    }
  }
}
