@use '../../../../../styles/variables.scss';
@use '../../../../../styles/breakpoints.scss';
.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  padding: 16px 32px 32px 32px;
  gap: 32px;
  background-color: variables.$content--default-background;
  border-radius: variables.$component-radius;

  .Title {
    font-family: variables.$font--text--bold;
    font-size: 24px;
    line-height: 32px;
  }
  .RefereList {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 6px;
    .RefereeName {
      text-transform: capitalize;
      font-family: variables.$font--text--medium;
      font-size: 20px;
      line-height: 28px;
      &::after {
        content: ',';
      }
      &:last-child {
        &::after {
          content: '';
        }
      }
    }
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .Container {
    padding: 32px 16px;
    gap: 24px;
    .Title {
      font-size: 20px;
      line-height: 28px;
    }
    .RefereList {
      .RefereeName {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

.IsViewingStream {
  padding: 32px 16px;
  gap: 24px;
  .Title {
    font-size: 20px;
    line-height: 28px;
  }
  .RefereList {
    .RefereeName {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
