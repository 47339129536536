@use '../../../../../../styles/variables.scss';
@use '../../../../../../styles/breakpoints.scss';

.ProsItemContainer {
  display: flex;
  flex-direction: row;
  gap: 12px;
  display: flex;
  align-items: center;
  .Icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 24px;
    min-height: 24px;
    height: 24px;
    width: 24px;
  }
  .Text {
    color: variables.$default--text-color;
    font-family: variables.$font--text--medium;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .ProsItemContainer {
    .Icon {
      min-width: 18px;
      min-height: 18px;
      height: 18px;
      width: 18px;
    }
    .Text {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.14px;
    }
  }
}
