@import '../../../../styles/variables.scss';
@import '../../../../styles/breakpoints.scss';

.ImageUploadContainer {
  position: relative;
}

.ImageUploadOverlayButton {
  position: absolute !important;
  background-color: $secondary--light-background !important;
  border-radius: 20px !important;
  &:hover {
    background-color: $ghost--inverted--hover-background !important;
  }
}
.Spinner {
  position: absolute;
  border-radius: 100px;
  padding: 2px;
  background-color: $secondary--light-background;
  width: 38px;
  height: 38px;
}
@media screen and (max-width: $sm) {
  .Spinner {
    position: absolute;
  }
}
