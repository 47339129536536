@use '../../../../styles/colors.scss';
@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.LabelContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: variables.$font--text--medium;
  border-radius: variables.$component-radius 0;
  font-size: 14px;
  line-height: 14px;
  display: inline-block;
  .Icon {
    display: flex;
    align-items: center;
    margin-right: 8px;
  }
}

.Live {
  background-color: variables.$live--background-color;
  padding: 8px 13px;
  color: colors.$white;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Coming {
  background-color: colors.$black--dark--2;
  color: colors.$white;
  padding: 8px 10px;
  .ComingContainer {
    display: flex;
    flex-direction: row;
  }
}
.Default {
  background-color: colors.$black--dark--2;
  color: colors.$white;
  padding: 8px 10px;
  .VideoContainer {
    display: flex;
    flex-direction: row;
  }
  .ReplayContainer {
    display: flex;
    flex-direction: row;
  }
}
.Highlight {
  background-color: colors.$grey--light--2;
  color: colors.$black--dark--2;
  padding: 8px 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.Dot {
  background: colors.$white;
  height: 6px;
  margin-right: 8px;
  width: 6px;
  border-radius: 50%;
}

@media only screen and (max-width: breakpoints.$mobile) {
  .LabelContent {
    font-size: 12px;
    line-height: 12px;
  }
}
