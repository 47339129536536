@import '../../../../styles/variables.scss';
@import '../../../../styles/breakpoints.scss';

.SportContainer {
  margin: 0 !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  height: 100%;
  width: 100%;
}
.SportContent {
  h3 {
    color: $brand--primary--text-light !important;
    font-family: $font--text--bold !important;
    letter-spacing: 0.01em !important;
    padding: 24px !important;
    margin: 0 !important;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}
