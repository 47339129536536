.Arrow {
  display: flex;
  align-content: center;
  align-items: center;
  vertical-align: center;
  cursor: pointer;
  transition: transform 0.15s;
}
.ArrowRotated {
  transform: rotate(180deg);
}
