@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.ModalWrapper {
  position: fixed;
  inset: 0; /* inset sets all 4 values (top right bottom left) much like how we set padding, margin etc., */
  background-color: $modal--blur--background-color;
  mix-blend-mode: normal;
  backdrop-filter: blur(12px);
  transition: all 0.3s ease-in-out;
  padding: 40px 20px;
  z-index: 200;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: center;
  .Modal {
    border-radius: $component-radius;
    position: relative;
    max-width: 1500px;
    max-height: 90vh;
    height: fit-content;
    overflow: hidden;
    .CloseButton {
      position: absolute;
      width: 24px;
      height: 24px;
      top: 42px;
      right: 42px;
      stroke: $modal--button--color;
      z-index: 201;
      &:hover {
        cursor: pointer;
        stroke: $modal--button--hover;
      }
    }
    .ModalContent {
      max-width: 2300px;
      max-height: 90vh;
      height: 100%;
    }
  }
}

@media only screen and (max-width: $sm) {
  .FullHeight {
    height: 100%;
    .Modal {
      height: 100%;
      width: 100%;
      .ModalContent {
        height: 100%;
      }
    }
  }
  .ModalWrapper {
    display: flex;
    padding: 0px;
    .Modal {
      padding: 0px;
      height: 100%;
      width: 100%;
      max-height: 100vh;
      max-width: 100%;
      .CloseButton {
        display: flex;
        justify-content: flex-end;
        width: 40px;
        height: 32px;
        right: 16px;
        top: 16px;
        .CloseIcon {
          width: 24px;
        }
      }
      .ModalContent {
        overflow: auto;
        max-height: 100vh;
        max-width: 100%;
      }
    }
  }
}
