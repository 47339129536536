@use '../../../../../styles/breakpoints.scss';

.Form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  .NewPasswordContainer {
    display: flex;
    flex-direction: row;
    gap: 24px;
    width: 100%;
    & > div {
      width: 100%;
    }
  }
  .ButtonContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .SubmitButton {
      padding: 14px 32px;
      margin-top: 0;
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .Form {
    .NewPasswordContainer {
      flex-direction: column;
    }
  }
}
