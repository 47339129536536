@import '../../../../styles/variables.scss';
@import '../../../../styles/breakpoints.scss';

.NewsTitleContainer {
  .Newstitle {
    font-family: $font--text--bold;
    margin: 0px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
  }
  .extraContent {
    padding-top: 24px;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
  }
}

@media only screen and (max-width: $mobile) {
  .NewsTitleContainer {
    .Newstitle {
      font-size: 16px;
      line-height: 24px;
    }
    .extraContent {
      display: none;
    }
  }
}
