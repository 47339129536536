@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.TestimonialsContainer {
  padding: 120px $padding--left-right--desktop;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 64px;
  background-color: $testimonials--section--background;
  .ImageContainer {
    .Image {
      width: 12vw;
    }
  }
  .RightSideContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 200px;

    .ButtonsContainer {
      display: flex;
      flex-direction: row;
      gap: 16px;
      padding-top: 20px;
      .Button {
        cursor: pointer;
      }
      .DisableBackwards,
      .DisableForwards {
        fill: $disabled--arrow--button--color;
        cursor: default;
      }
    }
  }
}
@media only screen and (max-width: $xl) {
  .TestimonialsContainer {
    .ImageContainer {
      .Image {
        width: 20vw;
      }
    }
    .RightSideContainer {
      height: 300px;
    }
  }
}
@media only screen and (max-width: $lg) {
  .TestimonialsContainer {
    .RightSideContainer {
      height: 350px;
    }
  }
}

@media only screen and (max-width: $md) {
  .TestimonialsContainer {
    .RightSideContainer {
      height: 500px;
    }
  }
}
@media only screen and (max-width: 1000px) {
  .TestimonialsContainer {
    .RightSideContainer {
      height: 620px;
    }
  }
}

@media only screen and (max-width: $sm) {
  .TestimonialsContainer {
    padding: 120px $padding--left-right--tablet;
    flex-direction: column;
    align-items: center;

    .ImageContainer {
      .Image {
        height: 400px;
        width: auto;
      }
    }
    .RightSideContainer {
      height: 150px;
    }
  }
}
@media only screen and (max-width: $mobile) {
  .TestimonialsContainer {
    padding: 120px $padding--left-right--mobile;
    .RightSideContainer {
      height: 150px;
    }
  }
}

@media only screen and (max-width: $xs) {
  .TestimonialsContainer {
    .RightSideContainer {
      height: 300px;
    }
  }
}

@media only screen and (max-width: $small-mobile) {
  .TestimonialsContainer {
    .RightSideContainer {
      height: 200px;
    }
  }
}
