@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.TextLinkContainer {
  display: flex;
  flex-direction: row;
  font-family: variables.$font--text--light;
  font-size: 12px;
  font-style: normal;
  gap: 3px;
  line-height: 12px;
  .Text {
    color: variables.$default--text-color;
  }
  .ClickableText {
    color: variables.$report--link-color;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .TextLinkContainer {
    font-family: variables.$font--text--medium;
  }
}
