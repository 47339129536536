@import '../../../../../styles/variables.scss';
@import '../../../../../styles/breakpoints.scss';

.Field {
  position: relative;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  background-color: $field-background;
  padding: 16px 24px;
  gap: 10px;
  height: 48px;
  width: 448px;
  border: $component-radius;
  align-items: center;
  z-index: 3;
  .Label {
    width: 100%;
    font-size: 16px;
    line-height: 16px;
    overflow: hidden;
    text-transform: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $field-text-color;
  }
  .Arrow {
    display: flex;
    align-content: center;
    align-items: center;
    vertical-align: center;
  }
  .ArrowDown {
    transition: transform 0.15s;
    transform: rotate(0deg);
  }
  .ArrowUp {
    transition: transform 0.15s;
    transform: rotate(180deg);
  }
  &:hover {
    cursor: pointer;
  }
}

@media only screen and (max-width: $mobile) {
  .Field {
    width: 100%;
  }
}
