@use '../../../styles/breakpoints.scss';

.GameScheduleListContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  .GameScheduleList {
    display: flex;
    flex-direction: column;
  }
  .ButtonContainer {
    padding-left: 51px;
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .GameScheduleListContainer {
    .GameScheduleList {
      gap: 12px;
    }
    .ButtonContainer {
      padding-left: 0;
      width: 100%;
    }
    .AddButton {
      width: 100%;
    }
  }
}
