@import '../../../../../../styles/variables.scss';

.DomainActionsButton {
  font-family: $font--text--medium;
}

.UpdateButtons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  height: 48px;
}
