@import './variables.scss';
@import './breakpoints';

// For  screens bigger than 899px wide
@media screen and (min-width: $sm) {
  .SolidSport {
    h1 {
      //styleName: Desktop/Headline/Headline 1;
      font-family: $font--text--bold;
      font-size: 80px;
      font-style: normal;
      font-weight: 700;
      line-height: 80px;
      letter-spacing: -0.01em;
      text-align: left;
    }
    h2 {
      //styleName: Desktop/Headline/Headline 2;
      font-family: $font--text--bold;
      font-size: 64px;
      font-style: normal;
      font-weight: 700;
      line-height: 77px;
      letter-spacing: -0.01em;
      text-align: left;
    }
    h3 {
      //styleName: Desktop/Headline/Headline 3;
      font-family: $font--text--bold;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: -0.01em;
      text-align: left;
    }
    h4 {
      //styleName: Desktop/Headline/Headline 4;
      font-family: $font--text--bold;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 35px;
      letter-spacing: -0.01em;
      text-align: left;
    }
    h5 {
      //styleName: Desktop/Headline/Headline 5;
      font-family: $font--text--bold;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
    }
    p {
      font-family: $font--text--light;
      font-size: 0.9em;
      line-height: 1.4em;
    }
  }
}
// these fonts should be used on smaller screens.
@media screen and (max-width: $sm) {
  .SolidSport {
    h1 {
      //styleName: Mobile/Headline/Headline 1;
      font-family: $font--text--bold;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: -0.01em;
      text-align: left;
    }
    h2 {
      //styleName: Mobile/Headline/Headline 2;
      font-family: $font--text--bold;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 35px;
      letter-spacing: -0.01em;
      text-align: left;
    }
    h3 {
      //styleName: Mobile/Headline/Headline 3;
      font-family: $font--text--bold;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
    }
    h4 {
      //styleName: Mobile / Headline / Headline 4;
      font-family: $font--text--bold !important;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
}
