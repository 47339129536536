@use '../../../../styles/variables.scss';
@use '../../multiStageTournamentVariables.scss' as mst;
@use '../../../../styles/breakpoints.scss';
.Container {
  display: flex;
  padding: 16px 48px;
  gap: 16px;
  align-items: center;
  align-self: stretch;
  background: mst.$filter--header--background;
  .SelectedFilters {
    border-left: mst.$filter--header--filters--container--left--border;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 24px;
    .ConnectorLine {
      width: 24px;
      height: 1px;
      background-color: variables.$default--text-color;
    }
  }
}

@media only screen and (max-width: breakpoints.$mobile) {
  .Container {
    padding: 16px;
    flex-direction: column;
    align-items: start;
    .SelectedFilters {
      flex-direction: column;
      border-left: unset;
      padding: 0px 24px;
      .ConnectorLine {
        height: 24px;
        width: 1px;
      }
    }
  }
}
