.Container {
  position: relative;
  .AdminButton {
    border-radius: 8px;
  }
  .TooltipContainer {
    position: absolute;
    right: 0;
    top: 52px;
    width: 320px;
  }
}
