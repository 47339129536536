@import '../../../../../../styles/variables.scss';
@import '../../../../../../styles/breakpoints.scss';

.ListHeader {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 16px 51px;
  gap: 16px;
  box-shadow: $inner--line--bottom--2;
  .ListHeaderItem {
    font-family: $font--text--medium;
    font-size: 16px;
    line-height: 24px;
    color: $list--header--item;
  }
  .TeamName {
    width: $team--list--team--name--width;
  }
  .ConnectedCompetitions {
    width: $team--list--ongoing--competitions--width;
  }
  .Status {
    width: $team--list--status--width;
  }

  .PlaceholderForIcon {
    width: $team--list--icon--width;
  }
}
@media only screen and (max-width: $sm) {
  .ListHeader {
    padding: 16px 24px;
    .ListHeaderItem {
      font-size: 14px;
      line-height: 18px;
    }
    .TeamName {
      width: $team--list--team--name--width-mobile;
    }
    .Status {
      width: $team--list--status--width-mobile;
    }
  }
}
@media only screen and (max-width: $mobile) {
  .ListHeader {
    display: none;
  }
}
