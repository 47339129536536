@import '../../../../styles/variables.scss';
@import '../../../../styles/breakpoints.scss';

.DropdownItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  white-space: wrap;
  text-overflow: ellipsis;
  padding: 24px;
  width: 100%;
  text-transform: none;
  font-size: 16px;
  line-height: 16px;
  color: $field--dropdown--item-color;
  font-family: $font--text--medium;
  background: $field--dropdown--item-background;
  &:hover {
    background: $field--dropdown--item--hover-background;
    cursor: pointer;
  }
}
.Disabled {
  background: $field--dropdown--item-background;
  color: $field--dropdown--item--disabled-color;
  &:hover {
    background: $field--dropdown--item-background;
    cursor: default;
  }
}
.Selected {
  background: $field--dropdown--item--selected-background;
}
@media only screen and (max-width: $mobile) {
  .DropdownItem {
    width: 100%;
    max-width: 100%;
  }
}
