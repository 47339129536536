@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.Container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .NoBroadcastLabel {
    width: fit-content;
  }
  .MainText {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .StreamTitle {
      font-family: variables.$font--text--bold;
      color: variables.$white;
      font-size: 32px;
      font-style: normal;
      line-height: 110%;
      letter-spacing: -0.32px;
    }
    .SubTitle {
      color: variables.$ghost--inverted--disabled-color;
      font-family: variables.$font--text--bold;
      font-size: 20px;
      font-style: normal;
      line-height: 28px;
    }
  }
  .Label {
    display: flex;
    gap: 16px;
    div {
      border-radius: variables.$component-radius !important;
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .Container {
    .MainText {
      .StreamTitle {
        font-size: 24px;
        line-height: 28px;
      }
      .SubTitle {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
