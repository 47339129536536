@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.ListContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  background: variables.$secondary--light-background;

  a {
    text-decoration: none;
  }
}

@media screen and (max-width: breakpoints.$sm) {
  .ListContainer {
    width: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
