@use '../../../../styles/breakpoints.scss';
.FacebookButton {
  padding: 14px 32px;
  path {
    stroke: none !important;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .FacebookButton {
    width: 100%;
  }
}
