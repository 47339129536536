@use '../../../../../../../styles/variables.scss';
@use '../../../../../../../styles/breakpoints.scss';

.DisplayLogoContainer {
  position: relative;
  width: 184px;
  height: 184px;
  border-radius: variables.$component-radius;
}

.DisplayLogoBackdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: variables.$component-radius;
  background-color: variables.$secondary--light-background;
  background-size: contain;
  filter: drop-shadow(0 0 0);
}
.WithTheme {
  background-color: variables.$channel-theme--primary-background-color-inverted;
}

.DisplayLogoOverlay {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  background: rgba(245, 245, 245, 0.7);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: variables.$component-radius;
}

.DisplayLogoOverlay:hover {
  opacity: 1;
}

@media only screen and (max-width: breakpoints.$sm) {
  .DisplayLogoContainer {
    position: inherit;
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: auto;
    width: fit-content;
  }

  .DisplayLogoBackdrop {
    position: relative;
    width: 138px;
    height: 138px;
  }

  .DisplayLogoOverlay {
    position: inherit;
    opacity: 1;
    background-color: variables.$white;
    flex-direction: row;
    gap: 12px;
    height: auto;
    width: auto;
  }
}
