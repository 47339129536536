@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';
@use '../../../../styles/colors.scss';
.Container {
  display: flex;
  flex-direction: column;
  width: 500px;
  min-width: 500px;
  max-width: 500px;
  max-height: 100%;
  height: 100%;
  overflow: hidden;
  background-color: variables.$content--default-background;
  .NavigationContainer {
    display: flex;
    flex-direction: column;
    .TopContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 18px 16px 0px 16px;
      gap: 8px;
      width: 500px;
      z-index: 100;
      .Content {
        display: flex;
        flex-direction: column;
        gap: 4px;
        .Label {
          border-radius: variables.$component-radius;
          overflow: hidden;
          font-size: 12px;
          line-height: 12px;
          width: fit-content;
        }
        .Title {
          font-family: variables.$font--text--medium;
          font-size: 16px;
          line-height: 24px;
        }
        .Categories {
          display: flex;
          flex-direction: row;
          gap: 4px;
        }
        .Category {
          color: colors.$black--dark--3;
          font-family: variables.$font--text--light;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.14px;
        }
      }
      .TeamLogos {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        .Logo {
          padding: 0 !important;
          border-radius: 0;
          img {
            height: 60px !important;
            width: 60px !important;
            max-width: 60px !important;
            max-height: 60px !important;
          }
        }
        .Divider {
          min-width: 2px;
          height: 50px;
          border-radius: 2px;
          overflow: hidden;
          background: colors.$grey--light--1;
        }
      }
    }
  }
  .PageRoutesWrapper {
    height: 100%;
    max-height: 100%;
    overflow: auto;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .Container {
    max-width: 100%;
    min-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
    .NavigationContainer {
      .TopContainer {
        width: 100%;
        .TeamLogos {
          display: none;
        }
      }
    }
  }
}
