$track-color: rgba(255, 255, 255, 0.6);
$track-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
// $track-shadow: none;
$thumb-color: #ffffff;
.VolumeControl {
  display: contents;
  flex-direction: row;
  .PlayerButton {
    width: 35px;
    .material-icons {
      // font-size: 2em !important;
    }
  }
  input[type='range'] {
    outline: none;
    outline-style: none;
    outline-width: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    width: 0;
    background: none;
    transition: 0.1s width linear, 0.1s height linear;
    -webkit-transition: 0.1s width linear, 0.1s height linear;
    -moz-transition: 0.1s width linear, 0.1s height linear;
    -ms-transition: 0.1s width linear, 0.1s height linear;
    &:active,
    &:focus {
      outline: none;
      outline-style: none;
      outline-width: 0;
    }
    &::-moz-focus-inner {
      outline: none;
      outline-style: none;
      outline-width: 0;
    }
    // Webkit
    &::-webkit-slider-runnable-track {
      background: $track-color;
      box-shadow: $track-shadow;
      outline: none;
      outline-style: none;
      outline-width: 0;
      border-radius: 6px;
      height: 6px;
      cursor: pointer;
      transition: 0.1s width linear, 0.1s height linear;
      -webkit-transition: 0.1s width linear, 0.1s height linear;
      -moz-transition: 0.1s width linear, 0.1s height linear;
      -ms-transition: 0.1s width linear, 0.1s height linear;
    }
    // Mozilla
    &::-moz-range-track {
      outline: none;
      background: $track-color;
      box-shadow: $track-shadow;
      border-radius: 6px;
      height: 6px;
      cursor: pointer;
      transition: 0.1s width linear, 0.1s height linear;
      -webkit-transition: 0.1s width linear, 0.1s height linear;
      -moz-transition: 0.1s width linear, 0.1s height linear;
      -ms-transition: 0.1s width linear, 0.1s height linear;
    }
    // MS
    &::-ms-track {
      background: $track-color;
      box-shadow: $track-shadow;
      border-radius: 6px;
      height: 6px;
      cursor: pointer;
      transition: 0.1s width linear, 0.1s height linear;
      -webkit-transition: 0.1s width linear, 0.1s height linear;
      -moz-transition: 0.1s width linear, 0.1s height linear;
      -ms-transition: 0.1s width linear, 0.1s height linear;
    }
    // Webkit
    &::-webkit-slider-thumb {
      display: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      cursor: pointer;
      background: $thumb-color;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      opacity: 0.7;
      transition: 0.1s width linear, 0.1s height linear;
      -webkit-transition: 0.1s width linear, 0.1s height linear;
      -moz-transition: 0.1s width linear, 0.1s height linear;
      -ms-transition: 0.1s width linear, 0.1s height linear;
      z-index: 10;
    }
    // Mozilla
    &::-moz-range-thumb {
      display: none;
      visibility: hidden;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      cursor: pointer;
      background: $thumb-color;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      opacity: 0.7;
      transition: 0.1s width linear, 0.1s height linear;
      -webkit-transition: 0.1s width linear, 0.1s height linear;
      -moz-transition: 0.1s width linear, 0.1s height linear;
      -ms-transition: 0.1s width linear, 0.1s height linear;
    }
    // MS
    &::-ms-thumb {
      display: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      cursor: pointer;
      background: $thumb-color;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      opacity: 0.7;
      transition: 0.1s width linear, 0.1s height linear;
      -webkit-transition: 0.1s width linear, 0.1s height linear;
      -moz-transition: 0.1s width linear, 0.1s height linear;
      -ms-transition: 0.1s width linear, 0.1s height linear;
    }
  }
  &:hover {
    input[type='range'] {
      width: 100px;
      &::-webkit-slider-runnable-track {
        height: 14px;
      }
      &::-moz-range-track {
        height: 14px;
      }
      &::-ms-track {
        height: 14px;
      }
      &::-webkit-slider-thumb {
        width: 14px;
        height: 14px;
        display: block;
      }
      &::-moz-range-thumb {
        width: 14px;
        height: 14px;
        display: block;
        visibility: visible;
      }
      &::-ms-thumb {
        width: 14px;
        height: 14px;
      }
    }
  }
}
