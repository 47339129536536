@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.Container {
  display: flex;
  flex-direction: column;
  background: variables.$content--default-background;
  min-height: 500px;
  width: 100%;
  .DropdownContainer {
    padding-top: 40px;
    padding-bottom: 24px;
    width: 448px;
  }
  .StatsWrapper {
    display: flex;
    flex-direction: column;
    gap: 80px;
    padding-top: 40px;
  }
  .PlayerStatisticsContainer {
    background-color: variables.$content--default-background;
  }
}

@media screen and (max-width: breakpoints.$sm) {
  .Container {
    .DropdownContainer {
      width: 100%;
    }
    .StatsWrapper {
      padding-top: 24px;
      gap: 64px;
    }
  }
}
