@use '../../../../styles/variables.scss';
.Container {
  display: flex;
  padding: 12px 0px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  font-family: variables.$font--text--bold;
  font-size: 24px;
  font-style: normal;
  line-height: 32px;
}
