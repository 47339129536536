@use '../../../../styles/variables.scss';
@use '../../../../styles/colors.scss';

.InformationButtonContainer {
  display: inline-flex;
  padding: 32px;
  justify-content: center;
  align-items: center;
}
.Grey {
  background-color: colors.$grey--light--2;
  &:hover {
    cursor: pointer;
    background-color: colors.$grey--light--1 !important;
  }
}
.White {
  background-color: colors.$grey--light--3;
  &:hover {
    cursor: pointer;
    background-color: variables.$ghost--hover-background;
  }
}
