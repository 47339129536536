@use '../../../styles/variables.scss';
.Loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  vertical-align: middle;
  width: 50px;
  height: 50px;
  max-height: 50px;
  border-radius: 100px;
  background: variables.$white;
  border: 3px solid variables.$ghost--disabled-background;
  div {
    width: 100%;
    height: 100%;
  }
}
