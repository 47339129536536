@use '../../styles/variables.scss';

.Popout {
  position: absolute;
  background-color: variables.$white;
  width: max-content;
  z-index: 101;
  border-radius: 2px;
  overflow: hidden;
  box-shadow: variables.$elevation-2;
}

.NE {
  bottom: calc(100% + 6px);
  right: 0;
}

.SE {
  top: calc(100% + 6px);
  right: 0;
}

.SW {
  top: calc(100% + 6px);
  left: 0;
}

.NW {
  bottom: calc(100% + 6px);
  left: 0;
}
