@use '../../../../styles/breakpoints.scss';
.LoginFormContainer {
  width: 100%;
  .Form {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .LoginButton {
      width: 100%;
      margin-top: 0;
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .LoginFormContainer {
    .Form {
      gap: 16px;
    }
  }
}
