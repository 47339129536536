@use '../../../../styles/breakpoints.scss';

.InfoBoxForm {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  .FormError {
    display: flex;
    flex-direction: column;
    padding-top: 48px;
    text-align: right;
    z-index: 50;
  }
}

@media only screen and (max-width: breakpoints.$md) {
  .InfoBoxForm {
    .FormError {
      text-align: left;
    }
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .InfoBoxForm {
    .FormError {
      text-align: left;
    }
  }
}
