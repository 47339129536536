.FilterRow {
  &::-webkit-scrollbar {
    display: none;
  }
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  box-sizing: border-box;
}

.FilterRow div:not(:last-child) {
  margin-right: 16px;
}

.FilterRow--small div:not(:last-child) {
  margin-right: 8px;
}
