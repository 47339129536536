@use '../../../../../styles/variables.scss';
@use '../../../../../styles/breakpoints.scss';

.Container {
  display: flex;

  height: 123px;
  padding: 12px 0px 12px 24px;
  align-items: flex-end;
  border-radius: variables.$component-radius;
  gap: 12px;
  background-color: variables.$secondary--light--hover-background;
  position: relative;
  overflow: hidden;
  .LeftWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: variables.$default--text-color;
    .Rank {
      font-family: variables.$font--text--medium;
      font-size: 16px;
      line-height: 24px;
    }
    .FirstName {
      font-family: variables.$font--text--medium;
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase;
      z-index: 2;
    }
    .LastName {
      text-wrap: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: variables.$font--text--bold;
      font-size: 24px;
      line-height: 28px;
      text-transform: uppercase;
      z-index: 2;
    }
    .TeamName {
      font-family: variables.$font--text--medium;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .StatsContainer {
    display: flex;
    height: 40px;
    padding: 4px 14px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    justify-content: center;
    background-color: variables.$white;
    border-radius: variables.$component-radius 0 0 variables.$component-radius;
    box-shadow: variables.$elevation-1;
    font-family: variables.$font--text--bold;
    font-size: 40px;
    line-height: 40px;
    z-index: 2;
  }
  .TeamImage {
    position: absolute;
    width: 264px;
    height: 264px;
    object-fit: contain;
    top: -100px;
    z-index: 1;
    opacity: 20%;
    right: -85px;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .Container {
    padding: 12px 0px 12px 16px;
    .LeftWrapper {
      .LastName {
        font-size: 20px;
        line-height: 26px;
      }
    }
    .StatsContainer {
      font-size: 24px;
      line-height: 28px;
    }
    .TeamImage {
      right: -125px;
    }
  }
}
