@use '../../../styles/breakpoints.scss';
@use '../../../styles/variables.scss';

.Container {
  padding: 32px 120px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

@media only screen and (max-width: breakpoints.$md) {
  .Container {
    padding: 32px 48px;
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .Container {
    padding: 32px;
  }
}
