@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';
@use '../../../styles/colors.scss';
.Container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 32px;
  gap: 24px;
  background-color: variables.$default--light-background;
  border-radius: variables.$component-radius;
  max-width: 1222px;
  width: 100%;

  .InformationBox {
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: variables.$default--text-color;
    .TextContent {
      display: flex;
      flex-direction: column;
      gap: 8px;
      h3 {
        margin: 0;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        margin: 0;
      }

      strong {
        font-family: variables.$font--text--medium;
      }
      a {
        color: colors.$link--color;
      }

      .Editor {
        display: none;
        visibility: hidden;
      }
    }
  }
  .ImageContainer {
    width: 35vw;
    height: 100%;
    max-width: 405px;
    img {
      border-radius: variables.$component-radius;
      width: 100%;
      height: 100%;
      max-height: 300px;
      object-fit: contain;
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .Container {
    flex-direction: column-reverse;
    justify-content: unset;
    padding: 24px;
    gap: 12px;
    .InformationBox {
      gap: 12px;
      .TextContent {
        gap: 4px;
        h3 {
          font-family: variables.$font--text--bold;
          font-size: 24px;
          font-weight: 700;
          line-height: 28px;
        }
      }
    }
    .ImageContainer {
      width: 100%;
      height: 100%;
      max-width: unset;
      img {
        height: 100%;
        max-height: 300px;
        object-fit: cover;
        width: 100%;
      }
    }
  }
}
