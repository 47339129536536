@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.HeroImageContainer {
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  max-height: 600px;
  .DefaultHeroImage {
    width: 100%;
    padding-bottom: variables.$channel-page--hero--image--size;
  }
  .RootHeroImage {
    width: 100%;
    padding-bottom: variables.$root-channel-page--hero--image--size--desktop;
  }
  position: relative;
  .UploadIcon {
    img {
      text-align: left;
    }
    position: absolute;
    bottom: 24px;
    right: 110px;
  }
  .PromotionOverlay {
    position: absolute;
    height: 100%;
    width: 100%;
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .DefaultHeroImage {
    width: 100%;
    padding-bottom: variables.$channel-page--hero--image--size--mobile !important;
  }
  .RootHeroImage {
    padding-bottom: variables.$root-channel-page--hero--image--size--mobile !important;
  }
  .UploadIcon {
    right: 32px !important;
  }
}

@media only screen and (max-width: breakpoints.$mobile) {
  .UploadIcon {
    right: 16px !important;
  }
}
