@use '../../../../../styles/colors.scss';
@use '../../../../../styles/variables.scss';
@use '../../../../../styles/breakpoints.scss';

@keyframes reportFormSuccess {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.ReportContentSuccessContainer {
  font-family: variables.$font--text--bold;
  max-width: 800px;
  padding: 138px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ReportContentSuccessCheckmark {
  width: 108px;
  height: 108px;
  animation-duration: 2s;
  animation-name: reportFormSuccess;
  animation-iteration-count: 1;

  svg {
    width: 54px;
    height: 36px;
  }

  border-radius: 50%;
  background-color: colors.$active--green--2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReportContentSuccessHeader {
  width: 500px;
  text-align: center !important;
  margin: 32px 0 16px;
}

.ReportContentSuccessText {
  font-family: variables.$font--text--light;
  font-size: 20px;
  line-height: 28px;
}

@media only screen and (max-width: breakpoints.$sm) {
  .ReportContentSuccessContainer {
    padding: 121px 0;
  }

  .ReportContentSuccessCheckmark {
    width: 54px;
    height: 54px;

    svg {
      width: 27px;
      height: 18px;
    }
  }

  .ReportContentSuccessHeader {
    width: 300px;
    text-align: center !important;
    margin: 32px 0 8px;
  }

  .ReportContentSuccessText {
    font-family: variables.$font--text--light;
    font-size: 14px;
    line-height: 18px;
  }
}
