@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.ReportContentFormContainer {
  max-width: 800px;
  padding: 110px variables.$padding--left-right--desktop;

  h2 {
    width: 100%;
    font-family: variables.$font--text--bold;
    font-weight: 700;
    font-style: normal;
    font-size: 64px;
    line-height: 77px;
    letter-spacing: -0.01em;
    margin: 16px 0;
    text-align: center;
  }
  .FormDescription {
    color: variables.$brand--primary--text-dark;
    font-weight: 400;
    line-height: 28px;
    font-size: 20px;
    text-align: center;
  }
  .FormOptionsContainer {
    display: flex;
    flex-direction: column;
    padding-top: 36px;
    .CheckboxGroup {
      padding-left: 32px;
    }
    .FormOption {
      line-height: 24px;
      font-size: 16px;
      padding-bottom: 20px;
    }
    .MessageContainer {
      padding-top: 20px;
      .MessageText {
        resize: none;
        width: 100%;
        height: 119px;
        font-family: variables.$font--text--light;
        box-sizing: border-box;
        padding: 16px 24px;
        color: variables.$chat--input--text-color;
        font-size: 16px;
        line-height: 16px;
        border: none;
        border-radius: variables.$component-radius;
        background-color: variables.$reporter--message--box-color;
      }
    }
  }
  .ButtonContainer {
    display: flex;
    justify-content: right;
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .ReportContentFormContainer {
    max-width: 500px;
    padding: 124px 48px 144px 48px;
    h2 {
      margin: 16px 0;
      line-height: 28px;
      font-size: 24px;
      font-weight: 700;
    }
    .FormDescription {
      font-weight: 400;
      line-height: 18px;
      font-size: 14px;
    }
    .FormOptionsContainer {
      padding-top: 33px;
      .FormOption {
        line-height: 22px;
        font-size: 14px;
        padding-bottom: 24px;
      }
    }
  }
}
