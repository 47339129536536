@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.PurchaseOptionCardContainer {
  border-radius: variables.$component-radius;
  width: 382px;
  max-width: 382px;
  font-size: 20px;
  line-height: 28px;
  color: variables.$default--text-color;

  &:hover {
    box-shadow: variables.$elevation-2;
  }

  .PurchaseOptionContentContainer {
    padding: 32px 40px;
    border-radius: variables.$component-radius;
    background-color: variables.$ghost--default-background;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    line-height: 28px;
  }

  .PurchasedOptionContentContainer {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  h4 {
    text-align: center;
    font-family: variables.$font--text--bold;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: -0.01em;
    text-align: center !important;
    padding-bottom: 10px;
    margin: 0;
  }
  .ProductPeriodCost {
    font-size: 20px;
    line-height: 28px;
  }
  .PurchaseOptionTitle {
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .PurchaseOptionIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    .PurchaseOptionIcon {
      height: 48px;
    }
  }
  .ButtonContainer {
    display: flex;
    justify-content: center;
    align-content: flex-end;
  }
  .Tournament {
    .PurchaseOptionContentContainer {
      min-height: 500px;
    }
  }
}

.IsPurchasedTag {
  background-color: variables.$active--default-background;
  color: variables.$subscription--card--is--purchased--tag-color;
  padding: 16px 0;
  display: flex;
  justify-content: center;
  width: 100%;
  font-family: variables.$font--text--medium;
  border-top-left-radius: variables.$component-radius;
  border-top-right-radius: variables.$component-radius;
}

.IsPurchasedBorder {
  border: 1px solid variables.$active--default-background;
}
.Subscription {
  .PurchaseOptionContentContainer {
    padding: 32px 40px;

    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 600px;
  }
}

.Tournament {
  .PurchaseOptionContentContainer {
    padding: 32px 40px;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .PurchaseOptionCardContainer {
    width: 300px;
    max-width: 300px;
    font-size: 14px;
    line-height: 18px;
    h4 {
      font-size: 24px;
      line-height: 28px;
    }
    .ProductPeriodCost {
      font-size: 14px;
      line-height: 18px;
    }
  }
  .Tournament,
  .Subscription {
    .PurchaseOptionContentContainer {
      min-height: 500px;
    }
  }
}

.IsPurchasedTag {
  background-color: variables.$active--default-background;
  color: variables.$subscription--card--is--purchased--tag-color;
  padding: 16px 0;
  display: flex;
  justify-content: center;
  width: 100%;
  font-family: variables.$font--text--medium;
  border-top-left-radius: variables.$component-radius;
  border-top-right-radius: variables.$component-radius;
}

.IsPurchasedBorder {
  border: 1px solid variables.$active--default-background;
}

@media only screen and (max-width: breakpoints.$sm) {
  .PurchaseOptionCardContainer {
    width: 300px;
    font-size: 14px;
    line-height: 18px;
    h4 {
      font-size: 24px;
      line-height: 28px;
    }
  }

  .IsPurchasedTag {
    padding: 8px 0;
  }

  .IsPurchasedTag {
    padding: 8px 0;
  }
}
