@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';
.Container {
  display: flex;
  flex-direction: column;
  gap: 32px;

  .LeadersWrapper {
    display: flex;
    flex-direction: row;
    gap: 24px;
    padding-right: variables.$padding--left-right--desktop;
  }
}

@media screen and (max-width: breakpoints.$sm) {
  .Container {
    gap: 12px;
    .LeadersWrapper {
      padding-right: variables.$padding--left-right--tablet;
    }
  }
}
@media screen and (max-width: breakpoints.$mobile) {
  .Container {
    .LeadersWrapper {
      padding-right: variables.$padding--left-right--mobile;
    }
  }
}
