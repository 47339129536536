@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.AdminItemContainer {
  padding: 24px 51px;
  border-radius: variables.$component-radius;
  background-color: variables.$secondary--light-background;
  .PromotionContainer {
    height: 250px;
    width: 100%;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .AdminItemContainer {
    .PromotionContainer {
      height: 200px;
    }
  }
}
