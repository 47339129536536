@import '../../../../../../styles/variables.scss';
@import '../../../../../../styles/breakpoints.scss';

.ListHeader {
  padding-top: 32px;
  font-family: $font--text--medium;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 16px;
}

.FontList {
  display: flex;
  flex-direction: column;
  .FontListHeader {
    display: flex;
    flex-direction: row;
    font-weight: bold;
    padding: 10px;
    font-size: 14px;
    line-height: 22px;
    font-family: $font--text--medium;
  }
  .Font {
    border-radius: $component-radius;
    display: flex;
    flex-direction: row;
    padding: 10px;
    font-size: 14px;
    line-height: 22px;
  }
  :nth-of-type(odd) {
    background-color: $alternating--list--background-color-1;
  }
  :nth-of-type(even) {
    background-color: $alternating--list--background-color-2;
  }
  :first-child {
    background-color: $white;
  }
}
.Cell {
  width: 50%;
  background-color: transparent !important;
  word-break: break-all;
  padding-right: 10px;
}
