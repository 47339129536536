@import '../../../styles/breakpoints.scss';

.ButtonContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

@media only screen and (max-width: $sm) {
  .ButtonContainer {
    flex-direction: column-reverse;
    button {
      width: 100%;
    }
  }
}
