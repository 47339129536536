@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.CategoryLeaderContainer {
  display: flex;
  flex-direction: column;
  width: 404px;
  gap: 12px;
  .Title {
    font-family: variables.$font--text--bold;
    color: variables.$default--text-color;
  }
  .RankingContainer {
    width: 404px;
    border-radius: variables.$component-radius;
    border: variables.$ghost--default-border;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .CategoryLeaderContainer {
    width: 285px;
    .Title {
      font-size: 20px;
      line-height: 26px;
    }
    .RankingContainer {
      width: 285px;
    }
  }
}
