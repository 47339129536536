@use '../../../../styles/breakpoints.scss';
.StreamList {
  display: flex;
  flex-wrap: wrap;
  row-gap: 40px;
  column-gap: 23px;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: breakpoints.$mobile) {
  .StreamList {
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 24px !important;
    .BroadcastThumbnailContainer {
      padding-bottom: 0px;
    }
    .game {
      padding-bottom: 57px;
    }
  }
}
