@font-face {
  font-family: 'NHaasGroteskDSPro-75Bd';
  src: local('NHaasGroteskDSPro-75Bd'),
    url(../fonts/NHaasGroteskDSPro-75Bd.otf) format('opentype'); //to use:"NHaasGroteskDSPro-75Bd"
}
@font-face {
  font-family: 'NHaasGroteskTXPro-55Rg';
  src: local('NHaasGroteskTXPro-55Rg'),
    url(../fonts/NHaasGroteskTXPro-55Rg.otf) format('opentype'); //to use:"NHaasGroteskTXPro-55Rg"
}

@font-face {
  font-family: 'NHaasGroteskTXPro-65Md';
  src: local('NHaasGroteskTXPro-65Md'),
    url(../fonts/NHaasGroteskTXPro-65Md.otf) format('opentype'); //to use:"NHaasGroteskTXPro-65Md"
}

@font-face {
  font-family: 'NHaasGroteskTXPro-75Bd';
  src: local('NHaasGroteskTXPro-75Bd'),
    url(../fonts/NHaasGroteskTXPro-75Bd.otf) format('opentype'); //to use:"NHaasGroteskTXPro-75Bd"
}

@font-face {
  font-family: 'NHaasGroteskTXPro-66MdIt';
  src: local('NHaasGroteskTXPro-66MdIt'),
    url(../fonts/NHaasGroteskTXPro-66MdIt.otf) format('opentype'); //to use:"NHaasGroteskTXPro-66MdIt"
}

@font-face {
  font-family: 'NHaasGroteskTXPro-75Bd';
  src: local('NHaasGroteskTXPro-75Bd'),
    url(../fonts/NHaasGroteskTXPro-75Bd.otf) format('opentype'); //to use:"NHaasGroteskTXPro-75Bd"
}

@font-face {
  font-family: 'NHaasGroteskTXPro-76BdIt';
  src: local('NHaasGroteskTXPro-76BdIt'),
    url(../fonts/NHaasGroteskTXPro-76BdIt.otf) format('opentype'); //to use:"NHaasGroteskTXPro-76BdIt"
}
