@use '../../../../../styles/variables.scss';
@use '../../../../../styles/breakpoints.scss';

.Theming {
  padding-left: 120px;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  .InnerContainer {
    display: flex;
    flex-direction: column;
    gap: 32px;
    .ColorSection {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .SectionLabel {
        font-family: variables.$font--text--bold;
        font-size: 16px;
        line-height: 1.25;
        color: variables.$default--text-color;
      }
      .Fields {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 16px;
      }
    }
  }
  .SubmitButton {
    width: fit-content;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .Theming {
    padding-left: 16px;
  }
}
