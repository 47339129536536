@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

*::before,
::after {
  box-sizing: content-box;
}

.AdminViewContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.AdminView {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  width: max-content;
  .LightText {
    color: variables.$menu-item--light--default-color;
    white-space: nowrap;
  }
  .Checkmark {
    width: 16px;
    height: 11px;
  }
  .LinkText {
    color: variables.$admin--link-color !important;
  }
  a {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    white-space: nowrap;
    &:hover {
      text-decoration: underline;
    }
  }
}
.AddAdminView {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  .InputField {
    background-color: variables.$form--text--area--background-color;
    padding: 16px 24px;
    border-radius: 2px;
    border-width: 0px;
    width: 343px;
  }
  .AddBtn {
    background-color: variables.$default--black-color;
    border: 1px solid variables.$form--text--area--background-color;
    cursor: pointer;
    padding: 15px 23px;
    width: 115px;
    &:hover {
      background-color: variables.$form--text--area--background-color;
    }
  }
  .InputError {
    border: 1px solid variables.$form--error--text-color;
  }
  .EmailField {
    display: flex;
    flex-direction: column;
  }
}
.EmailError {
  color: variables.$form--error--text-color;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
}

@media only screen and (max-width: breakpoints.$md) {
  .AdminView {
    .LightText {
      white-space: normal;
    }
    a {
      white-space: normal;
    }
  }
  .AddAdminView {
    .InputField {
      width: 200px;
    }
    .AddBtn {
      width: 112px;
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .AdminViewContainer {
    display: none;
  }
}
