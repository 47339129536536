@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.ForgotPasswordContent,
.SuccessEmailContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 32px;
  width: 100%;
  gap: 24px;
  padding-top: 64px;
  padding-bottom: 64px;
  .TextLinks {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
  .BottomLogo {
    justify-self: flex-end;
    .Logo {
      margin-top: 24px;
      height: 16px;
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .ForgotPasswordContent {
    padding: 0;
    .BottomLogo {
      margin-top: 32px;
    }
  }
}
