@use '../../../../styles/variables.scss';
@use '../../../../styles/colors.scss';

.TextFieldContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: variables.$component-radius;

  .TextContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: colors.$grey--light--2;

    .LeftSideText {
      display: flex;
      padding-bottom: 0 !important;
      font-family: variables.$font--text--medium !important;
      font-size: 16px;
      align-items: center;
      padding: 0 24px;
      height: 48px;
    }
    .TextField {
      border: none;
      width: 100%;
      border-radius: variables.$component-radius;
      height: 48px;
      font-size: 16px;
      padding: 16px 0 16px 24px;
      background-color: variables.$form--text--area--background-color;
    }
    .IconContainer {
      display: flex;
      height: 48px;
      .Loader {
        div {
          position: relative;
          top: -40px;
          left: 15px;
          height: 100px;
        }
      }
      background-color: variables.$form--text--area--background-color;
      padding: 14px;
      .Icon {
        background-color: variables.$form--text--area--background-color;
      }
    }
  }
  .FullUrl {
    font-size: 14px;
  }
  .Error {
    border: 1px variables.$form--error--border solid;
  }
}
