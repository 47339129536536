@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.Button {
  display: flex;
  border-radius: $button-border-radius;
  font-family: $font--text--medium;
  text-transform: inherit;
  border: none;
  font-size: 14px;
  line-height: 14px;
  width: fit-content;
  text-align: center;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
}
.ThemedInverted {
  background: $channel-theme--primary-background-color-inverted;
  color: $channel-theme--primary-color-inverted;
  border: solid 2px $channel-theme--primary-color-inverted;
  &:hover {
    background: $channel-theme--primary-color-inverted;
    color: $channel-theme--primary-background-color-inverted;
  }
  &:disabled {
    background: $primary--disabled-background;
  }
}

.ThemedPrimary {
  background: $channel-theme--primary-background-color;
  color: $channel-theme--primary-color;
  border: solid 2px $channel-theme--primary-color-inverted;
  &:hover {
    background: $channel-theme--primary-color;
    color: $channel-theme--primary-background-color;
  }
  &:disabled {
    background: $primary--disabled-background;
  }
}
.CTA {
  background: $channel-theme--cta--background-color;
  color: $channel-theme--cta-color;
  &:hover {
    opacity: 0.5;
  }
  &:disabled {
    background: $channel-theme--cta--background-color;
    opacity: 0.5;
  }
}
.Primary {
  background: $primary--default-background;
  color: $primary--default-color;
  &:hover {
    background: $primary--hover-background;
  }
  &:disabled {
    background: $primary--disabled-background;
  }
}
.Secondary {
  background: $secondary--default-background;
  color: $secondary--default-color;
  &:hover {
    background: $secondary--hover-background;
  }
  &:disabled {
    background: $secondary--disabled-background;
  }
}
.Active {
  background: $active--default-background;
  color: $active--default-color;
  &:hover {
    background: $active--hover-background;
  }
  &:disabled {
    background: $active--disabled-background;
  }
}
.Ghost {
  background: $ghost--default-background;
  color: $ghost-color;
  &:hover {
    background: $ghost--hover-background;
  }
  &:disabled {
    background: $ghost--disabled-background;
  }
}

.GhostWithBoarder {
  background: $ghost--default-background;
  color: $ghost-color;
  border: solid 2px $copy-modal--input-background;
  &:hover {
    background: $ghost--hover-background;
  }
  &:disabled {
    background: $ghost--disabled-background;
  }
}
.GhostInverted {
  border: $ghost--inverted--default-border;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background: $ghost--inverted--default-background;
  color: $ghost--inverted--default-color;
  &:hover {
    background: $ghost--inverted--hover-background;
    color: $ghost--inverted--hover-color;
  }
  &:disabled {
    border: $ghost--inverted--disabled-border;
    background: $ghost--inverted--disabled-background;
    color: $ghost--inverted--disabled-color;
  }
}

.CreateAccount {
  border: 1.5px solid #eaeaea;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background: $ghost--default-background;
  color: $ghost-color;
  &:hover {
    background: $ghost--hover-background;
  }
  &:disabled {
    background: $ghost--disabled-background;
  }
}

.Large {
  padding: 24px 32px;
}
.Medium {
  padding: 17px 32px;
}
.Small {
  padding: 8px 16px;
  font-size: 12px;
  line-height: 12px;
}
.XSmall {
  padding: 8px 16px;
  line-height: 12px;
  font-size: 12px !important;
}

@media only screen and (max-width: $sm) {
  .Large {
    padding: 24px 16px;
  }
  .Medium {
    padding: 17px 16px;
  }
  .Small {
    padding: 9px 16px;
    font-size: 12px;
  }
  .XSmall {
    padding: 9px 16px;
    font-size: 12px !important;
  }
}
