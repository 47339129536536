@use '../../../../../styles/variables.scss';
@use '../../../../../styles/breakpoints.scss';

.LinkSectionContainer {
  color: variables.$channel-theme--primary-color-inverted;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;
  justify-content: center;
  .Link {
    color: variables.$channel-theme--primary-color-inverted;
    text-decoration: underline;
    text-wrap: nowrap;
    a {
      &:hover {
        text-decoration: none;
        cursor: pointer;
      }
      &:active {
        text-decoration: none;
      }
      &:visited {
        text-decoration: none;
      }
      &:focus {
        text-decoration: none;
      }
    }
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .LinkSectionContainer {
    gap: 16px;
  }
}
