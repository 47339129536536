.UserImageButton {
  display: flex;
  align-items: center;
  .UserImage {
    margin-left: 16px;
    width: 48px;
    height: 48px;
    object-fit: contain;
    border-radius: 50%;
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}
