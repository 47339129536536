@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

@media only screen and (min-width: breakpoints.$xl) {
  .PurchaseContainer {
    .PurchaseOptionsContainer {
      justify-content: center;
    }
  }
  .MoreThanOneCard {
    justify-content: center;
  }
}
.LoadingContainer {
  min-height: 75vh;
}
.PurchaseContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 56px;
  padding-bottom: 32px;
  color: variables.$subscription--page-color;
  width: 100%;
}

.PurchaseHeading {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 variables.$padding--left-right--desktop;

  h3 {
    margin-bottom: 10px;
  }
}

.PurchaseTagLine {
  font-size: 20px;
  line-height: 28px;
  text-align: center;
}

.PurchaseOptionsContainer {
  max-width: 100%;
  display: flex;
  width: 100%;
  flex-direction: row;
  left: 0px;
  gap: 32px;
  padding: 48px 0 48px variables.$padding--left-right--desktop;
  overflow-y: visible;
  overflow-x: auto;
  justify-content: center;
  &:last-child {
    padding-right: variables.$padding--left-right--desktop;
  }
}
.MoreThanOneCard {
  justify-content: left;
  margin-left: 0;
}

@media screen and (max-width: breakpoints.$sm) {
  .PurchaseContainer {
    padding-top: 24px;

    .PurchaseHeading {
      h3 {
        font-size: 20px;
        line-height: 26px;
        font-family: variables.$font--text--bold !important;
      }
      .PurchaseTagLine {
        font-size: 14px;
        line-height: 18px;
      }
    }
    .PurchaseOptionsContainer {
      padding: 32px 0 32px variables.$padding--left-right--tablet;
      gap: 12px;
      &:last-child {
        padding-right: variables.$padding--left-right--tablet;
      }
    }
  }
}

@media screen and (max-width: breakpoints.$mobile) {
  .PurchaseContainer {
    .PurchaseHeading {
      padding: 0 variables.$padding--left-right--mobile;
    }
    .PurchaseOptionsContainer {
      padding: 32px 0 32px variables.$padding--left-right--mobile;
      &:last-child {
        padding-right: variables.$padding--left-right--mobile;
      }
    }
  }
}
