@import '../../../../../styles/variables.scss';
@import '../../../../../styles/breakpoints.scss';

.TextFieldContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 500px;
  .TextFieldLabels {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .PrimarayLabel {
    padding-bottom: 0 !important;
    font-family: $font--text--medium !important;
    font-size: 16px;
    line-height: 20px;
  }
  .DomainStatus {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;
  }
  .SecondaryLabel {
    font-family: $font--text--light !important;
  }
  .TextField {
    border: none;
    width: 100%;
    border-radius: $component-radius;
    height: 48px;
    font-size: 16px;
    padding: 16px 24px;
    background-color: $form--text--area--background-color;
  }
  .Error {
    border: 1px $form--error--text-color solid;
  }
}
