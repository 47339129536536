@use '../../../../../styles/variables.scss';
@use '../../../../../styles/breakpoints.scss';

.OptionsMenuItem {
  display: flex;
  align-items: center;
  user-select: none;
  width: 300px;
  padding: 24px;
  gap: 10px;
  font-size: 16px;
  line-height: 16px;
  color: variables.$field--dropdown--item-color;
  font-family: variables.$font--text--medium;
  background: variables.$field--dropdown--item-background;
  &:hover {
    background: variables.$field--dropdown--item--hover-background;
    cursor: pointer;
  }

  svg {
    display: flex;
    align-items: center;
    stroke: variables.$ghost-color;
    width: 16px;
    height: 16px;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .OptionsMenuItem {
    font-size: 14px;
    line-height: 14px;
  }
}
