.LoggingInLoadingOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10003;
  background: #ffffff33;
  display: flex;
  align-items: center;
  justify-content: center;
}
