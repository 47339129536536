@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.Modal {
  width: 800px;
  background-color: variables.$white;
  overflow: auto;
  max-height: 95vh;
}
.Success {
  padding-left: 24px;
  padding-right: 24px;
}
@media only screen and (max-width: breakpoints.$sm) {
  .Success {
    padding-left: 16px;
    padding-right: 16px;
  }
  .Modal {
    display: flex;
    align-items: center;
    width: 100%;
    max-height: unset;
  }
}
