@import '../../../styles/variables.scss';

.RoundIconButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  &:hover {
    cursor: pointer;
  }
}
.Light {
  background: $default--light-background !important;
  stroke: $default--light-color !important;
  &:hover {
    background: $hover--light-background !important;
    stroke: $hover--light-color !important;
  }
  &:active {
    cursor: grabbing;
  }
}
.Dark {
  background-color: $default--dark-background !important;
  stroke: $default--dark-color !important;
  &:hover {
    background: $hover--dark-background !important;
    stroke: $hover--dark-color !important;
  }
  &:active {
    cursor: grabbing;
  }
}
.Red {
  background-color: $default--dark-background !important;
  stroke: $default--dark-color !important;
  &:hover {
    background: $brand--primary !important;
    stroke: $brand--primary--text-light !important;
  }
  &:active {
    cursor: grabbing;
  }
}
.Black {
  background-color: $default--black-background !important;
  stroke: $default--black-color !important;
  &:hover {
    background: $hover--black-background !important;
    stroke: $hover--black-color !important;
  }
  &:active {
    cursor: grabbing;
  }
}
.IconButton--large {
  width: 136px !important;
  height: 136px !important;
}
.IconButton--medium {
  width: 40px !important;
  height: 40px !important;
}
.IconButton--small {
  width: 32px !important;
  height: 32px !important;
}
