@use '../../../../styles/variables.scss';
@use '../../../../styles/colors.scss';
@use '../../../../styles/breakpoints.scss';

.BotomTimeLabel {
  display: inline-block;
  padding: 8px 10px;
  background: colors.$grey--light--2;
  color: colors.$black--dark--2;
  border-radius: variables.$component-radius 0;
  font-size: 14px;
  line-height: 14px;
}

@media only screen and (max-width: breakpoints.$mobile) {
  .BotomTimeLabel {
    font-size: 12px;
    line-height: 12px;
  }
}
