@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.LinkSectionContainer {
  display: flex;
  padding: 4px 24px;
  flex-direction: column;
  align-items: flex-start;
  border-left: variables.$game--card--link--section--border;
  gap: 6px;
  .Icon {
    width: 20px;
  }
  .Link {
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: variables.$font--text--medium;
    font-size: 12px;
    line-height: 12px;
    color: variables.$default--text-color;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .Label {
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: variables.$font--text--medium;
    font-size: 12px;
    line-height: 12px;
    text-align: left;
    color: variables.$default--text-color;
    text-decoration: none;
  }
}

@media only screen and (max-width: breakpoints.$md) {
  .LinkSectionContainer {
    justify-content: center;
    align-items: center;
    border-left: unset;
    gap: 8px;
    align-self: stretch;
  }
}
