@use '../../styles/colors.scss';

$event--card--list-backround: colors.$grey--light--3;
$event--card--list--primary-background: colors.$white;
$event--card--list--secondary-background: colors.$grey--light--3;
$filter--header--background: colors.$grey--light--3;
$filter--header--filters--container--left--border: 1px solid
  colors.$black--dark--2;

$filter--header--item--active--background--color: colors.$white;
$filter--header--item--active--hover--color: colors.$grey--light--3;
$filter--header--item--active--color: colors.$black--dark--2;
$filter--header--item--active--border: 1px solid colors.$black--dark--2;

$filter--header--item--disabled--background--color: colors.$grey--light--3;
$filter--header--item--disabled--color: colors.$black--dark--3;
$filter--header--item--disabled--border: 1px solid colors.$black--dark--3;
$filter--item--primary--background--color: colors.$grey--light--3;
$filter--item--primary--hover--background--color: colors.$grey--light--2;

$filter--item--secondary--background--color: colors.$white;
$filter--item--secondary--hover--background--color: colors.$grey--light--2;

$event--schedule--background--color: colors.$white;
