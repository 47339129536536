@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.DefaultHeader {
  position: fixed;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: variables.$global--header--background;
  padding: 16px variables.$padding--left-right--desktop;
  z-index: 102;
  .TopHeaderLogo {
    height: 24px;
  }
  .RightSection {
    height: 48px;
    max-height: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    vertical-align: middle;
    flex-direction: row;
    gap: 16px;
    .SearchContainer {
      width: 100%;
      max-width: 345px;
    }

    .HamburgerMenuIcon {
      svg {
        stroke: variables.$white;
      }
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .DefaultHeader {
    padding: 16px variables.$padding--left-right--tablet;
    height: 64px !important;
    .RightSection {
      height: unset;
      gap: 0;
      .SearchContainer {
        width: unset;
      }
    }
  }
}
@media only screen and (max-width: breakpoints.$mobile) {
  .DefaultHeader {
    padding: 16px variables.$padding--left-right--mobile;
    .RightSection {
      gap: 0;
    }
  }
}
