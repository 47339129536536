@use '../../../styles/breakpoints.scss';
@use '../../../styles/variables.scss';

.EventCardListContainer {
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 32px;
  .EventCardGroupContainer {
    display: flex;
    flex-direction: column;
    gap: 2px;
    h4 {
      margin: 0;
      padding-top: 0;
      padding-bottom: 16px;
    }
  }
}
.EmptyMessageContainer {
  padding: 48px 110px;
}
@media only screen and (max-width: breakpoints.$md) {
  .EventCardListContainer {
    width: 100%;
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .EventCardListContainer {
    gap: 16px;
    padding: 32px 0px;
    width: 100%;
    .EventCardGroupContainer {
      h4 {
        text-align: left;
        font-size: 20px;
        line-height: 26px; /* 130% */
        font-family: variables.$font--text--bold;
      }
    }
  }
  .EmptyMessageContainer {
    padding: 32px 16px;
  }
}
