@use '../../styles/variables.scss';

.PreviewContainer {
  display: inline-flex;
  flex-direction: row;
  padding-top: 16px;
  margin-bottom: 16px;
  margin-right: 16px;
  height: 100px;
  box-sizing: border-box;
}
.FileInfo {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-left: 16px;
  font-size: 12px;

  .FileName {
    font-family: variables.$font--text--medium;
  }
  .FileSize {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }
}

.InnerPreview {
  display: flex;
  min-width: 0;
  overflow: hidden;
}

.PreviewImg {
  display: block;
  width: auto;
  height: 100%;
  max-width: 100%;
  object-fit: contain;
}
