@use '../../../../../styles/variables.scss';
@use '../../../../../styles/breakpoints.scss';

.Row {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 24px;
  gap: 12px;
  box-shadow: variables.$inner--line--bottom--1;
  &:last-child {
    box-shadow: unset;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .Row {
    gap: 6px;
    padding: 9px 16px;
  }
}
