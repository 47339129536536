@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.MenuPreloader {
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: space-between;
  .Loader {
    display: block;
    width: 80px;
    height: 30px;
  }
}

.MenuLoaderContainer {
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  gap: 30px;
}

@keyframes animate {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.ThemedLoader {
  background: $preloader--linear-gradient-themed;
}

.NormalLoader {
  background: $preloader--linear-gradient-2;
}

.Loader {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: animate;
  animation-timing-function: linear;
  position: relative;
  display: flex;
  vertical-align: center;
  background-size: 1000px 100%;
}

@media only screen and (max-width: $sm) {
  .MenuPreloader {
    gap: 20px;
    width: 100%;
    .Loader {
      height: 21px;
      width: 40px;
    }
  }
}
