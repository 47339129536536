.GreenTickBulletContainer {
  display: flex;
  flex-direction: row;
  padding-bottom: 8px;
  align-items: center;

  .GreenTick {
    height: 21px;
    width: 14.5px;
    margin-right: 32px;
  }
}
