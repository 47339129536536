@use '../../../../styles/breakpoints.scss';
.ResetPasswordFormContainer {
  width: 100%;

  .Form {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .SendButton {
      width: 100%;
      margin-top: 0;
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .ResetPasswordFormContainer {
    .Form {
      gap: 16px;
    }
  }
}
