@use '../../../styles/variables.scss';
.MenuItem {
  display: flex;
  padding-left: 32px;
  align-items: center;
  gap: 6px;
  width: 100%;
  &:hover {
    background: variables.$secondary--light-background;
    cursor: pointer;
  }

  .Icon {
    min-width: 24px;
    min-height: 24px;
    width: 24px;
    height: 24px;
  }

  a {
    font-family: variables.$font--text--bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.08px;
    color: variables.$default--text-color;
    text-decoration: none;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.Active {
  background: variables.$secondary--light-background;
}

.Container {
  display: flex;
  flex-direction: column;

  .MenuItem {
    padding: 12px 12px 12px 32px;
    justify-content: space-between;
  }
  .LinkContent {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 6px;
    .Label {
      font-family: variables.$font--text--bold;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.08px;
    }
  }
  .SubmenuLinks {
    display: flex;
    flex-direction: column;
  }
}
