@use '../../../../styles/breakpoints.scss';

.RootChannelLink {
  display: flex;
  align-items: center;
}
.RootLogoImage {
  max-width: 200px;
  object-fit: contain;
}
.ImageFooter {
  height: 40px;
  max-height: 40px;
  max-width: 120px;
}
.ImageHeader {
  height: 48px;
}
.Header {
  padding-right: 40px;
  height: 40px;
}
@media only screen and (max-width: breakpoints.$sm) {
  .ImageHeader {
    height: 32px;
    max-height: 32px;
  }
  .ImageFooter {
    height: 32px;
    max-height: 32px;
  }
}
