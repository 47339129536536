@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.LeftPadding {
  margin-left: $padding--left-right--desktop;
}
.NoPadding {
  margin-left: 0;
}

.SectionHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-right: 24px;
  margin-bottom: 32px;

  .Label {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    font-family: $font--text--medium;
  }
  .ShowAllLink {
    display: flex;
    align-items: center;
    color: $thumbnail--section--Header--link--default-color;
    stroke: $thumbnail--section--Header--link--default-color;
    font-size: 20px;
    line-height: 28px;
    font-family: $font--text--light;
    .Icon {
      display: flex;
      align-items: center;
      padding-left: 4px;
    }
    &:hover {
      color: $thumbnail--section--Header--link--hover-color;
      stroke: $thumbnail--section--Header--link--hover-color;
      text-decoration: none;
    }
  }
}
@media only screen and (max-width: $sm) {
  .LeftPadding {
    margin-left: $padding--left-right--tablet;
  }
  .NoPadding {
    margin-left: 0;
  }
  .SectionHeader {
    margin-right: 24px;
    .Label {
      font-family: $font--text--medium;
      font-size: 20px;
      line-height: 26px;
    }
  }
}

@media only screen and (max-width: $mobile) {
  .LeftPadding {
    margin: 0 $padding--left-right--mobile;
  }
  .NoPadding {
    margin-left: 0;
  }

  .SectionHeader {
    margin-bottom: 12px !important;

    .Label {
      font-family: $font--text--medium;
      font-size: 20px;
      line-height: 26px;
    }
    .ShowAllLink {
      font-size: 14px;
      line-height: 28px;
      .Icon {
        padding-left: 4px;
      }
    }
  }
}
