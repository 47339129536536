@import '../../../../../../styles/variables.scss';
@import '../../../../../../styles/breakpoints.scss';

.ProgressBarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  .PercentageText {
    font-family: $font--text--bold;
    font-size: 20px;
    line-height: 28px;
  }
  .ProgressBar {
    width: 100%;
    background: $progress--bar--background;
    height: 20px;
    border-radius: 20px;
    overflow: hidden;
    .Progress {
      height: 100%;
      background-color: $progress--bar--progress--background;
      border-radius: 20px;
      transition: 'width 1s ease-in-out';
    }
    .Completed {
      background-color: $progress--bar--completed--background;
    }
  }
}

@media only screen and (max-width: $sm) {
  .ProgressBarContainer {
    .PercentageText {
      font-size: 14px;
      line-height: 18px;
    }
    .ProgressBar {
      height: 15px;
    }
  }
}
