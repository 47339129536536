@use '../../styles/variables.scss';
.ErrorContainer {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: 55vh;
  color: variables.$default--text-color;
  .Title {
    text-align: center;
  }
  .SubText {
    font-family: variables.$font--text--light;
    font-size: 16px;
    line-height: 24px;
  }
}
