@use '../../../../../styles/breakpoints.scss';
.ShareButton,
.LikeButton {
  padding: 14px 32px !important;
  text-transform: none;
}

@media only screen and (max-width: breakpoints.$mobile) {
  .LikeButton,
  .ShareButton {
    width: 100%;
  }
}
