@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.GameScheduleCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 24px;
  text-transform: none;
  border-radius: variables.$component-radius;
  background: variables.$game--schedule--default--background-color;
  box-shadow: variables.$game--schedule--box-shadow;
  gap: 24px;
  max-width: 100%;
  .RightContainer {
    display: flex;
    align-items: center;
    margin-left: 24px;
    width: 100%;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .GameScheduleCard {
    flex-direction: column;
    .RightContainer {
      margin-left: 0;
    }
  }
}
