@use '../../../../styles/breakpoints.scss';
@use '../../ContentVariables.scss' as contentVar;
.StandingsRow {
  display: grid;
  align-items: center;
  justify-items: center;
  border-bottom: 1px solid contentVar.$standings-standard-divider-color;
  min-height: contentVar.$standings-row-height-desktop;
  line-height: contentVar.$standings-row-line-height-desktop;
  gap: contentVar.$standings-column-gap-desktop;
  font-size: contentVar.$standings-row-font-size-desktop;
  padding: contentVar.$standings-row-padding-desktop;

  *:first-child {
    justify-self: flex-start;
  }
}

.IsLastPlacement {
  border-bottom: none;
}

.IsLastBeforeDivider {
  border-bottom: 1px solid contentVar.$standings-accentuated-divider-color;
}
.IsPreSelected {
  background-color: contentVar.$standings-row-second-background-color;
}
.IsClickable {
  cursor: pointer;

  &:hover {
    background-color: contentVar.$standings-row-hover-background-color;
  }
}

@media screen and (max-width: breakpoints.$sm) {
  .StandingsRow {
    min-height: contentVar.$standings-row-height-mobile;
    line-height: contentVar.$standings-row-line-height-mobile;
    gap: contentVar.$standings-column-gap-mobile;
    font-size: contentVar.$standings-row-font-size-mobile;
    padding: contentVar.$standings-row-padding-mobile;
  }
}
