@use '../../../../styles/variables.scss';
@use '../../../../styles/colors.scss';
@use '../../../../styles/breakpoints.scss';

.RelatedChannelItem {
  display: flex;
  gap: 24px;
  align-items: stretch;
  cursor: pointer;
  color: inherit;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
}

.RelatedChannelItemLogo {
  img {
    height: 80px;
    width: 80px;
    object-fit: contain;
  }

  svg {
    height: 72px;
    width: 72px;
  }
}

.RelatedChannelItemDetails {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: auto 0;
}

.RelatedChannelItemType {
  font-family: variables.$font--text--semi--bold;
  line-height: 28px;
  font-size: 20px;
}

.RelatedChannelItemName {
  font-family: variables.$font--text--light;
  color: colors.$black--dark--3;
  line-height: 28px;
  font-size: 20px;
  white-space: nowrap;
}

@media screen and (max-width: breakpoints.$sm) {
  .RelatedChannelItem {
    gap: 8px;
  }

  .RelatedChannelItemLogo {
    img {
      height: 60px;
      width: 60px;
    }

    svg {
      height: 54px;
      width: 54px;
    }
  }

  .RelatedChannelItemType {
    line-height: 24px;
    font-size: 16px;
  }

  .RelatedChannelItemName {
    line-height: 24px;
    font-size: 16px;
  }
}
