@use '../../styles/colors.scss';
@use '../../styles/breakpoints.scss';

.PaginationContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 1000px;
  .PageLinksContainer {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
  .ArrowContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1000px;
    background-color: colors.$grey--light--2;
    height: 36px;
    width: 36px;
    &:hover {
      cursor: pointer;
      background-color: colors.$grey--light--3;
    }
  }
  .Disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .PaginationContainer {
    .ArrowContainer {
      height: 32px;
      width: 32px;
    }
  }
}

@media screen and (max-width: breakpoints.$xs) {
  .PaginationContainer {
    border-radius: 0;
    max-width: 100%;
    padding-bottom: 24px;
    width: unset;
    justify-content: unset;
    margin-left: auto;
    margin-right: auto;
    overflow-x: auto;
  }
}
