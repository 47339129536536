@use '../../../../../styles/colors.scss';
@use '../../../../../styles/variables.scss';
.FooterItemDropdown {
  display: inline-block;
  position: relative;
  color: colors.$black--dark--3;
  .Button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    line-height: 22px;
    .Icon {
      padding-left: 2px;
    }
    &:hover {
      color: variables.$footer--highlight--text;
      cursor: pointer;
    }
  }
  .DropdownContent {
    display: block;
    position: absolute;
    text-align: start;
    background-color: colors.$white;
    height: 140px;
    overflow-y: auto;
    font-size: 14px;
    box-shadow: variables.$elevation-2;
    .Item {
      width: 100%;
      width: 120px;
      font-family: variables.$font--text--light;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      text-transform: capitalize;
      line-height: 22px;
      color: variables.$default--text-color;
      padding: 6px 10px;
      text-decoration: none;
      .LanguageLabel {
        color: variables.$default--text-color;
      }
      .Checkmark {
        display: none;
        color: variables.$default--text-color;
      }
      &:hover {
        color: variables.$footer--highlight--text;
        background-color: colors.$grey--light--4;
        cursor: pointer;
        .Checkmark {
          display: block;
        }
      }
    }
    .Highlight {
      color: variables.$footer--highlight--text;
      background-color: colors.$white;
      .Checkmark {
        display: block;
      }
    }
  }
  .Content--Top {
    bottom: 30px;
    z-index: 2;
  }
  .Content--Bottom {
    top: 30px;
  }
}
