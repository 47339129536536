@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.DropdownListContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 12px;
  width: 100%;

  .Label {
    padding-bottom: 0 !important;
    font-family: $font--text--medium !important;
    font-size: 16px;
    line-height: 20px;
  }
}
