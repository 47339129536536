@use '../../../../styles/variables.scss';

.OtherLoginOptionsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  .Buttons {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
}

@media only screen and (max-width: 420px) {
  .OtherLoginOptionsContainer {
    .Buttons {
      flex-wrap: wrap;
      gap: 12px;
    }
  }
}
