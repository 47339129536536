@use '../../../../styles/variables.scss';

.LanguageMenuLink {
  display: flex;
  padding-left: 32px;
  align-items: center;
  gap: 6px;
  width: 100%;
  font-family: variables.$font--text--bold;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.08px;
  padding-top: 12px;
  padding-bottom: 12px;
  color: variables.$default--text-color;
  &:hover {
    background: variables.$secondary--light-background;
    cursor: pointer;
  }
}
