@use '../../../../styles/variables.scss';
.TextContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 12px;

  .Title {
    font-size: 24px;
    line-height: 28px;
    font-family: variables.$font--text--bold;
  }
}
