@use '../../../../styles/breakpoints.scss';
.AccountDetails {
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1;
}

@media only screen and (max-width: breakpoints.$sm) {
  .AccountDetails {
    padding: 24px 16px;
  }
}
