@use '../../styles/variables.scss';

.BrokenLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: variables.$component-radius;
  padding: 16px;
  margin-bottom: 16px;
  background-color: variables.$white;
  .InfoText {
    padding-left: 16px;
    gap: 16px;
    display: flex;
    flex-direction: column;
  }
}
