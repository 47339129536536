@use '../../../../../styles/colors.scss';
@use '../../../../../styles/variables.scss';
@use '../../../../../styles/breakpoints.scss';

.ArticlePageContainer {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: variables.$content--default-background;
  border-radius: variables.$component-radius;
  overflow: hidden;
  position: relative;
  .EditButton {
    top: 32px;
    right: 32px;
    position: absolute;
    z-index: 10;
  }
  .ContentWrapper {
    display: flex;
    flex-direction: column;
    .ImageContainerWrapper {
      width: 100%;
      height: 100%;
      max-height: 500px;
      border-radius: variables.$component-radius;
      overflow: hidden;
      .ImageContainer {
        width: 100%;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-position: center center;
        padding: 0;
        padding-bottom: 56.25%;
        position: relative;
        .ArticleImage {
          height: 100%;
          width: 100%;
          object-fit: cover;
          position: absolute;
          object-position: center;
        }
      }
    }

    .ArticleBox {
      display: flex;
      flex-direction: column;
      .ArticleBody {
        padding: 72px 258px 156px;
        display: flex;
        flex-direction: column;

        .Article {
          display: flex;
          justify-self: flex-start;
          flex-direction: column;
          gap: 42px;
          .Title {
            font-size: 40px;
            line-height: 120%;
            letter-spacing: -0.4px;
            margin: 0px;
          }
          .SubTitle {
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
            letter-spacing: 0em;
            text-align: left;
          }
          .DateText {
            color: variables.$tag-color;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
          }
          .Content {
            overflow: hidden;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            p {
              font-weight: 400;
              font-size: 20px;
              line-height: 28px;
            }
          }
        }
      }
    }
  }
}

.ArticleReadError {
  display: flex;
  justify-content: center;
  align-items: center;
  color: colors.$solid-red;
}

@media only screen and (max-width: breakpoints.$md) {
  .ArticlePageContainer {
    .EditButton {
      top: 24px;
      right: 24px;
    }
    .ContentWrapper {
      .ArticleBox {
        .ArticleBody {
          padding: 53px 100px;
          .Article {
            gap: 24px;

            .SubTitle {
              font-family: variables.$font--text--bold;
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
            }
            .Title {
              font-family: variables.$font--text--bold;
              font-weight: 700;
              font-size: 32px;
              line-height: 35px;
            }
            .Content {
              font-size: 16px;
              line-height: 24px;
              p {
                font-size: 16px;
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: breakpoints.$mobile) {
  .ArticlePageContainer {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .EditButton {
      top: 16px;
      right: 16px;
    }
    .ContentWrapper {
      .ArticleBox {
        .ArticleBody {
          padding: 33px 22px;
          .Article {
            .DateText {
              padding-bottom: 15px;
            }
            .Title {
              font-weight: 700;
              font-size: 32px;
              line-height: 35px;
            }
            .SubTitle {
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
            }
            .Content {
              font-size: 16px;
              line-height: 24px;
              p {
                font-size: 16px;
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  }
}
