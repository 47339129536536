@import '../../../../styles/variables.scss';

.EmptyContainer {
  background-color: $ghost--default-background;
  padding: 32px;
}
.xSmall {
  width: 280px;
  height: 243px;
}
.small {
  width: 318px;
  height: 243px;
}
.medium {
  width: 390px;
  height: 290px;
}
.large {
  width: 494px;
  height: 356px;
}
.xLarge {
  width: 1004px;
  height: 643px;
}
