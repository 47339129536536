@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.Schedule {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: variables.$secondary--light-background;
  .ToggleButtons {
    margin-top: 40px;
    margin-bottom: 40px;
    width: 600px;
    background-color: variables.$ghost--default-background;
  }
  .GameScheduleContent {
    min-height: 80vh;
    max-width: 988px;
    width: 100%;
    margin-bottom: 40px;
  }
}
.Empty {
  padding-top: 40px;
  padding-bottom: 80px;
}
.Error {
  padding: variables.$gutter--size--desktop 0;
  color: variables.$game--schedule--default-color;
}
.NoGames {
  padding: variables.$gutter--size--desktop 0;
  color: variables.$game--schedule--default-color;
}
@media only screen and (max-width: breakpoints.$mobile) {
  .Schedule {
    .ToggleButtons {
      width: 400px;
      max-width: 100%;
    }
  }
}
