@use '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';

.ComingSoonPage {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .BackgroundOverlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.607),
      rgba(255, 0, 0, 0)
    );
  }
  .PoweredByContainer {
    position: relative;
    width: fit-content;
    margin-top: 48px;
    padding: 20px 20px 20px 87px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-bottom-right-radius: variables.$component-radius;
    background: rgba(
      variables.$coming--soon--powered--by--container--background,
      0.4
    );

    .PoweredByText {
      font-family: variables.$font--text--light;
      font-size: 16px;
      line-height: 12px;
      margin-left: 27px;
      color: variables.$coming--soon--powered--by--text-color;
      cursor: default;
      text-transform: capitalize;
    }
    .IconAndLogo {
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: center;
      a {
        display: flex;
        align-items: center;
      }
      .VerticalLine {
        height: 15px;
        width: 3px;
        background: variables.$coming--soon--powered--by--text-color !important;
      }
      .ChannelLogo {
        height: 22px;
        width: auto;
        filter: brightness(0) invert(1);
      }
    }
  }
  .BottomContainer {
    .UrlContainer {
      position: relative;
      padding: 4px;
      width: fit-content;
      display: flex;
      align-items: center;
      text-align: center;
      background: variables.$channel-theme--primary-background-color;
      .UrlText {
        color: variables.$channel-theme--primary-color;
        font-size: 22px;
        line-height: 120%;
        letter-spacing: -0.01em;
        cursor: default;
        text-transform: uppercase;
      }
    }
    .ReleaseContainer {
      text-transform: capitalize;
      position: relative;
      display: flex;
      flex-direction: column;
      padding-left: 87px;
      padding-bottom: 48px;
      color: variables.$coming--soon--powered--by--text-color;
      .ReleaseContent {
        display: flex;
        flex-direction: column;
        cursor: default;
        gap: 16px;
        .ComingSoon {
          font-family: variables.$font--text--bold;
          text-transform: uppercase;
          font-size: 100px;
          letter-spacing: -0.01em;
          line-height: 120%;
        }

        .ReleaseDate {
          display: flex;
          flex-direction: column;
          align-self: flex-start;
          font-family: variables.$font--text--medium;
          font-size: 36px;
          line-height: 24px;
          gap: 16px;
          .Date {
            font-family: variables.$font--text--light;
            font-size: 20px;
            line-height: 12px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $sm) {
  .ComingSoonPage {
    .PoweredByContainer {
      padding: 16px;
      margin-top: 16px;
    }
    .BottomContainer {
      .UrlContainer {
        .UrlText {
          font-size: 18px;
        }
      }
      .ReleaseContainer {
        padding: 0 16px;
        padding-bottom: 32px;
        align-items: flex-start;

        .ReleaseContent {
          .ComingSoon {
            font-size: 40px;
          }
          .ReleaseDate {
            gap: 10px;

            font-size: 24px;
            line-height: 12px;
            .Date {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile) {
  .ComingSoonPage {
    .BottomContainer {
      .ReleaseContainer {
        padding-bottom: 50px;
      }
    }
  }
}
