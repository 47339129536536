@import '../../../../styles/breakpoints.scss';

.DateAndButtonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .RightContainer {
    align-self: end;
  }
}
@media only screen and (max-width: $sm) {
  .DateAndButtonsContainer {
    width: 100%;
    align-items: end;
    .RightContainer {
      margin-left: 16px;
    }
  }
}
