@use '../../styles/variables.scss';
@use '../../styles/breakpoints.scss';
.NotFoundContainer {
  background-color: variables.$content--default-background;
  padding: 1.5rem;
}

@media screen and (max-width: breakpoints.$sm) {
  .NotFoundContainer {
    width: unset;
    margin-top: -55px;
  }
}

.NotFoundSplashImage {
  max-height: 187.5px;
  width: fit-content;
  max-width: calc(100vw - 40px);
}

.NotFoundContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  height: 70vh;

  & > h2,
  p,
  div {
    margin: 0;

    @media screen and (min-width: breakpoints.$xs) {
      text-align: center !important;
      max-width: 800px;
    }
    @media screen and (max-width: breakpoints.$xs) {
      text-align: left !important;
      align-self: stretch;
      width: stretch;
    }
  }

  p {
    font-size: 16px !important;
  }
}
