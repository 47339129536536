@use '../../styles/variables.scss';

.Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  .ToggleContainer {
    position: relative;
    width: 42px;
    min-width: 42px;
    display: inline-block;
    text-align: left;
    .CheckBox {
      display: none;
    }
    .Label {
      display: block;
      overflow: hidden;
      cursor: pointer;

      border-radius: 16px;
      .Inner {
        display: block;
        width: 200%;
        margin-left: -100%;
        transition: margin 0.3s ease-in 0s;
        &:before,
        &:after {
          float: left;
          width: 50%;
          height: 24px;
          padding: 0;
          line-height: 24px;
          color: variables.$toggle--switch--circle--color;
          font-weight: bold;
          box-sizing: border-box;
        }
        &:before {
          content: '';
          padding-left: 10px;
          background-color: variables.$default--dark-background;
          color: variables.$toggle--switch--circle--color;
        }
        &:after {
          content: '';
          padding-right: 10px;
          background-color: variables.$toggle--switch--on--background;
          color: variables.$toggle--switch--circle--color;
          text-align: right;
        }
      }
      .Switch {
        width: 18px;
        margin: 3px;
        background: variables.$toggle--switch--circle--color;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 18px;
        border-radius: 16px;
        transition: all 0.3s ease-in 0s;
      }
    }
  }
  .CheckBox:checked + .Label .Inner {
    margin-left: 0;
  }
  .CheckBox:checked + .Label .Switch {
    right: 0px;
  }
  .TextContent {
    font-family: variables.$font--text--medium;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.16px;
  }
}
