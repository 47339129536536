@use '../../../../styles/colors.scss';
@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.AccountFormFieldsContainer {
  label {
    font-family: variables.$font--text--bold;
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 12px;
  }

  h4 {
    margin: 0;
    padding: 32px 0;
  }
  .FormDescription {
    margin: 0 !important;
    padding: 0 0 30px !important;
    font-size: 16px;
    line-height: 24px;
  }
  .TextField {
    border: none;
    width: 100%;
    border-radius: variables.$component-radius;
    height: 48px;
    padding: 16px 24px;
    background-color: colors.$grey--light--3;
    margin-bottom: 18px;
  }

  .AddressLineContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 16px;
    .AddressFieldContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
  .ContactInformationContainer {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  .AccountDetailsContainer {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .AccountFormFieldsContainer {
    h4 {
      font-family: variables.$font--text--bold;
      font-weight: bold;
      font-size: 24px;
    }
    .FormDescription {
      padding: 12px 0 30px 0;
      font-size: 14px;
      line-height: 18px;
    }
    .AddressLineContainer {
      flex-direction: column;
      gap: 32px;
    }
    .ContactInformationContainer {
      padding-bottom: 32px;
    }
  }
}
