@use '../../../styles/variables.scss';

.TimeFilterContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: variables.$component-radius;
  div {
    width: calc(100% / 3);
  }
  div:not(:last-child) {
    border-right: 2px solid variables.$inner--line--right;
  }
}
