@import '../../../styles/variables.scss';
.RoundIconButton {
  border-radius: 50%;
  &:hover {
    cursor: pointer;
  }
  &:active {
    cursor: grabbing;
  }
}
.Light {
  background: $default--light-background !important;
  stroke: $default--light-color !important;
  &:hover {
    background: $hover--light-background !important;
    stroke: $hover--light-color !important;
  }
}
.Dark {
  background-color: $default--dark-background !important;
  stroke: $default--dark-color !important;
  &:hover {
    background: $hover--dark-background !important;
    stroke: $hover--dark-color !important;
  }
}
.Red {
  background-color: $default--dark-background !important;
  stroke: $default--dark-color !important;
  &:hover {
    background: $brand--primary !important;
    stroke: $brand--primary--text-light !important;
  }
}

.Large {
  width: 136px !important;
  padding: 40px !important;
  height: 136px !important;
}
.Medium {
  padding: 8px !important;
  width: 40px !important;
  height: 40px !important;
}
.Small {
  padding: 8px !important;
  width: 32px !important;
  height: 32px !important;
}
