@import '../../../../../styles/breakpoints.scss';
.MenuItemsContainer {
  display: flex;
  flex-direction: row;
  gap: 40px;
  max-width: 100%;
}

@media screen and (max-width: $mobile) {
  .MenuItemsContainer {
    gap: 16px;
  }
}
