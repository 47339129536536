@use '../../styles/variables.scss';
@use '../../styles/breakpoints.scss';

.BannerAdContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: variables.$section--padding--top-bottom--desktop;
  padding-bottom: variables.$section--padding--top-bottom--desktop;
  overflow: auto;
}
@media screen and (max-width: breakpoints.$sm) {
  .BannerAdContainer {
    padding-top: variables.$section--padding--top-bottom--tablet;
    padding-bottom: variables.$section--padding--top-bottom--tablet;
  }
}
@media screen and (max-width: breakpoints.$mobile) {
  .BannerAdContainer {
    padding-top: variables.$section--padding--top-bottom--mobile;
    padding-bottom: variables.$section--padding--top-bottom--mobile;
  }
}

.White {
  background-color: variables.$content--default-background;
}
.Grey {
  background-color: variables.$secondary--light-background;
}
.LightGrey {
  background-color: rgba(variables.$secondary--light-background, 0.4);
}
.None {
  background-color: none !important;
}

.NoPadding {
  padding-top: 0;
  padding-bottom: 0;
}
