@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.StatsContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 56px;
  width: 100%;
  .EventName {
    color: variables.$default--text-color;
  }
  .TableContainer {
    margin-right: variables.$padding--left-right--desktop;
    border: variables.$ghost--inverted--disabled-border;
    width: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: variables.$component-radius;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .StatsContainer {
    padding-bottom: 32px;
    .TableContainer {
      width: 100%;
      margin-right: variables.$padding--left-right--tablet;
    }
  }
}
@media only screen and (max-width: breakpoints.$mobile) {
  .StatsContainer {
    .TableContainer {
      width: unset;
      margin-right: variables.$padding--left-right--mobile;
    }
  }
}
