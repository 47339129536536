@import '../../../styles/variables.scss';
.SharedContainer {
  display: inline-block;
  background: $white;
  padding: 16px;
  .ChannelContainer {
    height: 24px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .ChannelImageWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      width: 24px;
      img {
        max-height: 24px;
        max-width: 24px;
        width: auto;
        height: auto;
        border-radius: $component-radius;
        object-fit: contain;
      }
    }
    a {
      text-decoration: none;
      color: inherit;
      .ChannelName {
        padding-left: 12px;
        font-family: $font--text--bold;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.005em;
      }
    }
  }
}
.BroadcastThumbnailLink {
  text-decoration: none;
  color: inherit;
  font-family: inherit;
  border-radius: $component-radius;
  text-transform: none;
  &:visited {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
  }
}
