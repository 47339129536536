.StreamPage {
  .LoadingState {
    padding-top: 220px;
    width: 100%;
    min-height: 600px;
    max-height: 800px;
    height: 84vh;
    background: black;
  }
}

.IsViewingStream {
  max-height: 100%;
  height: 100%;
  width: 100%;
}
