@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.HeroContainer {
  background-color: black;
  color: $hero--section--text-color;
  padding: 180px $padding--left-right--desktop 100px;
  box-sizing: border-box;
  width: 100%;
  background-position: center center;
  background-size: cover;
  .ContentContainer {
    margin-top: 107px;
    margin-bottom: 107px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 830px;
    .Title {
      font-size: 80px;
      line-height: 100%;
      font-family: $font--text--bold;
    }
    .Text {
      font-size: 20px;
      line-height: 28px;
    }
  }
}

@media only screen and (max-width: $sm) {
  .HeroContainer {
    padding: 200px $padding--left-right--tablet 120px;
  }
}

@media only screen and (max-width: $xs) {
  .HeroContainer {
    padding: 80px $padding--left-right--mobile;
    .ContentContainer {
      margin-top: 80px;
      margin-bottom: 0px;
      .Title {
        font-size: 40px;
        line-height: 120%;
      }
      .Text {
        font-size: 16px;
      }
    }
  }
}
