@import '../../../../styles/variables.scss';
@import '../../../../styles/breakpoints.scss';

.ChannelImageContainer {
  display: flex;
  border: 4px solid $channel-page--channel-image-border-color;
  box-sizing: border-box;
  padding: 28px;
  width: 220px;
  height: 220px;
  background-color: $primary--default-color;

  .ChannelImage {
    width: 100%;
    height: 100%;
    background-position: center;
  }
  .UploadIcon {
    img {
      text-align: left;
    }
    position: absolute !important;
    left: 168px;
    top: 168px !important;
  }
}
@media screen and (max-width: $sm) {
  .ChannelImageContainer {
    padding: 17px;
    width: 95px;
    height: 95px;
    .UploadIcon {
      left: 48px;
      top: 48px !important;
    }
  }
}
