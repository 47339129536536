@use '../../../styles/variables.scss';

.RoundButton {
  padding: 14px;
  border-radius: 50px;
  width: fit-content;
  height: fit-content;
  border: variables.$ghost--default-border;
  background-color: variables.$ghost--default-background;
  &:hover {
    cursor: pointer;
    background-color: variables.$ghost--hover-background;
  }
  .Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    stroke: variables.$default--text-color;
  }
}
