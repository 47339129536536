@import '../../../../styles/variables.scss';
@import '../../../../styles/breakpoints.scss';

.TeamContainer {
  display: flex;
  align-items: center;
  width: 200px;

  .LogoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 32px;
    max-height: 32px;
    margin-right: 16px;
    min-width: 32px;
    width: 32px;
    height: 32px;
    text-align: center;
    .Empty {
      width: 24px;
      height: 24px;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .TeamName {
    display: flex;
    align-items: center;
    font-family: $font--text--medium;
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (max-width: $sm) {
  .TeamContainer {
    width: 100%;
    .TeamName {
      width: 100%;
    }
  }
}
.Win {
  .TeamName {
    color: $game--schedule--win-color;
  }
}
.Loss {
  .TeamName {
    color: $game--schedule--lose-color;
  }
}
.Equal {
  .TeamName {
    color: $game--schedule--equal-color;
  }
}
.Default {
  .TeamName {
    color: $game--schedule--default-color;
  }
}
