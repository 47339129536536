@import '../../styles/breakpoints.scss';
@import '../../styles/variables.scss';

.LandingPage {
  max-height: 100vh;
}
.LandingPageHero {
  display: flex;
  width: 100%;
}

@media screen and (max-width: $sm) {
  .LandingPageHero {
    flex-direction: column;
  }
}
