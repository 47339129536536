@use '../../../../../../styles/colors.scss';

.DefaultPricesContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;

  .Price {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .Divider {
    color: colors.$black--dark--3;
  }
}
