.AddTeamToClubFormContainer {
  form {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .ButtonContainer {
    display: flex;
    flex-direction: row;
    gap: 16px;
    .SubmitButton {
      margin-top: 0;
    }
  }
}
