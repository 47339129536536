.VideoPlayerDebug {
  position: absolute;
  z-index: 100;
  background: rgba(0, 0, 0, 0.85);
  color: #fff;
  font-size: 0.85em;
  padding: 0;
  dl {
    width: 100%;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.05);
    padding: 0;
    margin: 0;
    dt {
      background: rgba(255, 255, 255, 0.1);
      padding: 0 8px;
      margin: 0 2px 0 0;
      float: left;
      clear: left;
      margin-bottom: 2px;
    }
    dd {
      background: rgba(255, 255, 255, 0.2);
      padding: 0 8px;
      margin: 0;
      float: right;
      text-align: right;
      clear: right;
      margin-bottom: 2px;
    }
    .label {
      background: #000;
      width: 100%;
      font-weight: bold;
      flex-flow: column;
    }
  }
  .handle {
    background: #000;
    border-bottom: 1px solid #777;
  }
}
