@use '../../../../styles/variables.scss';
.UserSection {
  display: flex;
  flex-direction: column;
  color: variables.$default--text-color;
  align-items: center;
  gap: 32px;

  .ImageContainer {
    position: relative;
    width: 130px;
    height: 130px;
    min-width: 130px;
    min-height: 130px;
    background-color: variables.$white;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 100px;
      border: 3px solid variables.$ghost--disabled-background;
    }
    .UploadIcon {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .TextContent {
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: center;
    align-items: center;
    .Name {
      width: 100%;
      font-family: variables.$font--text--bold;
      font-size: 20px;
      line-height: 28px;
    }
    .Email {
      font-family: variables.$font--text--light;
      font-size: 16px;
      line-height: 24px;
    }
    .UserEngagement {
      display: flex;
      flex-direction: row wrap;
      gap: 8px;
      font-family: variables.$font--text--medium;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
