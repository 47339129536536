@use '../../styles/breakpoints.scss';
.InfoTooltipContainer {
  position: relative;
  .Tooltip {
    top: 44px;
    left: -151.5px;
    width: 320px;
    position: absolute;
    z-index: 2;
  }
}

@media only screen and (max-width: breakpoints.$md) {
  .InfoTooltipContainer {
    .Tooltip {
      max-width: 260px;
    }
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .InfoTooltipContainer {
    .Tooltip {
      max-width: unset;
    }
  }
}
