@use '../../../../styles/variables.scss';

.VideoPLayerPreloader {
  height: 875px;
  width: 100%;
  display: flex;
  flex-direction: column;
  .LoaderImage {
    display: block;
    width: 100%;
    height: 875px;
  }
}

@keyframes animate {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.LoaderImage {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: animate;
  animation-timing-function: linear;
  background: #f6f6f6;
  position: relative;
  display: flex;
  vertical-align: center;
  background: variables.$preloader--linear-gradient-2;
  background-size: 1000px 100%;
}
