@use '../../ContentVariables.scss' as contentVar;
@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.StandingdHeader {
  display: grid;
  align-items: center;
  justify-items: center;
  border-bottom: 1px solid contentVar.$standings-standard-divider-color;
  font-family: contentVar.$standings-header-font-family;
  color: contentVar.$standings-header-color;
  gap: contentVar.$standings-column-gap-desktop;
  font-size: contentVar.$standings-header-font-size-desktop;
  height: contentVar.$standings-header-height-desktop;
  line-height: contentVar.$standings-header-line-height-desktop;
  padding: contentVar.$standings-header-padding-desktop;

  *:first-child {
    justify-self: flex-start;
  }
  .Bold {
    color: variables.$default--text-color;
    font-family: variables.$font--text--bold;
  }
}

@media screen and (max-width: breakpoints.$sm) {
  .StandingdHeader {
    gap: contentVar.$standings-column-gap-mobile;
    font-size: contentVar.$standings-header-font-size-mobile;
    height: contentVar.$standings-header-height-mobile;
    line-height: contentVar.$standings-header-line-height-mobile;
    padding: contentVar.$standings-header-padding-mobile;
  }
}
