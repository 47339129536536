.PlayerButton.Airplay {
  position: relative;
  &.connected {
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      background-position: center center;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=);
      background-size: 30px;
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: 38% 26%;
      top: -1px;
      left: 0;
    }
    &:hover {
      &:before {
        opacity: 0.8;
      }
    }
  }
}
