@import '../../../../styles/variables.scss';
@import '../../../../styles/breakpoints.scss';

.Card {
  width: 196px;
  height: 353px;
  box-sizing: border-box;
  background-position: center center;
  background-size: cover;
  border-radius: $component-radius;
  overflow: hidden;

  .FeaturedContent {
    height: 100%;
    width: 100%;
    padding-top: 184px;
    padding-left: 41px;
    padding-right: 41px;
    .LogoContainer {
      width: 114px;
      height: 141px;

      .LogoImage {
        display: block;
        margin: auto;
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
      }
    }
  }
}

@media only screen and (max-width: $mobile) {
  .Card {
    width: 157px;
    height: 282px;
    .FeaturedContent {
      padding: 148px 33px 0px;

      .LogoContainer {
        width: 92px;
        height: 113px;
      }
    }
  }
}
