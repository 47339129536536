@use '../../../../../styles/variables.scss';
@use '../../../../../styles/breakpoints.scss';

.BuyBannerContent {
  display: flex;
  flex-direction: column;
  gap: 34px;
  align-items: flex-start;
  justify-content: center;
  width: 90%;
  .BuyViewContainer {
    width: 100%;
  }
  .BannerHeading {
    font-family: variables.$font--text--bold;
    color: variables.$modal--text--form--title-color;
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
    padding-bottom: 10px;
  }

  .BannersSection {
    padding-bottom: 16px;
  }
}

.SubscriptionSection {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media only screen and (max-width: breakpoints.$sm) and (orientation: portrait) {
  .BuyBannerContent {
    width: 100%;
  }
}

@media only screen and (max-width: breakpoints.$sm) and (orientation: landscape) {
  .BuyBannerContent {
    width: 100%;
  }
}
