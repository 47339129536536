@import '../../../../styles/variables.scss';
@import '../../../../styles/breakpoints.scss';

.IconTitleTextContainer {
  color: $info--section--text-color;
  width: 460px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .IconContainer {
    width: 42px;
    height: 42px;
    .Icon {
      align-self: center;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .Title {
    font-family: $font--text--semi--bold;
    font-size: 20px;
    line-height: 28px;
  }
  .Text {
    font-family: $font--text--light;
    font-style: normal;
    font-size: 20px;
    line-height: 28px;
  }
}
@media only screen and (max-width: $xl) {
  .IconTitleTextContainer {
    width: 410px;
  }
}
@media only screen and (max-width: $lg) {
  .IconTitleTextContainer {
    width: 350px;
  }
}
@media only screen and (max-width: $md) {
  .IconTitleTextContainer {
    width: 338px;
  }
}

@media only screen and (max-width: 1092px) {
  .IconTitleTextContainer {
    width: 100%;
  }
}
@media only screen and (max-width: $sm) {
  .IconTitleTextContainer {
    .Text {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
