@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.Footer {
  font-family: variables.$font--text--light;
  font-size: 14px;
  height: variables.$footer-height;
  max-height: variables.$footer-height;
  min-height: variables.$footer-height;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .FooterMenu {
    display: flex;
    width: 100%;
    flex-direction: row;
  }
  .TopRow {
    justify-content: center;
    padding-bottom: 24px;
  }
  .BottomRow {
    justify-content: center;
  }
  .MenuItemContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 22px;
  }

  .TopRowContainer {
    padding-left: 24px;
    padding-right: 24px;
  }

  .TopRowContainer:not(:last-of-type) {
    border-right: 1px solid variables.$footer--primary--text;
  }

  .MenuItem {
    text-decoration: none;
    color: variables.$footer--primary--text;
    text-align: center;
    a,
    a:visited {
      text-decoration: none;
      color: variables.$footer--primary--text;
    }
    a:hover,
    a:focus {
      color: variables.$footer--highlight--text;
    }
  }
  .DropDownButton {
    padding-right: 14px;
    div {
      &:hover {
        color: variables.$footer--highlight--text !important;
      }
    }
  }
  .CompanyName {
    line-height: 23px;
  }
  .FooterLogoContainer {
    height: 22px;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .Footer {
    padding: 0 variables.$padding--left-right--mobile;
    .MenuItemContainer {
      line-height: 12px !important;
      font-size: 12px !important;
    }
    .MenuItem {
      font-size: 12px;
    }
    .FooterMenu {
      display: flex;
      width: 100%;
      flex-direction: row;
    }
    .TopRow {
      width: 100%;
      padding-bottom: 16px !important;
      flex-wrap: wrap;
      row-gap: 12px;
    }
    .TopRowContainer {
      padding-left: 8px !important;
      padding-right: 8px !important;
    }
    .BottomRow {
      justify-content: center;
      font-size: 12px;
    }
    .DropDownButton {
      padding-left: 14px;
    }
    .CompanyName {
      padding-left: 12px !important;
      line-height: 12px !important;
      padding-top: 5px !important;
    }
  }
}
