@use '../../../../../styles/breakpoints.scss';

.PurchaseList {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  align-items: center;
  .List {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .LoadingContainer {
    height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media only screen and (max-width: 1050px) {
  .PurchaseList {
    .LoadingContainer {
      height: 2000px;
      justify-content: flex-start;
    }
  }
}
