@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.ReadArticleContainer {
  background-color: variables.$breadcrumb--background;
  padding-bottom: 40px;
}
.ReadArticle {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0px variables.$padding--left-right--desktop 0;
  border-radius: variables.$component-radius;
  overflow: hidden;
  margin-bottom: 32px;
}

@media only screen and (max-width: breakpoints.$sm) {
  .ReadArticleContainer {
    padding-bottom: 24px;
    .ReadArticle {
      padding: 0px variables.$padding--left-right--tablet 10px;
    }
  }
}

@media only screen and (max-width: breakpoints.$mobile) {
  .ReadArticleContainer {
    .ReadArticle {
      padding: 0px variables.$padding--left-right--mobile 10px;
      margin-bottom: 24px;
    }
  }
}
