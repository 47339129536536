@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.LineDivider {
  margin: 0px 13px;
}

.SpacedDivider {
  margin-right: 13px;
}

.Container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: $search-result--padding--top-bottom--desktop
    $padding--left-right--desktop;
  align-items: stretch;

  background-color: $search-item--container-background-color;

  transition: background-color $hover--transition-time;
  &:hover {
    background-color: $search-item--container-background-color--hover;
  }

  .ImageChannelContainer {
    display: flex;
    .Image {
      border-radius: $component-radius;
      height: 100px;
      width: 100px;
    }
  }

  .ImageArticleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    .Image {
      border-radius: $component-radius;
      height: 109px;
      width: 193px;
    }
  }

  .ContentContainer {
    min-height: 132px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    flex: 1 1 auto;
    order: 1;
    flex-grow: 1;
    margin: 0px 43px;

    .Published {
      position: static;
      top: 0;
      left: 0;
      padding-bottom: 13px;
      font-size: 12px;
      font-style: $font--text--light;
      color: $search-item--published-text-color;
      line-height: 12px;

      display: flex;
      align-items: center;
    }

    .TitleContainer {
      display: flex;
      flex-direction: row;
      width: 100%;
    }

    .Title a {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-decoration: none;

      font-family: $font--text--bold;
      color: $search-item--title-text-color;
      font-size: 32px;
      line-height: 110%;

      letter-spacing: -0.01em;
      margin-bottom: 13px;
      max-width: 784px;

      &:visited {
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
        cursor: pointer;
      }
      &:focus {
        text-decoration: none;
      }
    }

    .ArticleTitle a {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-decoration: none;

      font-family: $font--text--bold;
      color: $search-item--title-text-color;
      font-size: 32px;
      line-height: 110%;

      letter-spacing: -0.01em;
      margin-bottom: 13px;
      max-width: 784px;

      &:visited {
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
        cursor: pointer;
      }
      &:focus {
        text-decoration: none;
      }
    }

    .Description {
      align-items: flex-end;
      letter-spacing: 0.04em;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      font-family: $font--text--light;
      color: $search-item--description-text-color;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;

      max-width: 784px;
      margin-bottom: 20px;
    }

    .ComingGame {
      cursor: pointer;
      margin-right: 13px;
      text-decoration: underline;
      color: $search-item--category-text-color;
      &:hover {
        font-weight: bold;
      }
    }

    .IsLive {
      cursor: pointer;
      color: $search-item--live-text-color;
      text-decoration: underline;
      &:hover {
        font-weight: bold;
      }
    }

    .BottomFrame {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;

      order: 0;
      flex-grow: 0;

      font-family: $font--text--medium;
      font-size: 12px;
      line-height: 16px;

      align-items: flex-end;
      letter-spacing: 0.04em;
      text-transform: uppercase;

      color: $search-item--category-text-color;

      .EventText {
        text-decoration: underline;
      }
    }
  }
}

@media only screen and (max-width: $mobile) {
  .Container {
    padding: $search-result--padding--top-bottom--mobile
      $padding--left-right--mobile;

    .ImageChannelContainer {
      .Image {
        height: 48px;
        width: 48px;
      }
    }
    .ImageArticleContainer {
      .Image {
        height: 42px;
        width: 63px;
      }
    }
    .TitleContainer {
      justify-content: space-between;
      align-items: center;
    }
    .ContentContainer {
      margin: 0px 16px;
      min-height: 0;
      .Published {
        position: relative;
        font-size: 10px;
        padding-bottom: 0;
      }
      .Title a {
        font-family: $font--text--medium;
        font-size: 14px;
        line-height: 48px;
      }
      .ArticleTitle a {
        -webkit-line-clamp: 2;
        font-family: $font--text--medium;
        padding-top: 5px;
        margin-bottom: 0;
        line-height: 18px;
        font-size: 14px;
        max-width: 220px;
      }
      .Description {
        display: none;
      }
      .BottomFrame {
        display: none;
      }
      .EventTeams {
        display: none;
      }
    }
  }
}
