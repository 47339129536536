@use '../../../../../styles/variables.scss';
@use '../../../../../styles/breakpoints.scss';

.CoachList {
  display: flex;
  flex-direction: column;
  .ListTitle {
    color: variables.$default--text-color;
    padding: 0 16px;
    font-family: variables.$font--text--bold;
    font-size: 20px;
    line-height: 28px;
  }
  .List {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: breakpoints.$sm) {
  .CoachList {
    .ListTitle {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
