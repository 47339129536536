@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';
.TitleThumbnailSection {
  .LeftPadding {
    padding: variables.$section--padding--top-bottom--desktop;
  }
  .PreloaderTitle {
    padding-left: variables.$padding--left-right--desktop;
    padding-bottom: 32px;
  }
  .ThumbnailSlider {
    display: flex;
    flex-direction: row;
    .Content {
      display: flex;
      flex-direction: row;
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .TitleThumbnailSection {
    .PreloaderTitle {
      padding-left: variables.$padding--left-right--tablet;
    }
  }
}

@media only screen and (max-width: breakpoints.$mobile) {
  .TitleThumbnailSection {
    .LeftPadding {
      padding: variables.$section--padding--top-bottom--mobile;
    }
    .PreloaderTitle {
      padding-left: variables.$padding--left-right--mobile;
      padding-bottom: 12px;
    }
  }
}
