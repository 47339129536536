@use '../../../../../styles/variables.scss';
@use '../../../../../styles/breakpoints.scss';
.TeamContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 140px;
  text-align: left;
  img {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }
  .Name {
    font-family: variables.$font--text--medium;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    font-style: normal;
    word-break: break-word;
  }
  .DefaultTeamBadgeContainer {
    width: 40px;
    height: 40px;
  }
}
.HomeTeam {
  flex-direction: row-reverse;
  text-align: right;
}
@media only screen and (max-width: breakpoints.$md) {
  .TeamContainer {
    width: 100%;
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .TeamContainer {
    width: 120px;
    flex-direction: column;
    .Name {
      text-align: center;
    }
  }
  .HomeTeam {
    flex-direction: column;
  }
}
