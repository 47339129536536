@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';
.AboutStream {
  display: flex;
  flex-direction: column;
  padding: 40px variables.$padding--left-right--desktop;
  background-color: variables.$viewer--about--section--background;
  min-height: 100%;
  .Wrapper {
    display: flex;
    width: 100%;
    max-width: 100%;
    flex-direction: column;
    gap: 32px;
    height: fit-content;
    .Description {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: fit-content;
      .ContentContainer {
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-width: 100%;
        .Title {
          font-size: 32px;
          line-height: 110%;
          letter-spacing: -0.01em;
          font-family: variables.$font--text--bold;
          text-align: left;
        }
        .Text {
          width: 100%;
          text-align: left;
          overflow-wrap: anywhere;
        }
      }
    }
    .NativeAd {
      padding-bottom: 0;
      padding-top: 0;
      max-width: 1200px;
      width: 100%;
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .AboutStream {
    padding: 40px variables.$padding--left-right--tablet;
    .Wrapper {
      .Description {
        .ContentContainer {
          .Title {
            font-size: 20px;
            line-height: 26px;
          }
          .Text {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: breakpoints.$mobile) {
  .AboutStream {
    padding: 20px variables.$padding--left-right--mobile;
    .Wrapper {
      flex-direction: column;
    }
  }
}

.IsViewingStream {
  padding: 24px 16px;
  padding-bottom: 40px;
  background: variables.$content--default-background;
  .Wrapper {
    .Description {
      .Text {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.14px;
      }
    }
  }
}
