@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.CompetitionsContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .GameScheduleContainer {
    background: variables.$content--default-background;
    padding: 32px;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .CompetitionsContainer {
    .GameScheduleContainer {
      padding: 0;
    }
  }
}
