@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.DropdownField {
  background: $field--section-background;
  position: sticky;
  z-index: 101;
  padding: 20px 0;
}
.IsThemedPage {
  top: 96px;
}
.IsNotThemedPage {
  top: 80px;
}
.IsLGBPage {
  top: 96px;
}
.TopTag {
  position: relative;
  top: -182px;
}
.VideoListLoader {
  height: 800px;
}
.NoContentExistContainer {
  display: flex;
  min-height: 50vh;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
  .TextContainer {
    font-size: 24px;
    line-height: 32px;
    font-family: $font--text--bold;
    color: $videos--no--content--text-color;
    text-align: center;
    width: 400px;
  }
}
@media only screen and (max-width: $sm) {
  .IsThemedPage {
    top: 104px;
  }
  .IsNotThemedPage {
    top: 64px;
  }
  .IsLGBPage {
    top: 64px;
  }
  .VideoListLoader {
    height: 1000px;
  }
  .NoContentExistContainer {
    margin-top: 20px;
    margin-bottom: 20px;
    .TextContainer {
      font-size: 16px;
      line-height: 24px;
      width: 343px;
    }
  }
}
