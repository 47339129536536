@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.ModalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 56px 110px 64px;
  background: variables.$modal--default--background-color;
  color: variables.$modal--default--color;
  width: 608px;
  gap: 32px;
  height: 694px;
  .SuccessAnimationContainer {
    height: 108px;
    width: 108px;
  }
  .TextContent {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
    text-align: center;
    .Title {
      text-align: center;
      font-family: variables.$font--text--bold;
      font-size: 32px;
      font-style: normal;
      line-height: 110%;
      letter-spacing: -0.32px;
    }
    .SubText {
      font-family: variables.$font--text--light;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: breakpoints.$sm) {
  .ModalContent {
    padding: 103px 48px;
    width: 100%;
    height: 100%;
    gap: 16px;
    justify-content: flex-start;
    .TextContent {
      .Title {
        font-size: 20px;
        line-height: 26px;
      }
      .SubText {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.14px;
      }
    }
    .ButtonContainer {
      margin-top: 16px;
      width: 100%;
      button {
        width: 100%;
      }
    }
  }
}
