@import '../../../styles/variables.scss';

.TextAreaContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .TextArea {
    margin-top: 12px;
    box-sizing: border-box;
    border: none;
    border-radius: $component-radius;
    background: $form--text--area--background-color;
    padding: 16px 24px;
    font-size: 16px;
    line-height: 24px;
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    font-family: $font--text--light;
    ::placeholder {
      color: $form--text--area-color;
    }
    &:focus-visible {
      outline: none !important;
    }
    &:hover {
      background: $form--text--area--hover--background-color;
    }
    &:disabled {
      &:hover {
        background: $form--text--area--background-color;
      }
    }
  }
}
.Error {
  .TextArea {
    border: $form--error--border;
  }
}
.ErrorText {
  color: $form--error--text-color;
  font-size: 12px;
  line-height: 12px;
  margin-top: 12px;
}
