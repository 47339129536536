@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.HeadingContainer {
  h4 {
    padding: 0 51px;
    margin: 0;
  }
  .Subheading {
    padding: 0 51px;
    font-size: 16px;
    line-height: 24px;
    margin: 10px 0;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .HeadingContainer {
    h4 {
      padding: 0 16px;
      font-family: variables.$font--text--semi--bold;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
    }
    .Subheading {
      padding: 0 16px;
    }
  }
}
