@use '../../../../../styles/breakpoints.scss';
.DateContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  .DateTimeContainer {
    display: flex;
    flex-direction: row;
    gap: 12px;
    width: 100%;
    div {
      width: 100%;
    }
  }
}
@media only screen and (max-width: breakpoints.$md) {
  .DateContainer {
    flex-direction: column;
    gap: 16px;
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .DateContainer {
    width: 100%;
    .DateTimeContainer {
      div {
        width: 100%;
      }
    }
  }
}
