@import '../../../../styles/variables.scss';
@import '../../../../styles/breakpoints.scss';

.OverviewFormFieldsContainer {
  h4 {
    margin: 0;
    padding: 32px 0;
  }
  .InformationContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
@media only screen and (max-width: $sm) {
  .OverviewFormFieldsContainer {
    h4 {
      font-family: $font--text--bold;
      font-weight: bold;
      font-size: 24px;
    }
  }
}
