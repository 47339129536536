@use '../../../../styles/breakpoints.scss';

.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  gap: 40px;
  width: 100%;
  .ToggleContainer {
    max-width: 600px;
    width: 100%;
  }
  .Preloaders {
    display: flex;
    flex-direction: column;
    gap: 1px;
    max-width: 988px;
    width: 100%;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .Container {
    .ToggleContainer {
      max-width: 400px;
    }
  }
}
