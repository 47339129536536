@use '../../../styles/breakpoints.scss';
.Image {
  max-height: 200px;
  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .Image {
    max-height: 400px;
  }
}
