@use '../styles/breakpoints.scss';

.TopPadding {
  height: 80px;
  min-height: 80px;
  width: 100%;
}
.TopPaddingThemed {
  height: 96px;
  min-height: 96px;
  width: 100%;
}

@media screen and (max-width: breakpoints.$sm) {
  .TopPadding {
    height: 64px;
    min-height: 64px;
    width: 100%;
  }
  .TopPaddingThemed {
    height: 56px;
    min-height: 56px;
  }
  .TopPaddingThemedMobile {
    height: 104px;
    min-height: 104px;
  }
  .TopPaddingThemedMobileWithoutMenuLinks {
    height: 64px;
    min-height: 64px;
  }
}

@media screen and (max-width: breakpoints.$mobile) {
  .TopPaddingThemedMobile {
    height: 104px;
    min-height: 104px;
  }
  .TopPaddingThemedMobileWithoutMenuLinks {
    height: 64px;
    min-height: 64px;
  }
}
