@use '../../../../styles/colors.scss';
@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.ButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .FirstButton {
    margin-bottom: 12px;
  }
  .PlannedContainer {
    display: flex;
    justify-content: row;
    color: variables.$planned--color;
  }
}

.NoBroadcastLabel {
  font-size: 14px;
  line-height: 14px;
  box-sizing: border-box;
  padding: 8px 10px;
  background: colors.$grey--light--2;
  border-radius: variables.$component-radius;
  color: colors.$black--dark--2;
}

@media only screen and (max-width: breakpoints.$sm) {
  .ButtonContainer {
    flex-direction: row;
    gap: 16px;
    .FirstButton {
      margin-bottom: 0;
    }
  }
}

@media only screen and (max-width: breakpoints.$xs) {
  .ButtonContainer {
    gap: 10px;
    flex-direction: column;
  }
}
