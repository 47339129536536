.AllPurchases {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 32px;
  flex: 1;
}
@media only screen and (max-width: 1050px) {
  .AllPurchases {
    padding: 24px 16px;
  }
}
