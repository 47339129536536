@use '../../../../../styles/variables.scss';
@use '../../../../../styles/breakpoints.scss';
.InputContainer {
  padding: 40px variables.$padding--left-right--desktop;
  background: variables.$chat--input--container--background-color;
}
@media only screen and (max-width: breakpoints.$sm) {
  .InputContainer {
    padding: 24px variables.$padding--left-right--tablet;
  }
}
@media only screen and (max-width: breakpoints.$mobile) {
  .InputContainer {
    padding: 16px variables.$padding--left-right--mobile;
  }
}

.IsViewingStream {
  padding: 16px 16px 32px 16px;
}
