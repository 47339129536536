@use '../../../../../styles/variables.scss';
@use '../../../../../styles/colors.scss';

.NoBroadcastLabel {
  padding: 8px 16px;
  background-color: colors.$grey--light--2;
  border: variables.$component-radius;
  color: colors.$black--dark--3;
  font-family: variables.$font--text--medium;
  font-size: 12px;
  line-height: 12px;
  height: fit-content;
}
