@use '../../../../../styles/breakpoints.scss';

.Form {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  min-height: 478px;
  .AccessDropdown {
    max-width: 345px;
  }
  .Buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }
}
@media only screen and (max-width: breakpoints.$md) {
  .Form {
    min-height: 350px;
    gap: 16px;
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .Form {
    min-height: unset;
    .AccessDropdown {
      max-width: unset;
    }
  }
}
