@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.NewsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $secondary--light-background;

  .FirstArticle {
    padding-bottom: 24px;
  }

  .OtherArticles {
    padding-bottom: 24px;
  }
}
.NewsListContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1920px;
}

@media only screen and (max-width: $sm) {
  .NewsContainer {
    .FirstArticle {
      padding-bottom: 40px;
    }
    .OtherArticles {
      padding-bottom: 1px;
    }
  }
}

@media only screen and (max-width: $mobile) {
  .NewsContainer {
    display: inline;
    .FirstArticle {
      padding-bottom: 40px;
    }
    .OtherArticles {
      padding-bottom: 1px;
    }
  }
}
