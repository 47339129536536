@use '../../../../../styles/colors.scss';
@use '../../../../../styles/variables.scss';
@use '../../../../../styles/breakpoints.scss';

.LoadingContainer {
  width: fit-content;
}

@media only screen and (min-width: breakpoints.$sm) {
  .ModalWrapper {
    height: 824px;
    align-items: center;

    & > div:first-child {
      justify-content: center;
    }
  }

  .ModalContentWrapper {
    padding: 0 110px;
  }
}

.ModalContent {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.TopContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.ContentTitle {
  font-family: variables.$font--text--bold;
  color: colors.$black--dark--2;
  font-size: 32px;
  line-height: 1.1;
  display: flex;
  align-items: center;
  gap: 16px;
}

.ContentDescription {
  font-size: 16px;
  line-height: 1.5;
}

.SecondaryTitle {
  font-family: variables.$font--text--bold;
  color: colors.$black--dark--2;
  font-size: 24px;
  line-height: 28px;
}

.TicketPriceContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .TicketPriceTitle {
    font-family: variables.$font--text--bold;
    color: colors.$black--dark--2;
    font-size: 14px;
    line-height: 18px;
  }

  .TicketPriceDescription {
    font-size: 12px;
    line-height: 1;
  }
}

.GameContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 16px;
}

.GameTitle {
  font-family: variables.$font--text--bold;
  color: colors.$black--dark--2;
  font-size: 14px;
  line-height: 18px;
}

.GameDescription {
  font-size: 12px;
  line-height: 1;
  color: colors.$black--dark--3;
}

.ButtonsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > button {
    width: 100%;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .ModalWrapper {
    & > div:first-child > div:first-child {
      padding: 40px 16px 16px;
    }
  }
  .LoadingContainer {
    width: 100vw;
  }

  .ModalContentWrapper {
    grid-column: span 2;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    padding: 12px 16px;
  }

  .ModalContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 30px;
  }

  .TopContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .ContentTitle {
    font-family: variables.$font--text--bold;
    color: colors.$black--dark--2;
    font-size: 20px;
    line-height: 1.25;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .ContentDescription {
    font-size: 16px;
    line-height: 1.5;
  }

  .SecondaryTitle {
    font-family: variables.$font--text--bold;
    color: colors.$black--dark--2;
    font-size: 20px;
    line-height: 1.25;
  }

  .TicketPriceContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .TicketPriceTitle {
      font-family: variables.$font--text--bold;
      color: colors.$black--dark--2;
      font-size: 16px;
      line-height: 1.5;
    }

    .TicketPriceDescription {
      font-size: 14px;
      line-height: 1;
    }
  }

  .GameContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .GameTitle {
    font-family: variables.$font--text--bold;
    color: colors.$black--dark--2;
    font-size: 14px;
    line-height: 18px;
  }

  .GameDescription {
    font-size: 12px;
    line-height: 1;
    color: colors.$black--dark--3;
  }

  .ButtonsContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 0;

    & > div {
      width: 100%;
    }
  }
}
