@use '../../../../../styles/variables.scss';
@use '../../../../../styles/breakpoints.scss';
.TableHeader {
  display: flex;
  flex-direction: row;
  display: flex;
  width: fit-content;
  padding: 16px 16px 8px 16px;
  gap: 32px;
  border-radius: variables.$component-radius variables.$component-radius 0 0;
  background-color: variables.$secondary--light-background;
  box-shadow: variables.$inner--line--bottom--1;
  color: variables.$default--text-color;
}

.ThemedHeader {
  background-color: variables.$channel-theme--primary-background-color-inverted;
  color: variables.$channel-theme--primary-color-inverted;
}
@media only screen and (max-width: breakpoints.$sm) {
  .TableHeader {
    gap: 6px;
    width: 100%;
  }
}
