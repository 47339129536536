@import '../../../../../styles/variables.scss';
@import '../../../../../styles/breakpoints.scss';

.ChannelTypeLabelContainer {
  text-transform: capitalize;
  background-color: $secondary--default-background;
  color: $secondary--default-color;
  border-radius: $component-radius;
  padding: 8px 10px;
  width: fit-content;
  height: fit-content;
  margin-left: 24px;
  font-size: 14px;
}
@media screen and (max-width: $sm) {
  .ChannelTypeLabelContainer {
    margin-left: 16px;
    font-size: 12px;
  }
}
