@use '../../styles/variables.scss';
@use '../../styles/breakpoints.scss';
.SearchResult {
  min-height: 80vh;
  background-color: variables.$secondary--light-background;
  color: variables.$default--text-color;
  text-transform: none;
  .ResultsHeaderContainer {
    padding: 32px variables.$padding--left-right--desktop;
    .SearchTextContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      padding-bottom: 16px !important;
      h2 {
        margin: 0;
        font-size: 24px;
        font-style: normal;
        line-height: 32px;
      }
      a {
        word-break: keep-all;
        min-width: fit-content;
        font-size: 14px;
        line-height: 14px;
      }
    }
  }
}
.SearchMessage {
  padding: variables.$search-result--padding--top-bottom--desktop
    variables.$padding--left-right--desktop;
}

.SearchResultVideoSection {
  background-color: variables.$search-result--video-background;
}

.SearchResultChannelSection {
  background-color: variables.$search-result--channel-background;
}

.VideoContainer {
  padding: variables.$search-result--padding--top-bottom--desktop
    variables.$padding--left-right--desktop;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  grid-auto-rows: 266;
}
.ChannelContainer {
  padding: 32px variables.$padding--left-right--desktop;
}

.CountryFilterButtonsContainer {
  *:first-child {
    margin-right: 8px;
  }
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
}

@media only screen and (max-width: breakpoints.$sm) {
  .SearchResult {
    .SearchMessage {
      padding: variables.$search-result--padding--top-bottom--tablet
        variables.$padding--left-right--tablet;
    }
    .VideoContainer {
      padding: variables.$search-result--padding--top-bottom--tablet
        variables.$padding--left-right--tablet;
    }
    .ChannelContainer {
      padding: variables.$search-result--padding--top-bottom--tablet
        variables.$padding--left-right--tablet;
    }
    .ResultsHeaderContainer {
      padding: variables.$search-result--padding--top-bottom--tablet 0
        variables.$search-result--padding--top-bottom--tablet
        variables.$padding--left-right--tablet;
      .SearchTextContainer {
        padding-right: variables.$padding--left-right--tablet;
      }
    }
  }
}

@media only screen and (max-width: breakpoints.$mobile) {
  .SearchMessage {
    padding: variables.$search-result--padding--top-bottom--mobile
      variables.$padding--left-right--mobile;
  }
  .VideoContainer {
    padding: variables.$search-result--padding--top-bottom--mobile
      variables.$padding--left-right--mobile;
  }
  .ChannelContainer {
    padding: variables.$search-result--padding--top-bottom--mobile
      variables.$padding--left-right--mobile;
  }
  .ResultsHeaderContainer {
    padding: variables.$search-result--padding--top-bottom--mobile 0
      variables.$search-result--padding--top-bottom--mobile
      variables.$padding--left-right--mobile;
    h2 {
      padding-right: variables.$padding--left-right--mobile;
    }
  }
}
