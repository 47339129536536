@import '../../../../../styles/variables.scss';
@import '../../../../../styles/breakpoints.scss';

.MenuItem {
  a {
    font-size: 20px;
    line-height: 28px;
    text-decoration: none;
    overflow: hidden;
    font-family: $font--text--medium;
    white-space: nowrap;

    &:hover {
      text-decoration: none;
    }
  }
}
.DarkBackground {
  a {
    color: $menu-item--dark--default-color;
    &:hover {
      color: $menu-item--dark--hover-color;
    }
  }
  &.Active {
    a {
      color: $menu-item--dark--active-color !important;
    }
  }
}

.LightBackground {
  a {
    color: $menu-item--light--default-color;
    &:hover {
      color: $menu-item--light--hover-color;
    }
  }
  &.Active {
    a {
      color: $menu-item--light--active-color;
    }
  }
}

.ThemedBackground {
  a {
    color: $channel-theme--primary-color-inverted;
    &:hover {
      opacity: 0.65;
    }
    &.Active {
      color: $channel-theme--primary-color-inverted;
    }
  }
}
@media screen and (max-width: $sm) {
  .MenuItem {
    a {
      font-size: 14px;
      line-height: 14px;
    }
  }
  .ThemedBackground {
    a {
      font-size: 14px;
      line-height: 14px;
      font-family: $font--text--light;
    }
  }
}
