@use '../../../styles/breakpoints.scss';
@use '../../../styles/variables.scss';

.Container {
  width: 100%;
  a img {
    border-radius: variables.$component-radius;
    max-width: 1222px;
    max-height: 300px;
    object-fit: cover;
    width: 100%;
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .Container a img {
    height: 100%;
  }
}
