@use '../../../styles/variables.scss';

.CategoryFilterContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  border-radius: variables.$component-radius;
  background-color: variables.$secondary--light-background;
  div {
    width: calc(100% / 3);
  }
  div:not(:last-child) {
    border-right: 2px solid variables.$inner--line--right;
  }
}
