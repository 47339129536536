.ChatLoaderWrapper {
  display: flex;
  justify-content: center;
  .ChatLoader {
    position: relative;
    overflow: hidden;
    width: fit-content;
    .LoaderDots {
      position: absolute;
      top: -50px;
      left: -28px;
    }
  }
}
