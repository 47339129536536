@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.PromotionOverlayContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: variables.$channel-page--hero--image--overlay--background;
  text-transform: none;
  .Content {
    max-width: 466px;
    max-height: 184px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    .Title {
      margin: 0;
      text-align: center;
      color: variables.$secondary--default-color;
    }
    .Text {
      font-family: variables.$font--text--medium;
      color: variables.$channel-page--hero--image--overlay--secondary-color;
      font-size: 24px;
      font-style: normal;
      text-align: center;
      line-height: 32px;
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .PromotionOverlayContainer {
    .Content {
      max-width: 279px;
      max-height: 94px;
      gap: 8px;
      .Title {
        font-family: variables.$font--text--bold;
        font-size: 20px;
        line-height: 26px;
      }
      .Text {
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
}
