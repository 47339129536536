@use '../../../styles/colors.scss';
@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.ArticleThumbnailSliderError {
  display: flex;
  align-items: center;
  justify-content: center;
  color: colors.$solid-red;
}
.ArticleSliderContainer {
  padding-top: 40px;
  padding-bottom: 40px;
}
.StrongHeading {
  padding-left: variables.$padding--left-right--desktop;
  padding-bottom: 32px;
}
.Label {
  padding-left: variables.$padding--left-right--desktop;
  padding-bottom: 32px;
  font-family: variables.$font--text--medium !important;
}
.Content {
  display: flex;
  flex-direction: row;
}

.Preloader {
  padding-left: variables.$padding--left-right--desktop;
}
@media only screen and (max-width: breakpoints.$sm) {
  .ArticleSliderContainer {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .StrongHeading {
    padding-left: variables.$padding--left-right--tablet;
  }
  .Label {
    padding-left: variables.$padding--left-right--tablet;
    font-family: variables.$font--text--medium;
  }
  .Preloader {
    padding-left: variables.$padding--left-right--tablet;
  }
}

@media only screen and (max-width: breakpoints.$mobile) {
  .StrongHeading {
    padding-left: variables.$padding--left-right--mobile;
    padding-bottom: 12px;
  }
  .Label {
    padding-left: variables.$padding--left-right--mobile;
    padding-bottom: 12px;
  }
  .Preloader {
    padding-left: variables.$padding--left-right--mobile;
  }
}
