@import '../../../../../styles/variables.scss';
@import '../../../../../styles/breakpoints.scss';

.HeadingContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 0 0 51px;
  .TopContainer {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    .Heading {
      padding: 0;
      margin: 0;
    }
  }
}

@media only screen and (max-width: $sm) {
  .HeadingContainer {
    padding: 0;
    .TopContainer {
      .Heading {
        font-family: $font--text--bold;
        font-size: 20px;
        line-height: 26px;
      }
    }
  }
}
