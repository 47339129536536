@use '../../../../styles/variables.scss';
@use '../../../../styles/colors.scss';

.GameScheduleCard {
  padding: 24px 24px;
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  border: 1px solid colors.$grey--light--2;
  border-radius: 2px;
  background: variables.$list--item--background;
}
.Item {
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
  font-family: variables.$font--text--medium;
  gap: 8px;

  .Name {
    font-family: variables.$font--text--semi--bold;
    font-size: 16px;
    padding-bottom: 8px;
  }
  .TotalGames {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    line-height: 18px;
    color: colors.$black--dark--3;
    .Label {
      color: colors.$black--dark--2;
      width: 100px;
    }
  }
  .Date {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    line-height: 18px;
    color: colors.$black--dark--3;
    .Label {
      color: colors.$black--dark--2;
      width: 100px;
    }
  }
  .Status {
    padding: 3px 9px;
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 14px;
    background-color: colors.$grey--light--2;
  }
}
.RemoveButton {
  cursor: pointer;
  width: 48px;
}

.StatusActive {
  color: variables.$status--active--color;
}
.StatusUpcoming {
  color: variables.$status--upcoming--color;
}
