@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.NotThemed {
  text-transform: none;
}

.StartContainer {
  background-color: variables.$secondary--light-background;
  border-top: inset 1px solid transparent;
  .ThemedPastGames {
    background-color: variables.$secondary--light-background;
    color: variables.$channel-page--slider--not--themed-color;
  }

  .NotThemedPastGames {
    color: variables.$channel-page--slider--not--themed-color;
    background-color: transparent;
  }
  .UpcomingStreamsContainer {
    min-height: 350px;
    text-transform: none;
    padding: variables.$section--padding--top-bottom--desktop 0;
    background-color: variables.$secondary--light-background;
    color: variables.$channel-page--slider--not--themed-color;
  }
  .LatestVideosContainer {
    min-height: 350px;
    text-transform: none;
    padding: variables.$section--padding--top-bottom--desktop 0;
  }
  .HighlightsContainer {
    min-height: 350px;
    text-transform: none;
    padding: variables.$section--padding--top-bottom--desktop 0;
    background-color: variables.$secondary--light-background;
    color: variables.$channel-page--slider--not--themed-color;
  }
  .PromotedCategoriesContainer {
    text-transform: none;
    background-color: variables.$secondary--light-background;
    color: variables.$channel-page--slider--not--themed-color;
  }
  .NewsContainer {
    text-transform: none;
    background-color: variables.$secondary--light-background;
    color: variables.$channel-page--slider--not--themed-color;
  }
  padding-bottom: variables.$section--padding--top-bottom--desktop;
}

@media only screen and (max-width: breakpoints.$mobile) {
  .StartContainer {
    .UpcomingStreamsContainer {
      min-height: 250px;
      padding: variables.$section--padding--top-bottom--mobile 0;
    }
    .LatestVideosContainer {
      min-height: 250px;
      padding: variables.$section--padding--top-bottom--mobile 0;
    }
    .HighlightsContainer {
      min-height: 250px;
      padding: variables.$section--padding--top-bottom--mobile 0;
    }
    padding-bottom: variables.$section--padding--top-bottom--mobile;
  }
}
