.HomePage {
  display: flex;
  flex-direction: column;
  flex: 1;
  .TopContainer {
    position: relative;
    height: 333px;
    width: 100%;
    .BackgroundImg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .UserContainer {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      height: 333px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
