@use '../../../../../styles/variables.scss';
@use '../../../../../styles/breakpoints.scss';

.InfoContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 430px;
  max-width: 430px;
  padding: 16px;
  right: 0;
  top: 42px;
  border-radius: 8px;
  z-index: 100;
  background-color: variables.$white;
  color: variables.$default--text-color;
  box-shadow: variables.$elevation-1;
  .TopContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 2px;
    .Icon {
      svg {
        path {
          stroke: variables.$default--text-color !important;
        }
      }
    }
    .Title {
      font-size: 16px;
      font-family: variables.$font--text--medium;
      line-height: 24px;
    }
  }
  .GeoblockList {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .GeoblockDirection {
      font-family: variables.$font--text--light;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .GeoblockedCountries {
    display: flex;
    flex-direction: row;
    gap: 4px;
    .CountryLabel {
      background-color: variables.$secondary--default-background;
      border-radius: 2px;
      color: variables.$white;
      display: flex;
      width: fit-content;
      padding: 8px 10px;
      font-family: variables.$font--text--medium;
      align-items: center;
      font-size: 14px;
      line-height: 14px;
    }
  }
}

@media only screen and (max-width: breakpoints.$xs) {
  .InfoContainer {
    width: 90vw;
    right: -48px;
  }
}
