@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}
.NewsThumbnailLink {
  text-decoration: none;
  color: $brand--primary--text-dark;
  &:hover,
  &:after,
  &:active {
    text-decoration: none;
  }
}

.NewsThumbnailContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px;
  border-radius: $component-radius;
  background: $white;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.NewsTextContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 41px 65px 71px;
}

.ImageContainer {
  height: 100%;
  width: 100%;
  max-height: 550px;
  overflow: hidden;
  .NewsImage {
    padding-top: 66.64%;
    height: 0;
    width: 100%;
    background-size: cover !important;
    background-position: center center;
    background-repeat: no-repeat !important;
  }
}

.NoImageContainer {
  height: 50vh;
  width: 100%;
  .NewsImage {
    background-origin: content-box;
    height: 100%;
    width: 100%;
    padding: 16px;
    background-size: 100% 100%;
    background-repeat: no-repeat !important;
  }
}

@media only screen and (max-width: $mobile) {
  .NewsTextContainer {
    padding: 16px;
  }
  .NoImageContainer {
    height: 30vh;
    width: 100%;
  }
}
