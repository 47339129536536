@import '../../../../styles/variables.scss';

.GameContent {
  display: flex;
  flex-direction: column;
  .ImageWrapper {
    overflow: hidden;
    position: relative;
    padding-bottom: 56.25%;
    background: $thumbnail--background--hover-color;
    border-radius: $component-radius;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      object-position: center;
      border-radius: $component-radius;
    }
  }
  .ThumbnailLabelContainer {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    border-radius: $component-radius;
  }
  .TimeLabelContainer {
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: $component-radius;
  }
  .RootDomainLabelContainer {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 10;
  }
  .BottomContainer {
    position: relative;
    box-sizing: border-box;
    padding-left: 2px;
    padding-right: 2px;
    display: flex;
    width: 100%;
    flex-direction: column;

    .Title {
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: $font--text--bold !important;
      &:hover {
        + .TooltipWrapper {
          display: block;
        }
      }
    }
    .TooltipWrapper {
      bottom: 50px;
      z-index: 100;
      width: 100%;
      position: absolute;
      display: none;
    }
  }
}
.WithBottomRow {
  &.GameContent {
    &:hover {
      .ImageWrapper {
        img {
          opacity: 0.42;
          transition: $hover--transition-time;
        }
      }
    }
  }
  &.xSmall {
    width: 280px;
    height: 100%;
    .BottomContainer {
      padding-top: 8px;
      .Title {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
  &.small {
    width: 318px;
    height: 100%;
    overflow-y: hidden;
    .BottomContainer {
      padding-top: 8px;
      .Title {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
  &.medium {
    width: 390px;
    height: 100%;
    .BottomContainer {
      padding-top: 8px;
      .Title {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  &.large {
    width: 494px;
    height: 100%;
    .BottomContainer {
      padding-top: 12px;
      .Title {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
  &.xLarge {
    padding: 0px;
    width: 1004px;
    height: 643px;
    .BottomContainer {
      padding-top: 12px;
      .Title {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
}
.small {
  width: 318px;
  height: 179px;
}
.medium {
  width: 390px;
  height: 220px;
}
.large {
  width: 494px;
  height: 272px;
}
.xLarge {
  width: 1004px;
  height: 565px;
}
.keepRatio {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  &.GameContent {
    .ImageWrapper {
      overflow: hidden;
      background: unset !important;
    }
  }
  .BottomContainer {
    position: absolute;
    bottom: -40px;
  }
}
