@use '../../styles/breakpoints.scss';

@media only screen and (max-width: breakpoints.$sm) {
  .MobileMenuModal {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .MobileMenuModal {
    padding-left: 0 !important;
    padding-right: 0 !important;
    overflow: unset;
    & > div:first-child {
      height: 100%;
    }
  }
}
