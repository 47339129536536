@import '../../../../../../styles/variables.scss';
@import '../../../../../../styles/breakpoints.scss';

.ScoreboardTeamBadge {
  background-color: $white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: 148px;
  height: 100%;

  .ScoreboardTeamBadgeLogo {
    width: 64px;
  }

  .ScoreboardTeamBadgeName {
    font-family: $font--text--bold;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
  }
}

@media only screen and (max-width: $mobile) {
  .ScoreboardTeamBadge {
    height: 100%;
    width: 131px;
  }
}
