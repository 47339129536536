#VideoPlayer {
  position: relative;
  width: 100%;
  display: block;
  background: #000000;
  padding-bottom: 56.25%;
  height: 0;
  display: block;
  &.fullscreen {
    padding-bottom: 0;
    height: 100%;
  }
  video {
    position: absolute;
    top: 0;
    left: 0;
  }
}
