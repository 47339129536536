@use '../multiStageTournamentVariables.scss' as mst;
@use '../../../styles/variables.scss';

.FilterList {
  width: 100%;
  border: 2px solid mst.$filter--header--background;
  border-bottom-left-radius: variables.$component-radius;
  border-bottom-right-radius: variables.$component-radius;
}
.ContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
