@use '../../styles/colors.scss';
@use '../../styles/variables.scss';

$standings-scrollbar-offset: 18px;
$standings-background-color: colors.$grey--light--3;
$standings-standard-divider-color: colors.$grey--light--1;
$standings-accentuated-divider-color: colors.$black--dark--2;
$standings-header-font-family: variables.$font--text--light;
$standings-header-color: colors.$black--dark--3;
$standings-form-win-background-color: variables.$active--default-background;
$standings-form-loss-background-color: #ec1e24;
$standings-form-draw-background-color: #fbbc04;
$standings-form-unknown-background-color: colors.$grey--light--1;
$standings-form-text-color: colors.$white;
$standings-expanded-toggle-hover-background-color: colors.$grey--light--2;
$standings-row-hover-background-color: colors.$grey--light--2;
$standings-row-second-background-color: colors.$grey--light--3;
$standings-container-padding-desktop: variables.$section--padding--top-bottom--desktop
  0 variables.$section--padding--top-bottom--desktop
  variables.$padding--left-right--desktop;
$standings-container-gap-desktop: 24px;
$standings-table-width-desktop: 1220px;
$standings-column-gap-desktop: 32px;
$standings-header-height-desktop: 48px;
$standings-header-line-height-desktop: 24px;
$standings-header-font-size-desktop: 16px;
$standings-header-padding-desktop: 12px 20px 8px;
$standings-row-height-desktop: 72px;
$standings-row-line-height-desktop: 28px;
$standings-row-font-size-desktop: 20px;
$standings-row-padding-desktop: 0px 20px;
$standings-team-position-size-desktop: 32px;
$standings-team-position-margin-desktop: 32px;
$standings-team-image-size-desktop: 32px;
$standings-team-image-gap-desktop: 16px;
$standings-form-bead-size-desktop: 32px;
$standings-form-bead-gap-desktop: 10px;
$standings--team--name--witdh--desktop: 350px;
$standings--team--name--witdh--mobile: 140px;

$standings-container-padding-mobile: variables.$section--padding--top-bottom--mobile
  0 variables.$section--padding--top-bottom--mobile
  variables.$padding--left-right--mobile;

$standings-container-gap-mobile: 16px;
$standings-table-width-mobile: 705px;
$standings-column-gap-mobile: 8px;
$standings-header-height-mobile: 36px;
$standings-header-line-height-mobile: 12px;
$standings-header-font-size-mobile: 12px;
$standings-header-padding-mobile: 16px 4px 8px;
$standings-row-height-mobile: 52px;
$standings-row-line-height-mobile: 24px;
$standings-row-font-size-mobile: 16px;
$standings-row-padding-mobile: 0 4px;
$standings-team-position-size-mobile: 24px;
$standings-team-position-margin-mobile: 8px;
$standings-team-image-size-mobile: 24px;
$standings-team-image-gap-mobile: 8px;
$standings-form-bead-size-mobile: 24px;
$standings-form-bead-gap-mobile: 8px;

$standings-expanded-toggle-font-size-mobile: 16px;
$standings-expanded-toggle-line-height-mobile: 24px;
$standings-expanded-toggle-height-mobile: 56px;
$standings-expanded-toggle-gap-mobile: 16px;
$standings-expanded-toggle-arrow-size-mobile: 16px;
