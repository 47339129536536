.TimeDisplay {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  pointer-events: none;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #fff;
  font-size: 0.85em;
  font-family: 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  opacity: 1;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
  font-weight: 500;
  margin-left: 8px;
  .liveDisplay {
    // width: 50px;
    margin-right: 8px;
  }
  .currentTime {
    // width: 40px;
  }
  .ads {
  }
  .duration {
    // width: 40px;
    &:before {
      content: '/';
      margin-right: 5px;
      margin-left: 5px;
    }
  }
}
