@use '../../../../../styles/variables.scss';
@use '../../../../../styles/colors.scss';
@use '../../../../../styles/breakpoints.scss';

.BrandingHeader {
  h4 {
    margin: 0;
    padding: 32px 0;
  }
  display: flex;
  flex-direction: column;
  gap: 40px;
  .Container {
    display: flex;
    flex-direction: column;
    color: colors.$black--dark--2;

    .SubText {
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
    }
  }
}
.DropdownFieldWrapper {
  width: 448px;
}
.SubmitButton {
  margin-top: 24px;
  margin-bottom: 24px;
}
.ReleaseDateContainer {
  display: flex;
  flex-direction: column;
  align-content: center;
  gap: 8px;
  margin-top: 12px;
  .Label {
    font-family: variables.$font--text--medium;
  }
  .Date {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    width: fit-content;
    height: fit-content;
    padding: 4px 12px;
    border-radius: 8px;
    background: variables.$grey--light--3;
    .Icon {
      width: 16px;
      height: 16px;
      stroke: variables.$default--text-color;
    }
    &:hover {
      cursor: pointer;
      background-color: variables.$grey--light--1;
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .BrandingHeader {
    h4 {
      font-size: 24px !important;
      font-family: variables.$font--text--bold !important;
    }
    gap: 32px;
    .Container {
      gap: 12px;
      .SubText {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .DropdownFieldWrapper {
    width: unset;
  }
}
