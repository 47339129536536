@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.NotSignedInContent {
  min-height: calc(100vh - 78px);
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;

  .InnerContainer {
    height: 650px;
    min-height: 650px;
    position: relative;
    overflow: hidden;
    background-color: black;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .Content {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 150px 110px 95px 110px;
      align-items: flex-start;
      gap: 24px;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      .TopContent {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .Text {
          color: variables.$white;
          font-family: variables.$font--text--bold;
          font-size: 24px;
          font-style: normal;
          line-height: 28px;
        }
      }
      .ButtonContainer {
        display: flex;
        flex-direction: row;
        gap: 16px;
      }
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .NotSignedInContent {
    height: unset;
    .InnerContainer {
      min-height: calc(100vh - 59px);
      height: calc(100vh - 78px);
      .Content {
        padding: 48px 32px;
        gap: 16px;
        .TopContent {
          .Text {
            font-size: 20px;
            line-height: 26px;
          }
        }
        .ButtonContainer {
          gap: 16px;
        }
      }
    }
  }
}
@media only screen and (max-width: breakpoints.$mobile) {
  .NotSignedInContent {
    .InnerContainer {
      .Content {
        padding: 16px;
        .ButtonContainer {
          flex-direction: column;
          width: 100%;
        }
      }
    }
  }
}
