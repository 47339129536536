@import '../../.././../styles/variables.scss';
@import '../../.././../styles/breakpoints.scss';

.LabelAndDateContainer {
  display: flex;
  flex-direction: column;
  .Label,
  .Date,
  .RootDomainLabel {
    color: $game--schedule--default-color;
    font-size: 16px;
    line-height: 24px;
  }
  .Label {
    font-family: $font--text--bold;
    margin-bottom: 4px;
    a {
      text-decoration: none;
      color: $game--schedule--default-color;
      font-family: $font--text--bold;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .RootDomainLabel {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    svg {
      height: 16px;
      width: 16px;
    }
  }
  .Date {
    font-family: $font--text--light;
  }
}
@media only screen and (max-width: $sm) {
  .LabelAndDateContainer {
    .Label,
    .RootDomainLabel,
    .Date {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
