@use '../../../styles/variables.scss';
@use '../../../styles/colors.scss';
@use '../../../styles/breakpoints.scss';

.FrontPageHero {
  box-sizing: border-box;
  height: 918px;
  width: 100%;
  background-position: center center;
  padding-top: variables.$padding--left-right--desktop;
  margin-bottom: 200px;
  background-size: cover;
  .SloganText {
    margin-top: 204px;
    display: flex;
    flex-direction: column;
    padding-left: variables.$padding--left-right--desktop;
    font-family: variables.$font--text--bold;
    font-size: 80px;
    line-height: 100%;
    margin-bottom: 80px;
    .FirstRow {
      color: colors.$white;
    }
    .SecondRow {
      color: colors.$solid-red;
    }
  }
  .Heading {
    padding-left: variables.$padding--left-right--desktop;
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .FrontPageHero {
    height: 700px;
    padding-top: variables.$padding--left-right--tablet;
    .SloganText {
      padding-left: variables.$padding--left-right--tablet;
    }
  }
  .Heading {
    padding-left: variables.$padding--left-right--tablet;
  }
}
@media only screen and (max-width: breakpoints.$mobile) {
  .FrontPageHero {
    padding-top: variables.$padding--left-right--mobile;
    .SloganText {
      padding-left: variables.$padding--left-right--mobile;
    }
    height: 509px;
    .SloganText {
      font-size: 40px;
      line-height: 48px;
      margin-top: 127px;
    }
  }
  .Heading {
    padding-left: variables.$padding--left-right--mobile;
  }
}
