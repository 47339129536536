@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.AccountHeaderContainer {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 102;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: variables.$white;
  padding: 16px 32px;
  height: 78px;
  border-bottom: 1px solid variables.$secondary--light-background;
  .Logo {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .HamburgerMenuIcon {
    svg {
      height: 22px !important;
      width: 22px !important;
      stroke: variables.$brand--primary--text-dark !important;
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .AccountHeaderContainer {
    height: 59px;
    padding: variables.$navigation--header--top-bottom--padding-mobile
      variables.$padding--left-right--tablet;
    .Logo {
      svg {
        width: 126px;
        height: 26px;
      }
    }
  }
}
@media only screen and (max-width: breakpoints.$mobile) {
  .AccountHeaderContainer {
    padding: variables.$navigation--header--top-bottom--padding-mobile
      variables.$padding--left-right--mobile !important;
  }
}
