@import '../../../styles/variables.scss';

.TagContainer {
  display: flex;
  color: $tag-color;
  font-size: 12px;
  line-height: 12px;
  .TagIcon {
    width: 12px;
    height: 12px;
    stroke: $tag-color !important;
    margin-right: 4px;
  }
}
