@use '../../../../../styles/variables.scss';
@use '../../../../../styles/breakpoints.scss';

.HeaderCell {
  font-family: variables.$font--text--light;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
}
.PlayerHeaderCell {
  width: 308px;
  div {
    justify-content: flex-start;
  }
}
.TeamHeaderCell {
  width: 66px;
  div {
    justify-content: flex-start;
  }
}
.RankHeaderCell {
  width: 38px;
  text-align: center;
}
.DefaultHeaderCell {
  width: 150px;
  text-align: center;
}

@media only screen and (max-width: breakpoints.$sm) {
  .PlayerHeaderCell {
    width: 100%;
  }
  .TeamHeaderCell {
    width: 100px;
    text-align: center;
    div {
      justify-content: center;
    }
    &.Selected {
      width: 110px;
    }
  }
  .RankHeaderCell {
    width: 30px;
  }
  .DefaultHeaderCell {
    width: 80px;
    &.Selected {
      width: 100px;
    }
  }
}
@media only screen and (max-width: breakpoints.$mobile) {
  .PlayerHeaderCell {
    width: 150px;
  }
  .TeamHeaderCell {
    width: 60px;
    &.Selected {
      width: 80px;
    }
  }
  .DefaultHeaderCell {
    width: 60px;
    &.Selected {
      width: 90px;
    }
  }
}
