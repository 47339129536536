@import '../../../styles/variables.scss';
@import '../../../styles/colors.scss';

.FilterItemButton {
  white-space: nowrap;
  display: inline-block;
  align-items: center;
  font-weight: 500;
  font-family: $font--text--medium;
  border-radius: 32px;
  background: $default--light-background !important;
  color: $black--dark--3 !important;
  cursor: pointer;
  transition: all 0.15s;

  &:hover {
    background-color: $black--dark--3 !important;
    color: $white !important;
  }
  &:active {
    background-color: $default--dark-background !important;
    color: $default--dark-color !important;
  }
}

.Selected {
  background-color: $default--dark-background !important;
  color: $default--dark-color !important;
}

.FilterItemButton--large {
  font-size: 20px;
  line-height: 20px;
  padding: 16px;
}

.FilterItemButton--medium {
  font-size: 16px;
  line-height: 16px;
  padding: 10px;
}

.FilterItemButton--small {
  font-size: 14px;
  line-height: 14px;
  padding: 10px;
}
