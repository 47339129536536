@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

*::before,
::after {
  box-sizing: content-box;
}

.ChannelItemContainer {
  display: flex;
  gap: 16px;
  background-color: variables.$secondary--default-color;
  text-decoration: none;
  color: inherit;
  height: fit-content;
  padding: 24px 48px;
  &:hover {
    background-color: variables.$channel-item--hover-color;
  }
  .LinkContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
    width: 100%;
    text-decoration: none;
    img {
      width: 48px;
      height: 48px;
    }
    .NoLogo {
      width: 48px;
      height: 48px;
    }
    .ListView {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 8px;
      width: 100%;
      .Name {
        font-family: variables.$font--text--medium;
        font-size: 24px;

        line-height: 32px;
        margin: 0;
      }
      .ListViewDescription {
        font-size: 16px;
        color: variables.$menu-item--dark--default-color;
        line-height: 1;
      }
    }
  }
}

@media screen and (max-width: breakpoints.$sm) {
  .ChannelItemContainer {
    padding: 16px 32px;
    .LinkContainer {
      .ListView {
        .Name {
          font-size: 16px;
          line-height: 24px; /* 150% */
        }
      }
    }
  }
}
