@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.FormModalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: variables.$modal--default--background-color;
  justify-content: center;

  padding: 56px 110px 64px;
  width: 608px;
  gap: 16px;
  height: 694px;
  max-height: 80vh;
  border-radius: variables.$component-radius;
  color: variables.$modal--default--color;
  overflow: auto;
  .InsideContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 32px;
    height: 100%;
    width: 100%;
    .ModalHeader {
      color: variables.$modal--header--color;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 110%;
    }
    .InfoContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .HeaderContainer {
        display: flex;
        flex-direction: column;
        gap: 6px;
        .PreHeader {
          font-family: variables.$font--text--medium;
          font-size: 14px;
        }
        h4 {
          margin: 0;
          font-family: variables.$font--text--bold;
          width: 100%;
          font-size: 32px;
          line-height: 110%;
          padding: 0;
        }
      }
      .Information {
        font-family: variables.$font--text--light;
        font-size: 16px;
      }
    }
  }
}
@media screen and (max-height: 760px) {
  .FormModalContainer {
    .InsideContainer {
      justify-content: unset;
    }
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .FormModalContainer {
    padding: 103px 48px;
    width: 100%;
    height: 100%;
    max-height: 100%;
    justify-content: flex-start;
    .InsideContainer {
      justify-content: flex-start;
      height: unset;
      padding: 48px 0;
      .ModalHeader {
        font-size: 14px;
        line-height: 110%;
      }
      .InfoContainer {
        .PreHeader {
          font-family: variables.$font--text--medium;
          font-size: 14px;
        }
        .HeaderContainer {
          .h4 {
            font-size: 24px;
            line-height: 28px;
          }
        }
        .Information {
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.01em;
        }
      }
    }
  }
}
