@import '../../../../styles/variables.scss';
@import '../../../../styles/breakpoints.scss';

.FiltersContainer {
  padding-top: 40px;
  display: flex;
  flex-direction: row;
  gap: 24px;
  max-width: 920px;
  .Filter {
    width: 100%;
  }
}
@media only screen and (max-width: $sm) {
  .FiltersContainer {
    flex-direction: column;
  }
}
