@use '../../../../../styles/colors.scss';
@import '../../../../../styles/variables.scss';
@import '../../../../../styles/breakpoints.scss';

.TeamItem {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 24px 51px;
  background: $list--item--background;
  gap: 16px;
  box-shadow: $inner--line--bottom--1;
  &:hover {
    background: $list--item--background-hover;
    cursor: pointer;
  }
  .TeamName {
    font-family: $font--text--bold;
    font-size: 20px;
    line-height: 28px;
    width: $team--list--team--name--width;
    color: colors.$black--dark--2;
  }
  .OngoingCompetitions {
    width: $team--list--ongoing--competitions--width;
    color: colors.$black--dark--3;
    font-family: $font--text--medium;
    font-size: 16px;
    line-height: 24px;
  }
  .ImportButton {
    width: $team--list--ongoing--competitions--width;
  }
  .Status {
    font-family: $font--text--medium;
    font-size: 16px;
    line-height: 24px;
    width: $team--list--status--width;
    min-width: $team--list--status--width;
  }
  .Active {
    color: $status--active--color;
  }
  .NotActive {
    color: $status--not--active--color;
  }
  .Disabled {
    color: $status--disabled--color;
  }
  .Icon {
    height: 32px;
    width: $team--list--icon--width;
  }
}

@media only screen and (max-width: $sm) {
  .TeamItem {
    padding: 24px;
    .TeamName {
      font-family: $font--text--medium;
      font-size: 14px;
      line-height: 18px;
      width: $team--list--team--name--width-mobile;
    }
    .OngoingCompetitions {
      font-size: 14px;
      line-height: 18px;
    }
    .Status {
      width: $team--list--status--width-mobile;
      min-width: $team--list--status--width-mobile;
      font-size: 14px;
      line-height: 18px;
    }
  }
}
