@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.ErrorContainer {
  min-height: 600px;
  max-height: 800px;
  height: 84vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: variables.$default--text-color;
  .ErrorIcon {
    height: 233px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  h2 {
    text-align: center;
  }
  .SubText {
    font-size: 16px;
    line-height: 24px;
  }
  .ButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 32px;
    gap: 16px;
    .BackButton {
      svg {
        path {
          stroke: variables.$white;
        }
      }
    }
  }
}
@media screen and (max-width: breakpoints.$sm) {
  .ErrorContainer {
    height: 440px;
    .ErrorIcon {
      height: 150px;
      margin-bottom: 16px;
    }
    .SubText {
      font-size: 14px;
      line-height: 14px;
    }
  }
}
@media screen and (max-width: breakpoints.$mobile) {
  .ErrorContainer {
    text-align: left;

    h2 {
      width: 100%;
      text-align: left;
    }
    .SubText {
      text-align: left;
      width: 100%;
    }
    .ButtonContainer {
      justify-content: left;

      flex-direction: column;
      width: 100%;
      .BackButton {
        width: 100%;
      }
      .ReportButton {
        width: 100%;
      }
    }
  }
}
