@use '../../../../../styles/variables.scss';
@use '../../../../../styles/breakpoints.scss';

.PurchaseTagLine {
  padding-top: 32px;
  padding-bottom: 32px;
  text-align: center;
}
.BulletPoints {
  padding-bottom: 32px;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
}
.ExpirationDate {
  font-size: 20px;
  line-height: 28px;
  font-family: variables.$font--text--bold;
  text-align: center;
  padding-bottom: 12px;
}
.HelpText {
  font-size: 16px;
  line-height: 24px;
  font-family: variables.$font--text--bold;
  text-align: center;
  padding-bottom: 12px;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: breakpoints.$sm) {
  .PurchaseTagLine {
    padding-top: 24px;
    padding-bottom: 24px;
    font-size: 14px;
    line-height: 18px;
  }
  .BulletPoints {
    font-size: 14px;
    line-height: 18px;
  }
  .ExpirationDate {
    font-size: 14px;
    line-height: 18px;
    font-family: variables.$font--text--bold;
    text-align: center;
    padding-bottom: 12px;
  }
  .HelpText {
    font-size: 14px;
    line-height: 14px;
  }
}
