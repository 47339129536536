@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.NavigationHeaderPreloader {
  .LoaderImage {
    display: block;
    width: 100%;
    height: variables.$navigation--header--desktop-height;
  }
}

@keyframes animate {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.LoaderImage {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: animate;
  animation-timing-function: linear;
  background: variables.$white;
  position: relative;
  display: flex;
  vertical-align: center;
  background: variables.$preloader--linear-gradient-2;
  background-size: 1000px 100%;
}
@media only screen and (max-width: breakpoints.$sm) {
  .NavigationHeaderPreloader {
    height: variables.$navigation--header--mobile-height;
    .LoaderImage {
      display: block;
      width: 100%;
      height: variables.$navigation--header--mobile-height;
    }
  }
}
