@import '../../../styles/variables.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.IconTextButton {
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  justify-content: center;
  font-family: $font--text--medium;
  border-radius: $component-radius;
  cursor: pointer;
  .Icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .label {
    display: flex;
    align-items: center;
  }
}

.IsFluid {
  width: auto;
}
.IsRounded {
  border-radius: 8px;
}
.Primary {
  background: $primary--default-background;
  color: $primary--default-color;
  .Icon {
    stroke: $primary--default-color !important;
  }
  &:hover {
    background: $primary--hover-background;
  }
  &:disabled {
    background: $primary--disabled-background;
  }
}
.Secondary {
  background: $secondary--default-background;
  color: $secondary--default-color;
  .Icon {
    stroke: $secondary--default-color !important;
  }
  &:hover {
    background: $secondary--hover-background;
  }
  &:disabled {
    background: $secondary--disabled-background;
  }
}
.Ghost {
  background: $ghost--default-background;
  color: $ghost-color;
  border: $ghost--default-border;
  .Icon {
    stroke: $ghost-color !important;
  }
  &:hover {
    background: $ghost--hover-background;
  }
  &:disabled {
    background: $ghost--disabled-background;
  }
}
.GhostInverted {
  border: $ghost--inverted--default-border;
  background: $ghost--inverted--default-background;
  color: $ghost--inverted--default-color;
  .Icon {
    stroke: $ghost--inverted--default-color !important;
  }
  &:hover {
    background: $ghost--inverted--hover-background;
    color: $ghost--inverted--hover-color;
    .Icon {
      stroke: $ghost--inverted--hover-color !important;
    }
  }
  &:disabled {
    border: $ghost--inverted--disabled-border;
    background: $ghost--inverted--disabled-background;
    color: $ghost--inverted--disabled-color;
  }
}
.ThemedPrimary {
  background: $channel-theme--primary-background-color-inverted;
  color: $channel-theme--primary-color-inverted;
  border: solid 2px $channel-theme--primary-color-inverted;
  stroke: $channel-theme--primary-color-inverted;
  &:hover {
    background: $channel-theme--primary-color-inverted;
    color: $channel-theme--primary-background-color-inverted;
  }
  &:disabled {
    background: $primary--disabled-background;
  }
}
.ThemedInverted {
  background: $channel-theme--primary-background-color;
  stroke: $channel-theme--primary-color;
  color: $channel-theme--primary-color;
  border: solid 2px $channel-theme--primary-color;
  &:hover {
    background: $channel-theme--primary-color;
    stroke: $channel-theme--primary-background-color;
    color: $channel-theme--primary-background-color;
  }
  &:disabled {
    background: $primary--disabled-background;
  }
}

.CTA {
  background: $channel-theme--cta--background-color;
  color: $channel-theme--cta-color;
  .Icon {
    stroke: $channel-theme--cta-color !important;
  }
  &:hover {
    opacity: 0.6;
  }
  &:disabled {
    opacity: 0.5;
  }
}
.IsDisabled {
  opacity: 0.7;
  &:hover {
    opacity: 0.7;
    cursor: default;
  }
}
.Large {
  padding: 21px 32px;
  .Icon {
    height: 20px;
    width: 20px;
  }
  .Label {
    font-size: 14px;
    line-height: 14px;
  }
}

.WithoutLabel {
  padding-left: 14px !important;
  padding-right: 14px !important;
}
.Medium {
  padding: 14px 32px;
  .Icon {
    height: 20px;
    width: 20px;
  }
  .Label {
    font-size: 14px;
    line-height: 14px;
  }
}
.Small {
  padding: 8px 16px;
  .Icon {
    height: 12px;
    width: 12px;
  }
  .Label {
    font-size: 12px;
    line-height: 12px;
  }
}
