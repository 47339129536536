@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.GameCardContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: variables.$component-radius;
  overflow: hidden;
  background-color: variables.$game--schedule--default--background-color;
  box-shadow: variables.$inner--line--bottom--1;
  max-width: 1120px;
  .InnerContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    .LinksButtonsContainer {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
.Primary {
  background-color: variables.$game--card--primary--background-color;
}
.Secondary {
  background-color: variables.$game--card--secondary--background-color;
}

@media only screen and (max-width: breakpoints.$md) {
  .GameCardContainer {
    flex-direction: column;
    .InnerContainer {
      flex-direction: column;
      gap: 12px;
      align-items: center;
      padding: 16px;
      .LinksButtonsContainer {
        width: 100%;
        display: flex;
        gap: 12px;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .GameCardContainer {
    .InnerContainer {
      padding: 8px 12px;
    }
  }
}
