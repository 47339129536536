@use '../../../../styles/breakpoints.scss';
.Subscription {
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
  .List {
    display: flex;
    flex-direction: row;
    gap: 24px;
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .Subscription {
    padding: 24px 16px;
    .List {
      flex-direction: column;
      gap: 12px;
    }
  }
}
