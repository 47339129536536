@use '../../../styles/variables.scss';

.AlertMessage {
  padding: 14px 16px;
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  gap: 12px;
  border-radius: variables.$component-radius;
  .TextContent {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-top: 2px;
    .Title {
      font-size: 16px;
    }
    .Text {
      font-size: 14px;
    }
  }
}

.warning {
  background: #fff3e5;
  // color: #663c00;
}
.info {
  background: #e5f5fd;
}
.success {
  background: #edf7ed;
}
.error {
  background: #fcedec;
}
.general {
  background: variables.$secondary--light-background;
}
