@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';
.CreditCard {
  display: flex;
  width: 100%;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  row-gap: 32px;
  flex-wrap: wrap;
  background-color: variables.$secondary--light-background;
  border-radius: variables.$component-radius;
  border: variables.$ghost--default-border;
  .InformationContainer {
    display: flex;
    flex-direction: row;
    gap: 32px;
    align-items: center;
    .Icon {
      min-width: 56px;
      min-height: 28px;
    }
    .TextInfo {
      display: flex;
      flex-direction: row;
      gap: 32px;
      .CardNumberText,
      .CardHolder,
      .ExpirationDate {
        font-family: variables.$font--text--light;
        font-size: 16px;
        line-height: 16px;
      }
    }
  }
}

@media only screen and (max-width: breakpoints.$mobile) {
  .CreditCard {
    gap: 16px;
    padding: 24px 16px;
    .InformationContainer {
      width: 100%;
      align-items: flex-start;

      .TextInfo {
        width: 100%;
        flex-direction: column;
        align-items: end;
        gap: 20px;
        text-align: right;
      }
    }
  }
}
