.FullscreenButton {
  color: #fff;
  width: 44px;
  height: 34px;
  align-items: center;
  justify-content: center;
  .FullscreenIcon {
    // font-size: 2.4em !important;
  }
  &:hover {
    .FullscreenIcon {
      // font-size: 2.7rem !important;
    }
  }
}
