@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.SportSection {
  padding: $section--padding--top-bottom--desktop 0;
}

@media only screen and (max-width: $mobile) {
  .SportSection {
    padding: $section--padding--top-bottom--mobile 0;
  }
}
