@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.ChannelListItemPreloader {
  display: flex;
  flex-direction: row;
  background-color: variables.$preloader--background;
  width: 100%;
  align-items: center;
  gap: 24px;
  height: 108px;
  box-shadow: inset 0px -3px 0px variables.$white;

  .IconLoaderImage {
    display: block;
    margin-left: 48px;
    width: 48px;
    height: 48px;
  }
  .TextLoaderImage {
    display: block;
    width: 300px;
    height: 24px;
  }
}

@keyframes animate-text {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes animate-icon {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.IconLoaderImage {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: animate-icon;
  animation-timing-function: linear;
  background: variables.$white;
  position: relative;
  display: flex;
  vertical-align: center;
  background: variables.$preloader--linear-gradient-2;
  background-size: 1000px 100%;
}

.TextLoaderImage {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: animate-text;
  animation-timing-function: linear;
  background: variables.$white;
  position: relative;
  display: flex;
  vertical-align: center;
  background: variables.$preloader--linear-gradient-2;
  background-size: 700px 100%;
}

@media only screen and (max-width: breakpoints.$sm) {
  .ChannelListItemPreloader {
    width: 100%;
    height: 64px;
    .TextLoaderImage {
      width: 200px;
      height: 20px;
    }
  }
}
