@use '../../../../styles/breakpoints.scss';

.TeamsContainer {
  display: flex;
  flex-direction: column;
  padding: 32px 0;
  padding-bottom: 56px;
  gap: 48px;
}

@media only screen and (max-width: breakpoints.$sm) {
  .TeamsContainer {
    gap: 32px;
    padding-bottom: 48px;
  }
}
