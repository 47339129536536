@use '../../../styles/breakpoints.scss';

.Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .FieldContainer {
    display: flex;
    flex-direction: row;
    gap: 24px;
    width: 100%;
    .TextInput {
      width: 100%;
      input {
        width: unset;
        height: 48px;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  .AlwaysColumns {
    flex-direction: column;
  }
  .ToggleSwitchContainer {
    width: 100%;
    padding-left: 16px;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .Container {
    .FieldContainer {
      gap: 16px;
      flex-direction: column;
    }
  }
}
