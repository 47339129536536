@use '../../../../../../styles/variables.scss';
@use '../../../../../../styles/breakpoints.scss';

.ReporterMessageContainer {
  gap: 8px;
  display: flex;
  padding: 16px 0 8px;
  align-items: flex-end;
  max-width: variables.$message--bubble--max-width;
  .ReporterNameAndTextBox {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .ReporterName {
      font-family: variables.$font--text--light;
      font-size: 12px;
      line-height: 12px;
      margin-left: 8px;
      color: variables.$chat--message--user--name-color;
    }
    .ReporterBoxContainer {
      display: flex;
      position: relative;
      justify-content: flex-end;
      padding-right: 7px;
      .ReporterMessageText {
        display: flex;
        flex-direction: column;
        border-radius: variables.$component-radius;
        padding: 8px;
        font-family: variables.$font--text--light;
        font-size: 16px;
        line-height: 24px;
        min-width: 100px;
        background: variables.$reporter--message--box-color;
        .TimeStamp {
          color: variables.$chat--message--user--name-color;
          padding-top: 4px;
          line-height: 16px;
          font-size: 12px;
        }
      }
      .Triangle {
        position: absolute;
        bottom: 0;
        right: 0;
        left: right;
        border-left: 0px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 15px solid variables.$reporter--message--box-color;
      }
    }
  }
  .ReporterImage {
    height: 64px;
    width: 64px;
    object-fit: contain;
    align-self: flex-end;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .ReporterMessageContainer {
    .ReporterNameAndTextBox {
      .ReporterName {
        font-size: 10px;
        line-height: 10px;
      }
      .ReporterBoxContainer {
        .ReporterMessageText {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
  .ReporterImage {
    height: 48px;
    width: 48px;
  }
}
