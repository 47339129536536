@use '../../../styles/variables.scss';
.Container {
  display: flex;
  flex-direction: row;
  width: fit-content;
  gap: 8px;
  height: fit-content;
  padding: 14px;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  border: variables.$filter--pill--button-border;
  font-family: variables.$font--text--bold;
  font-size: 14px;
  line-height: 22px;
  background: variables.$filter--item--secondary--background--color;
  &:hover {
    cursor: pointer;
    background: variables.$filter--item--secondary--hover--background--color;
  }
}
