@use '../../../../styles/breakpoints.scss';
.TeamVsTeamContainer {
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 0 32px;
}

@media screen and (max-width: breakpoints.$md) {
  .TeamVsTeamContainer {
    padding: unset;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
  }
}
