@use '../../styles/variables.scss';

.Toggle {
  display: flex;
  flex-direction: row;
  .FirstButton {
    flex-grow: 1;
    max-width: 300px;
  }
  .SecondButton {
    max-width: 300px;
    flex-grow: 1;
  }
}
