@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';
.StatusMessageContainer {
  animation-duration: 1s;
  animation-name: slidein;
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: row;
  bottom: 48px;
  gap: 10px;
  padding: 24px;
  border-radius: variables.$component-radius;
  width: fit-content;
  border: variables.$status--message--border;
  left: variables.$padding--left-right--desktop;
  margin-right: variables.$padding--left-right--desktop;
  box-shadow: variables.$elevation-1;
  background-color: variables.$status--message--background;
  color: variables.$default--text-color;
  text-transform: none;
  z-index: 10;
  .CrossIcon {
    &:hover {
      cursor: pointer;
    }
    svg {
      stroke: variables.$status--message--cross--icon--color;
    }
  }
  @keyframes slidein {
    from {
      transform: translate3d(0, 100%, 0);
      visibility: visible;
    }

    to {
      transform: translate3d(0, 0, 0);
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .StatusMessageContainer {
    left: variables.$padding--left-right--tablet;
    margin-right: variables.$padding--left-right--tablet;
    bottom: 32px;
  }
}
@media only screen and (max-width: breakpoints.$mobile) {
  .StatusMessageContainer {
    left: variables.$padding--left-right--mobile;
    margin-right: variables.$padding--left-right--mobile;
    bottom: 16px;
  }
}
