@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';
.ErrorContainer {
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-top: 10%;
  justify-content: center;
  height: 100%;
  max-width: 100%;
  padding: 1.5rem;
  h2 {
    font-size: 60px;
    text-align: center;
    margin: 10px;
    font-family: variables.$font--text--bold;
  }
  p {
    margin: 10px;
    text-align: center;
    font-size: 20px;
  }
}
@media screen and (max-width: breakpoints.$sm) {
  .ErrorContainer {
    h2 {
      font-size: 40px !important;
    }
  }
}
