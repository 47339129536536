@use '../../styles/breakpoints.scss';

.PageContainer {
  padding-top: 78px;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  .Sidebar {
    position: fixed;
    bottom: 0;
    top: 78px;
  }
  .Content {
    display: flex;
    flex-direction: column;
    padding-left: 220px;
    width: 100%;
    min-height: 90vh;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .PageContainer {
    padding-top: 59px;
    .Content {
      padding-left: unset;
    }
  }
}
