@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.Container {
  padding: 24px 56px 56px 56px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  background-color: variables.$content--default-background;
  border-radius: variables.$component-radius;
  color: variables.$default--text-color;
  .BreadcrumbContainer {
    width: 100%;
    > div {
      padding-left: 0;
    }
  }
  .Content {
    width: 100%;
    overflow: hidden !important;
    word-wrap: break-word;
  }
}
@media only screen and (max-width: breakpoints.$sm) {
  .Container {
    padding: 16px 32px 48px 32px;
  }
}

@media only screen and (max-width: breakpoints.$mobile) {
  .Container {
    padding: 16px 16px 32px 16px;
  }
}
