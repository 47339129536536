@import '../../../../../styles/colors.scss';
@import '../../../../../styles/breakpoints.scss';

.GreenCheckmark {
  width: 40px;
  height: 40px;
  min-width: 40px;
  background-color: $active--green--2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 17px;
  }
}

@media only screen and (max-width: $mobile) {
  .GreenCheckmark {
    width: 24px;
    height: 24px;
    min-width: 24px;

    svg {
      width: 10px;
    }
  }
}
