@import '../../../styles/breakpoints.scss';
@import '../../../styles/variables.scss';

.SectionContainer {
  background-color: $our--teams--section--background-color;
  width: 100%;
  padding-bottom: 120px;

  .TitleArrowContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 120px $padding--left-right--desktop 0px;

    .SectionTitle {
      font-family: $font--text--bold;
      font-size: 32px;
      line-height: 35px;
    }
  }
  .ContentSliderContainer {
    padding-top: 32px;
  }
}

@media only screen and (max-width: $sm) {
  .SectionContainer {
    padding-bottom: 80px;

    .TitleArrowContainer {
      padding: 72px $padding--left-right--tablet 0px;
    }
  }
}
@media only screen and (max-width: $mobile) {
  .SectionContainer {
    padding-bottom: 72px;
    .TitleArrowContainer {
      padding: 72px $padding--left-right--mobile 0px;
    }
    .ContentSliderContainer {
      padding-top: 22px;
    }
  }
}
