@use '../../../../../styles/variables.scss';
@use '../../../../../styles/breakpoints.scss';
.ReceiptContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  gap: 16px;
  .InnerContainer {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  .ButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;
    width: 100%;
  }
}
.ReceiptItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
  line-height: 22px;
  letter-spacing: -0.14px;
  .DescriptionLabel {
    font-family: variables.$font--text--light;
  }
  .Content {
    max-width: 350px;
    text-align: end;
    font-family: variables.$font--text--medium;
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .ReceiptContent {
    .ButtonContainer {
      margin-top: 32px;
      gap: 16px;
      flex-direction: column-reverse;
      .Button {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: breakpoints.$sm) {
  .ReceiptItem {
    .Content {
      word-break: break-all;
    }
  }
}
