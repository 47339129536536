@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.ConfirmationModalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $modal--default--background-color;
  justify-content: center;
  padding: 56px 110px 64px;
  width: 608px;
  gap: 16px;
  height: 694px;
  color: $modal--default--color;
  .InsideContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .ModalHeader {
      color: $modal--header--color;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 110%;
    }
    .ModalInfoContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .Title {
        font-family: $font--text--bold;
        width: 100%;
        font-size: 32px;
        line-height: 110%;
      }
      .Information {
        font-family: $font--text--light;
        font-size: 16px;
      }
    }
  }
}

@media only screen and (max-width: $mobile) {
  .ConfirmationModalContainer {
    padding: 103px 48px;
    width: 100%;
    justify-content: flex-start;
    .InsideContainer {
      padding: 48px 0;
      .ModalHeader {
        font-size: 14px;
        line-height: 110%;
      }
      .ModalInfoContainer {
        .Title {
          font-size: 24px;
          line-height: 28px;
        }
        .Information {
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.01em;
        }
      }
    }
  }
}
