@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.GameSchedulePageContainer {
  padding: 16px 0;
}
.GameSchedulePageContainerEmpty {
  padding: 32px 0;
}
@media screen and (max-width: breakpoints.$sm) {
  .GameSchedulePageContainer {
    padding: 16px 0 !important;
  }
  .GameSchedulePageContainerEmpty {
    padding: 32px 0;
  }
}
@media screen and (max-width: breakpoints.$mobile) {
  .GameSchedulePageContainer {
    padding: 16px 0 !important;
  }
  .GameSchedulePageContainerEmpty {
    padding: 32px 0;
  }
}
