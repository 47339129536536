@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';
.ModalContainer {
  z-index: 1001;
}
.PaymentContent {
  position: relative;
  background-color: #fff;
  padding: 56px 64px 64px;
  border-radius: 2px;
  width: 608px;
  min-height: 400px;

  .ButtonContainer {
    width: 100%;
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;
  }
}

@media screen and (max-width: breakpoints.$sm) {
  .PaymentContent {
    padding: 103px 48px;
    width: 100%;
  }
}
@media screen and (max-width: breakpoints.$xs) {
  .PaymentContent {
    padding: 103px 32px;
    width: 100%;
    overflow-y: scroll;
    .closingIcon {
      top: 0px;
      right: 0px;
    }
  }
}
