@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.HeaderContainer {
  position: static;
  overflow: hidden;
  .BreadcrumbContainer {
    background-color: variables.$breadcrumb--background;
  }
  .HeaderRootContentContainer {
    height: fit-content;
    box-sizing: border-box;
    margin-bottom: -75px;
  }

  .WithTopBorder {
    border-top: 1px solid variables.$channel-theme--primary-color;
  }
  .HeaderLogoImage {
    position: absolute;
    left: variables.$padding--left-right--desktop;
    bottom: -38px;
    z-index: 90;
  }
  .ImagesContainer {
    position: relative;
  }
  .Spacer {
    position: static;
  }

  .NotThemed {
    text-transform: none;
  }

  .FollowButtonContainer {
    width: 100%;
    position: relative;
    top: 36px;
    height: 48px;
    z-index: 10;
  }
  .HeaderAdminPanel {
    position: static;
    padding: 0 variables.$padding--left-right--desktop 28px;
  }
  .SubmenuContainer {
    position: static;
    padding-left: variables.$padding--left-right--desktop;
    padding-right: 16px;
    overflow-x: scroll;
    white-space: nowrap;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }
  .SubmenuContainer::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
}
@media screen and (max-width: breakpoints.$sm) {
  .HeaderContainer {
    .HeaderRootContentContainer {
      margin-bottom: -33px;
    }

    .HeaderLogoImage {
      left: 30px !important;
      bottom: -28px !important;
    }

    .FollowButtonContainer {
      top: 25px !important;
      height: 28px !important;
      right: -60px !important;
    }
    .FollowButton {
      height: 28px !important;
      right: 92px !important;
    }
    .HeaderAdminPanel {
      padding: 0 variables.$padding--left-right--tablet 16px !important;
    }
    .SubmenuContainer {
      padding-left: 32px !important;
    }
  }
}
@media screen and (max-width: breakpoints.$mobile) {
  .HeaderContainer {
    .HeaderLogoImage {
      left: 16px !important;
    }
    .HeaderAdminPanel {
      padding: 0 variables.$padding--left-right--mobile 16px !important;
    }
    .SubmenuContainer {
      padding-left: variables.$padding--left-right--mobile !important;
    }
    .FollowButtonContainer {
      right: -76px !important;
    }
  }
}
