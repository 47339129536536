@use '../../styles//variables.scss';
@use '../../styles/breakpoints.scss';

.SearcfieldContainer {
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  align-items: center;
  display: flex;
  border-radius: 25px;
  .BackArrow {
    display: flex;
    justify-content: center;
    margin-right: 12px;
    &:hover {
      cursor: pointer;
    }
  }
  .SearchIcon {
    height: 24px;
    display: flex;
    align-items: center;
    margin-right: 9px;
  }
  .SearchInput {
    border: none;
    text-overflow: ellipsis;
    font-family: variables.$font--text--light;
    font-size: 16px;
    line-height: 16px;
    padding: unset;
    background-color: transparent;
    &::placeholder {
      color: variables.$searchfield--default--placeholder-color;
      opacity: 1; /* Firefox */
    }
    &:focus-visible {
      outline-style: none;
    }
  }
  .CrossIcon {
    background: none !important;
  }
}
.White {
  background-color: variables.$white;
  .BackArrow {
    svg {
      path {
        stroke: variables.$default--text-color;
      }
    }
  }
  .CrossIcon {
    cursor: pointer;
    svg {
      stroke: variables.$searchfield--default--active--text-color;
    }
  }
  &:focus-within {
    background: variables.$searchfield--default--active--background !important;
    &:hover {
      .SearchIcon {
        stroke: variables.$searchfield--default--placeholder-color !important;
      }
      .SearchInput {
        &::placeholder {
          color: variables.$searchfield--default--placeholder-color !important;
        }
      }
    }
  }
  .SearchIcon {
    stroke: variables.$searchfield--default--placeholder-color;
  }
  .SearchInput {
    width: 100%;
    &::placeholder {
      color: variables.$searchfield--default--placeholder-color !important;
    }
  }
  &:hover {
    background: variables.$searchfield--default--hover-background;
    .SearchIcon {
      stroke: variables.$searchfield--default--hover--placeholder-color !important;
    }
    .SearchInput {
      &::placeholder {
        color: variables.$searchfield--default--hover--placeholder-color !important;
      }
    }
  }
}
.Light {
  background-color: variables.$searchfield--default-background;
  .BackArrow {
    svg {
      path {
        stroke: variables.$default--text-color;
      }
    }
  }
  .CrossIcon {
    cursor: pointer;
    svg {
      stroke: variables.$searchfield--default--active--text-color;
    }
  }
  &:focus-within {
    background: variables.$searchfield--default--active--background !important;
    &:hover {
      .SearchIcon {
        stroke: variables.$searchfield--default--placeholder-color !important;
      }
      .SearchInput {
        &::placeholder {
          color: variables.$searchfield--default--placeholder-color !important;
        }
      }
    }
  }
  .SearchIcon {
    stroke: variables.$searchfield--default--placeholder-color;
  }
  .SearchInput {
    width: 100%;
    &::placeholder {
      color: variables.$searchfield--default--placeholder-color !important;
    }
  }
  &:hover {
    background: variables.$searchfield--default--hover-background;
    .SearchIcon {
      stroke: variables.$searchfield--default--hover--placeholder-color !important;
    }
    .SearchInput {
      &::placeholder {
        color: variables.$searchfield--default--hover--placeholder-color !important;
      }
    }
  }
}

.Dark {
  background-color: variables.$searchfield--desktop--dark-background;
  .BackArrow {
    svg {
      path {
        stroke: variables.$searchfield--desktop--dark--active-color !important;
      }
    }
  }
  .SearchIcon {
    stroke: variables.$searchfield--desktop--dark--placeholder-color !important;
  }

  .SearchInput {
    width: 100%;
    color: variables.$searchfield--desktop--dark--placeholder-color;

    &::placeholder {
      color: variables.$searchfield--desktop--dark--placeholder-color !important;
    }
    &:focus-visible {
      color: variables.$searchfield--desktop--dark--active-color;
    }
  }
  .CrossIcon {
    stroke: variables.$searchfield--desktop--dark--active-color;
  }
  &:hover {
    background: variables.$searchfield--desktop--dark--hover-background;
  }
  &:focus-within {
    background: variables.$searchfield--desktop--dark--active--background !important;
    .SearchIcon {
      stroke: variables.$searchfield--desktop--dark--active-color;
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .SearcfieldContainer {
    border-radius: 0 0 2px 2px;
  }
}
@media only screen and (max-width: breakpoints.$mobile) {
  .SearcfieldContainer {
    width: 100%;
    .SearchInput {
      font-size: 14px;
      line-height: 14px;
    }
  }
  .Dark {
    background-color: variables.$searchfield--dark-background;
    &:focus-within {
      background: variables.$searchfield--dark--active--background !important;
    }
    .SearchIcon {
      stroke: variables.$searchfield--dark--placeholder-color !important;
    }
    .SearchInput {
      width: 100%;
      &:focus-visible {
        background: variables.$searchfield--dark--active--background;
        color: variables.$searchfield--dark--active-color;
      }
    }
  }
}
