@use '../../../../styles/variables.scss';

.RadioButton {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;

  &:hover label {
    cursor: pointer;
  }

  input[type='radio'] {
    display: grid;
    place-content: center;
    appearance: none;
    background-color: variables.$secondary--light-background;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 50%;
    transform: translateY(-0.075em);

    &:before {
      content: '';
      width: 0.65em;
      height: 0.65em;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em variables.$dark--selected-border;
    }
    &:checked::before {
      transform: scale(1);
    }
    &:hover {
      cursor: pointer;
    }
  }
}
