.ImageSliderPreloader {
  display: flex;
  flex-direction: column;
  width: 100%;
  .Wrapper {
    display: flex;
    flex-direction: row;
    padding: 1.5rem;
    flex-wrap: nowrap;
    justify-content: space-between;
    .ObjectPreloader {
      width: 32%;
      display: flex;
      flex-direction: column;
      .LoaderImage {
        display: block;
        padding-bottom: 56.25%;
      }
      .BottomContainer {
        padding: 6px;
        .Category {
          width: 100%;
          height: 16px;
          margin-bottom: 6px;
        }
        .LoaderTitle {
          width: 80%;
          height: 20px;
          margin-bottom: 6px;
        }
        .LoaderTime {
          width: 40%;
          height: 16px;
        }
      }
    }
  }
}

@keyframes animate {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
#one,
#two,
#three,
#four,
#five,
#six {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: animate;
  animation-timing-function: linear;
  background: #f6f6f6;
  position: relative;
  background: linear-gradient(110deg, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  -webkit-background-size: 1000px 200px;
}
@media screen and (max-width: 813px) {
  .ImageSliderPreloader {
    .Wrapper {
      .ObjectPreloader {
        width: 48.5%;
      }
    }
  }
}
@media screen and (max-width: 501px) {
  .ImageSliderPreloader {
    .Wrapper {
      .ObjectPreloader {
        width: 70%;
      }
      .object2 {
        width: 26% !important;
        .LoaderImage {
          height: 100%;

          animation-duration: 8s;
        }
        .BottomContainer {
          padding-right: 0;
          .LoaderTitle {
            width: 100%;
          }
          .LoaderTime {
            width: 100%;
          }
        }
      }
    }
  }
}
