@use '../../../../styles/colors.scss';
@use '../../../../styles/breakpoints.scss';

.GameResult {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  background-color: colors.$grey--light--3;
}

@media only screen and (max-width: breakpoints.$mobile) {
  .GameResult {
    padding: 33px 0;
  }
}

.IsViewingStream {
  padding: 24px 16px;
  min-height: 100%;
  gap: 24px;
}
