@use '../../../styles/variables.scss';
@use '../../../styles/breakpoints.scss';

.Container {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  padding-bottom: 16px;

  .ChildLink {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-decoration: none;
    padding: 24px 56px;

    color: variables.$default--text-color;
    font-family: variables.$font--text--medium;
    &:hover {
      background-color: variables.$ghost--hover-background !important;
    }
  }
}
