@use '../../../../styles/variables.scss';
@use '../../../../styles/breakpoints.scss';

.TopActionsBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 24px;
  padding-left: variables.$padding--left-right--desktop;
  padding-right: variables.$padding--left-right--desktop;
  .BackButton {
    max-height: 48px;
    max-width: 48px;
    svg {
      height: 48px;
      width: 48px;
    }
  }
  .RightContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    .StreamOptionsButton {
      position: relative;
      .OptionsTooltipWrapper {
        position: absolute;
        right: -15px;
        width: 320px;
        top: 48px;
      }
    }
  }
}

@media only screen and (max-width: breakpoints.$sm) {
  .TopActionsBar {
    padding-left: variables.$padding--left-right--tablet;
    padding-right: variables.$padding--left-right--tablet;
    padding-top: 24px;
  }
}
@media only screen and (max-width: breakpoints.$mobile) {
  .TopActionsBar {
    padding-left: variables.$padding--left-right--mobile;
    padding-right: variables.$padding--left-right--mobile;
    .StreamOptionsButton .OptionsTooltipWrapper {
      max-width: 90vw;
    }
  }
}

.IsViewingStream {
  padding-left: 16px;
  padding-right: 16px;
  .BackButton {
    max-height: 32px;
    max-width: 32px;
    svg {
      height: 32px;
      width: 32px;
    }
  }
}
