@import '../../../../styles/variables.scss';
@import '../../../../styles/breakpoints.scss';

.LineDivider {
  margin: 0px 13px;
}

.SpacedDivider {
  margin-right: 13px;
}

.Container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: $search-result--padding--top-bottom--desktop
    $padding--left-right--desktop;
  width: 1220px;
  align-items: stretch;

  background-color: $search-item--container-background-color;

  transition: background-color $hover--transition-time;
  &:hover {
    background-color: $search-item--container-background-color--hover;
  }

  .ImageChannelContainer {
    .Image {
      border-radius: $component-radius;
      height: 100px;
      width: 100px;
    }
  }

  .ContentContainer {
    width: 984px;
    min-height: 132px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    flex: none;
    order: 1;
    flex-grow: 1;
    margin: 0px 43px;

    .Published {
      position: static;
      top: 0;
      left: 0;
      padding-bottom: 13px;
      font-size: 12px;
      font-style: $font--text--light;
      color: $search-item--published-text-color;
      line-height: 12px;

      display: flex;
      align-items: center;
    }
    .EventTitle a {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-decoration: none;

      font-family: $font--text--bold;
      color: $search-item--title-text-color;
      font-size: 32px;
      line-height: 110%;

      letter-spacing: -0.01em;
      margin-bottom: 13px;
      max-width: 784px;

      &:visited {
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
        cursor: pointer;
      }
      &:focus {
        text-decoration: none;
      }
    }

    .Description {
      align-items: flex-end;
      letter-spacing: 0.04em;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      font-family: $font--text--light;
      color: $search-item--description-text-color;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;

      max-width: 784px;
      margin-bottom: 20px;
    }

    .EventTeams {
      align-items: flex-end;
      letter-spacing: 0.04em;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      font-family: $font--text--light;
      color: $search-item--event-text-color;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;

      max-width: 784px;
      margin-bottom: 20px;
    }

    .EventTeamsText {
      color: $search-item--event-teams-text-color;
    }

    .ComingGame {
      cursor: pointer;
      margin-right: 13px;
      text-decoration: underline;
      font-family: $font--text--medium;
      color: $search-item--category-text-color;
      &:hover {
        font-family: $font--text--bold;
      }
    }

    .IsLive {
      cursor: pointer;
      color: $search-item--live-text-color;
      font-family: $font--text--medium;
      text-decoration: underline;
      &:hover {
        font-family: $font--text--bold;
      }
    }

    .BottomFrame {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;

      flex: none;
      order: 0;
      flex-grow: 0;

      font-family: $font--text--medium;
      font-size: 12px;
      line-height: 16px;

      align-items: flex-end;
      letter-spacing: 0.04em;
      text-transform: uppercase;

      color: $search-item--category-text-color;

      .EventText {
        text-decoration: underline;
      }
    }
  }
}

@media only screen and (max-width: $mobile) {
  .Container {
    padding: $search-result--padding--top-bottom--mobile
      $padding--left-right--mobile;

    .ImageChannelContainer {
      .Image {
        height: 48px !important;
        width: 48px !important;
      }
    }
    .ContentContainer {
      margin: 0px 16px;
      min-height: 0;

      .EventTitle a {
        font-family: $font--text--medium;
        font-size: 14px;
      }
      .Description {
        display: none;
      }
      .EventTeams {
        display: none;
      }
    }
  }
}
